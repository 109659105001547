import React from "react";

import { GoToTop } from "../../components/сommon/GoToTop";
import { Header } from "../../components/сommon/Header";
import { Footer } from "../../components/сommon/Footer";
import { Intro } from "../../components/Intro";
import { HomePageSlider } from "../../components/HomePageSlider";
import { Blogs } from "../../components/Blogs";
import { ScrollToTop } from "../../components/сommon/ScrollToTop";
import { ErrorModal } from "../../components/modals/ErrorModal";

/**
 * @desc Home UI
 * @return {JSX.Element}
 */
export const MainPage = () => (
  <div className="container">
    <GoToTop threshold={3000} />
    <ScrollToTop />
    <Header link="unlock" />
    <Intro url={"https://www.youtube.com/watch?v=JNU7bjxJUhA"} />
    <HomePageSlider />
    <Blogs />
    <Footer />
    <ErrorModal />
  </div>
);
