import React from "react";
import Typography from "@material-ui/core/Typography";
import i18n from "i18n-js";

/**
 * @desc function for handling and display errors
 * @param {object} error - object with error
 * @returns {JSX.Element}
 */
export const ErrorHandler = ({ error }) => {
  let errorText = "Fehler: ";

  const errorDescriptions = {
    "400": i18n.t("errorText.error400"),
    "401": i18n.t("errorText.error401"),
    "403": i18n.t("errorText.error403"),
    "404": i18n.t("errorText.error404"),
    "405": i18n.t("errorText.error405"),
    "409": i18n.t("errorText.error409"),
    "500": i18n.t("errorText.error500"),
  };

  if (error?.response?.status && errorDescriptions[error.response.status]) {
    errorText = error?.response?.status && errorDescriptions[error.response.status] ? `${errorText}${errorDescriptions[error.response.status]}. ${error.response.data.message}` : `${errorText}${errorDescriptions[error.response.status]}`;
  } else {
    if (error?.response?.status) {
      errorText = errorText + error.response.status
    } else {
      errorText = "Anwendungsfehler: " + error.message
    }
  }

  return (
    <Typography variant="body1" color="error" style={{marginTop: "5px"}}>{errorText}</Typography>
  );
};
