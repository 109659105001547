import SvgIcon from "@material-ui/core/SvgIcon";

export const FacebookIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M19.241,9.9a9.339,9.339,0,1,0-10.8,9.226V12.6H6.07V9.9H8.442V7.844A3.3,3.3,0,0,1,11.97,4.211a14.373,14.373,0,0,1,2.091.182v2.3H12.883a1.35,1.35,0,0,0-1.522,1.458V9.9h2.59l-.414,2.7H11.361v6.527A9.343,9.343,0,0,0,19.241,9.9Z"
      transform="translate(2, 2)"
    />
  </SvgIcon>
);
