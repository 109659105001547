import { Component } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CropFreeIcon from "@material-ui/icons/CropFree";
import CloseIcon from "@material-ui/icons/Close";
import UploadValidator from "../сommon/UploadValidator";

class ImageCropper extends Component {
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      this.fileName = file.name;
      if (file.size > 1024 * 1024 * 10) {
        this.props.setSizeAlertOpen(true);
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.props.setCropObject({
          ...this.props.cropObject,
          src: reader.result,
        })
      );
      reader.readAsDataURL(file);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.props.setCropObject({ ...this.props.cropObject, crop });
  };

  makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, "newFile.jpeg").then((res) => {
        const { fileUrl, blob } = res;
        this.props.setCropObject({ ...this.props.cropObject, fileUrl });
        let formData = new FormData();
        formData.append("file", blob, this.fileName);
        this.props.setResultCrop(formData);
      });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({ blob: blob, fileUrl: this.fileUrl });
      }, "image/jpeg");
    });
  }

  render() {
    const { crop, src } = this.props.cropObject;

    return (
      <>
        {this.props.validation ? (
          <UploadValidator
            text="Bild zuschneiden"
            accept="image/png, image/jpeg"
            onChange={this.onSelectFile}
            validators={["required"]}
            errorMessages={["Dieses Feld wird benötigt"]}
            value={this.props.value}
            classNames={this.props.classes}
            startIcon={<CropFreeIcon />}
          />
        ) : (
          <Button component="label" className={this.props.classes.upload_btn} startIcon={<CropFreeIcon />}>
            Bild zuschneiden
            <input type="file" accept="image/png, image/jpeg" onChange={this.onSelectFile} hidden />
          </Button>
        )}

        {src && (
          <div className={this.props.classes.cropper_wrap}>
            <div className={this.props.classes.cropper_close_block}>
              <IconButton
                onClick={() => {
                  this.props.setCropObject({
                    src: null,
                    crop: { unit: "%", width: 50, aspect: this.props.aspect },
                    fileUrl: null,
                  });
                  this.props.setResultCrop(null);
                }}
                className={this.props.classes.cropper_close_btn}
              >
                <CloseIcon />
              </IconButton>
            </div>

            <ReactCrop
              src={src}
              crop={crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              className={this.props.classes.cropper_src_img}
            />
          </div>
        )}
      </>
    );
  }
}

export default ImageCropper;
