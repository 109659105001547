import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CloseIcon from "@material-ui/icons/Close";

import { editPrivateFileField } from "../../../store/actions";

import useStyles from "../useStyles";
import getBytesFromFileSize from "../../../utils/getBytesFromFileSize";

/**
 * @desc function for create private file watermarks for upload
 * @returns {JSX.Element}
 */
export const PrivateFileLogosUpload = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { file: { logos, forLogos, deleteLogosIds } } = useSelector(({ adminFiles }) => adminFiles);
  const [sizeAlertOpen, setSizeAlertOpen] = useState(false);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const newFile = e.target.files[0];
      if (newFile.size > 1024 * 1024 * 2) {
        setSizeAlertOpen(true);
        return;
      }
      const newLogosFiles = [...logos, newFile];
      dispatch(editPrivateFileField("logos", newLogosFiles));
    }
  };

  const removeLogo = (file, index) => {
    if (file.id) {
      const filteredLogos = logos.filter((el) => el.id !== file.id);
      const deletedLogos = [...deleteLogosIds, file.id];
      dispatch(editPrivateFileField("logos", filteredLogos));
      dispatch(editPrivateFileField("deleteLogosIds", deletedLogos));
    } else {
      const filteredLogos = [...logos];
      filteredLogos.splice(index, 1);
      dispatch(editPrivateFileField("logos", filteredLogos));
    }
  };

  const handleLogoChange = ({ target }) => {
    dispatch(editPrivateFileField("forLogos", target.checked));
  };

  return (
    <div className={classes.upload_block}>
      <Typography align="center" className={classes.upload_title}>
        Logos hochladen
      </Typography>

      <FormControlLabel
        className={classes.upload_logos_checkbox}
        control={<Checkbox color="primary" checked={forLogos} onChange={handleLogoChange}/>}
        label="Logos zulassen"
      />

      {logos.map((file, index) => {
        const src = file.id ? "" : URL.createObjectURL(file);

        return (
          <div key={file.size + index} className={classes.upload_close_block}>
            <div className={classes.upload_close_block_text_wrap}>
              <Typography noWrap className={classes.upload_close_block_text_number}>
                {index + 1}.
              </Typography>
              {src && <img src={src} className={classes.upload_logo_preview} alt="log"/>}
              <Typography noWrap className={classes.upload_close_block_text_name}>
                {`${file.name ? "Neue Logo " : "Alte Logo "} ${getBytesFromFileSize(file.size)}. `}
                {file.name ? `Name: ${file.name}` : ""}
              </Typography>
            </div>
            <IconButton onClick={() => removeLogo(file, index)} className={classes.upload_close_block_btn}>
              <CloseIcon />
            </IconButton>
          </div>
        );
      })}

      <Button component="label" className={classes.upload_btn} startIcon={<CameraAltIcon />}>
        Bild hochladen
        <input type="file" accept="image/jpeg,image/png" onChange={(e) => onSelectFile(e)} hidden />
      </Button>

      <Snackbar
        open={sizeAlertOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => setSizeAlertOpen(false)}
      >
        <MuiAlert elevation={6} variant="filled" onClose={() => setSizeAlertOpen(false)} severity="error">
          Die Logo-Größe muss weniger als 2 MB
        </MuiAlert>
      </Snackbar>
    </div>
  );
};
