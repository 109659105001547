import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./Header.module.scss";
import { HeaderLogoIcon } from "../icons/HeaderLogoIcon";
import { HomeIcon } from "../icons/HomeIcon";
import { UnlockIcon } from "../icons/UnlockIcon";
import { LockIcon } from "../icons/LockIcon";

/**
 * @desc function for create header
 * @param {string} link - header link
 * @param {string} container - type of header container
 * @returns {JSX.Element}
 */
export const Header = ({ link = "home", container = "container" }) => {
  const { token } = useSelector(({ login }) => login);

  return (
    <header className={`${styles.header} ${container}`}>
      <Link to={"/home"}>
        <img src="/img/header-logo.svg" className={styles.logo} alt="header-logo"/>
        {/* <HeaderLogoIcon className={styles.logo} /> */}
      </Link>
      {link === "home" && (
        <Link to="/home" className={styles.home}>
          <HomeIcon className={styles.homeIcon} />
        </Link>
      )}
      {link === "unlock" && (
        token
          ? (
            <Link to={"/content"} className={styles.home}>
              <UnlockIcon className={styles.unlockIcon} />
            </Link>
          )
          : (
            <Link to={"/signin"} className={styles.home}>
              <LockIcon className={styles.unlockIcon} />
            </Link>
          )
      )}
    </header >
  );
}
