import { ACTION } from "../constants/types";

const INIT_STATE = {
  users: [],
  pagination: {
    page: 0,
    size: 10,
    totalCount: 0,
  },
  query: {
    page: 0,
    size: 10,
    email: "",
    sort: "createdAt,desc",
  },
  loading: false,
  error: null,
  user: {
    id: null,
  },
  isModalOpen: false,
  modalContent: null,
  actionLoading: false,
  actionError: null,
};

export const usersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION.SET_USERS_QUERY:
      return { ...state, query: { ...state.query, ...action.payload } };

    case ACTION.GET_USERS_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        pagination: { page: action.payload.page, totalCount: action.payload.totalCount },
        loading: false,
      };
    case ACTION.GET_USERS_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case ACTION.ENABLE_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION.ENABLE_USER_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case ACTION.USER_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: true,
        modalContent: action.payload.modalContent,
        user: { id: action.payload.id, role: action.payload.role, email: action.payload.email },
      };
    case ACTION.USER_MODAL_CLOSE:
      return { ...state, isModalOpen: false, modalContent: null, user: { id: null }, actionError: null };

    case ACTION.DELETE_USER_REQUEST:
      return { ...state, actionLoading: true, actionError: null };
    case ACTION.DELETE_USER_SUCCESS:
      return { ...state, isModalOpen: false, modalContent: null, user: { id: null }, actionLoading: false };
    case ACTION.DELETE_USER_FAILURE:
      return { ...state, actionError: action.payload, actionLoading: false };

    case ACTION.EDIT_USER_REQUEST:
      return { ...state, actionLoading: true, actionError: null };
    case ACTION.EDIT_USER_SUCCESS:
      return { ...state, isModalOpen: false, modalContent: null, user: { id: null }, actionLoading: false };
    case ACTION.EDIT_USER_FAILURE:
      return { ...state, actionError: action.payload, actionLoading: false };

    case ACTION.EDIT_USER_FIELDS:
      return { ...state, user: { ...state.user, ...action.payload } };

    case ACTION.CLEAN_USERS:
      return INIT_STATE;

    default:
      return state;
  }
};
