import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  merge_image_section: {
    marginBottom: 100,
    padding: "20px 140px",
    [theme.breakpoints.down("lg")]: {
      padding: "20px 81px"
    },
    [theme.breakpoints.down("md")]: {
      padding: "20px 50px"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 36px"
    },
  },
  merge_image_section_title: {
    lineHeight: "72px",
    margin: "30px 0 30px 0",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "48px",
    },
    [theme.breakpoints.down("xs")]: {
      lineHeight: "21px",
      margin: "20px 0 30px 0",
    },
  },
  result_wrapper: {
    width: "100%",
    margin: "20px auto",
    cursor: "not-allowed",
  },
  result_wrapper_cursor: {
    cursor: "move"
  },
  result_img: {
    width: "100%",
  },
  preloader_wrap: {
    height: 300,
  },
  logos_wrap: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  waterMark_controls: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,
  },
  waterMark_btn: {
    height: 49,
    width: 250,
    textDecoration: "none",
    padding: "14px 30px",
    letterSpacing: "2px",
  },
  upload_btn: {
    height: 49,
    color: theme.palette.primary.decor,
    width: 160,
    border: "1px dashed currentcolor",
    margin: "15px auto",
    textDecoration: "none",
    display: "flex",
  },
  logos_description: {
    fontSize: 20,
    lineHeight: "25px",
    fontWeight: 500,
    marginBottom: 70,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      lineHeight: "21px",
      marginBottom: 50,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      lineHeight: "16px",
      marginBottom: 20,
    },
  },
}));
export default useStyles;

