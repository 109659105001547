import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  upload_block: {
    margin: "0 auto 16px auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  upload_title: {
    fontSize: 20,
    fontWeight: 500,
    margin: "10px auto 20px auto",
  },
  upload_description: {
    fontSize: 18,
    margin: "10px auto 20px auto",
  },
  upload_btn: {
    height: 49,
    color: theme.palette.primary.decor,
    width: 160,
    border: "1px dashed currentcolor",
    textDecoration: "none",
  },
  upload_preview_img_box: {
    marginBottom: 20,
  },
  upload_preview_img_box_grid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 5,
    width: 500,
    marginBottom: 20,
  },
  upload_preview_img_wrapper_grid: {
    position: "relative",
  },
  upload_preview_img: {
    height: 100,
    width: "auto",
    border: "1px dashed #000000",
  },
  upload_preview_img_grid: {
    height: 100,
    width: "100%",
    objectFit: "cover",
    border: "1px dashed #000000",
  },
  cropper_wrap: {
    display: "flex",
    flexDirection: "column"
  },
  cropper_src_img: {
    width: "100%",
    maxWidth: 1824,
    marginBottom: 20,
  },
  cropper_close_block: {
    textAlign: "right",
  },
  cropper_close_btn: {
    color: theme.palette.primary.decor,
  },
  upload_close_block_text: {
    fontSize: 18,
  },
  upload_close_block: {
    height: 31,
    width: 450,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  upload_close_block_btn: {
    height: 30,
    width: 30,
    padding: 5,
    color: theme.palette.primary.decor,
    marginLeft: 10,
  },
  upload_close_block_btn_grid: {
    position: "absolute",
    top: 4,
    right: 4,
    height: 25,
    width: 25,
    padding: 5,
    color: "#fff",
    background: theme.palette.primary.decor,
    opacity: 0.8,
    "&:hover": {
      background: theme.palette.primary.decor,
      opacity: 1,
    }
  },
  upload_close_block_text_name: {
    fontSize: 18,
    width: 360,
  },
  upload_close_block_text_wrap: {
    display: "flex",
    width: 410,
  },
  upload_close_block_text_number: {
    fontSize: 18,
    width: 20,
    marginRight: 5,
  },
  upload_logo_preview: {
    height: 20,
    width: 20,
    marginRight: 5,
    objectFit: "cover",
  },
  upload_logos_checkbox: {
    margin: "5px 10px 10px"
  },
  upload_validator_wrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 70,
  },
  upload_preload_wrap: {
    marginBottom: 21,
  },
}));

export default useStyles;
