import React from "react";
import { Link } from "react-router-dom";

import { useStyles } from "./useStyles";
import { ArrowLeftIcon } from "../icons/ArrowLeftIcon";

/**
 * @desc function for create back button
 * @returns {JSX.Element}
 */
export const BackButton = () => {
  const classes = useStyles();

  return (
    <div className={classes.backButton}>
      <Link to={"/home"}>
        <ArrowLeftIcon />
      </Link>
    </div>
  );
};
