import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import i18n from "i18n-js";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Typography, Button, InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { Preloader } from "../../сommon/Preloader";
import { ErrorHandler } from "../../сommon/ErrorHandler";
import { SuccessModal } from "../../modals/SuccessModal";

import useStyles from "../useStyles";
import { HeaderLogoIcon } from "../../сommon/icons/HeaderLogoIcon";
import { SignupUnlockIcon } from "../../сommon/icons/SignupUnlockIcon";

/**
 * @desc function for create password form
 * @param {string} token - user token
 * @returns {JSX.Element}
 */
export const PasswordForm = ({ token }) => {
  const classes = useStyles();
  const history = useHistory();
  const unmounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [user, setValues] = useState({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      return value === user.password;
    });
  }, [user.password]);

  const handleChange = event => setValues({ ...user, [event.target.name]: event.target.value });
  const handleShowPassword = name => setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  const closeModal = () => {
    setSuccessModalOpen(false);
    history.push("/signin");
  };
  const handleSubmit = () => {
    setLoading(true);
    setError(null);
    axios
      .put(`uaa/user/password/change/${token}`, { password: user.password })
      .then(res => {
        if (!unmounted.current) {
          if (res.status === 200) {
            setSuccessModalOpen(true);
          }
        }
      })
      .catch(error => {
        if (!unmounted.current) {
          setError(error);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    return () => { unmounted.current = true };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.form}>
      <div className={classes.formTitle}>
        <SignupUnlockIcon className={classes.formTitleImg} />
        <Typography variant="h3" color="textPrimary">
          {i18n.t("setPassword.title")}
        </Typography>
      </div>
      <div className={classes.formWrapper}>
        <div className={classes.formLogoWrapper}>
          <HeaderLogoIcon className={classes.formLogo} />
        </div>
        <ValidatorForm className={classes.formContent} noValidate={false} onSubmit={handleSubmit} autoComplete="off">
          <div>
            <TextValidator
              className={classes.formTextField}
              variant="filled"
              placeholder="Passwort"
              name="password"
              value={user.password}
              onChange={e => handleChange(e)}
              InputProps={{
                type: showPassword.password ? "text" : "password",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={() => handleShowPassword("password")} edge="end">
                      {showPassword.password ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 30,
                className: classes.formTextInput,
              }}
              validators={["required", `matchRegexp:^[a-zA-ZäöüßÄÖÜẞ0-9\\!\\#\\$\\%\\&\\'\\(\\)\\*\\+\\,\\-\\.\\/\\:\\;\\<\\>\\=\\?\\@\\[\\]\\{\\}\\\\\\\\\\^\\_\\"\\~\\|]{4,20}$`]}
              errorMessages={["Dieses Feld wird benötigt", "Das Passwort muss aus 4 bis 20 Symbolen bestehen"]}
            />

            <TextValidator
              className={classes.formTextField}
              variant="filled"
              placeholder="Passwort wiederholen"
              name="confirmPassword"
              value={user.confirmPassword}
              onChange={e => handleChange(e)}
              InputProps={{
                type: showPassword.confirmPassword ? "text" : "password",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleShowPassword("confirmPassword")}
                      edge="end"
                    >
                      {showPassword.confirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 30,
                className: classes.formTextInput,
              }}
              validators={["required", `matchRegexp:^[a-zA-ZäöüßÄÖÜẞ0-9\\!\\#\\$\\%\\&\\'\\(\\)\\*\\+\\,\\-\\.\\/\\:\\;\\<\\>\\=\\?\\@\\[\\]\\{\\}\\\\\\\\\\^\\_\\"\\~\\|]{4,20}$`, "isPasswordMatch"]}
              errorMessages={[
                "Dieses Feld wird benötigt",
                "Das Passwort muss aus 4 bis 20 Symbolen bestehen",
                "Passwortkonformität fehlgeschlagen",
              ]}
            />

            <div className={classes.formLinksWrap}>
              <Link to="/signin" className={classes.formLink}>
                Einloggen
            </Link>
            </div>
          </div>

          {!!error && <ErrorHandler error={error} />}

          {loading ? (
            <Preloader />
          ) : (
            <Button className={classes.formSubmitBtn} type="submit">
              Speichern
            </Button>
          )
          }
        </ValidatorForm>
        <SuccessModal isModalOpen={successModalOpen} onCloseModal={closeModal} text="Neues Passwort erfolgreich gespeichert." />
      </div>
    </div>
  );
};
