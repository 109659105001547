import SvgIcon from "@material-ui/core/SvgIcon";

export const LoadIcon = props => (
  <SvgIcon {...props}>
    <g transform="translate(-1505.852 -1279)">
      <path d="M25.149,22.5v4.589a2.294,2.294,0,0,1-2.294,2.294H6.794A2.294,2.294,0,0,1,4.5,27.089V22.5" transform="translate(1502.352 1271.266)" fill="none" stroke="#393737" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path d="M10.5,15l5.736,5.736L21.971,15" transform="translate(1500.94 1273.03)" fill="none" stroke="#393737" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path d="M18,18.266V4.5" transform="translate(1499.176 1275.5)" fill="none" stroke="#393737" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </g>
  </SvgIcon>
);
