import { ACTION } from "../constants/types";

/**
 * @desc function for auth log in
 * @param {object} user - object with user data
 * @return {{type: string, payload: *}}
 */
export const logInRequest = user => {
  return {
    type: ACTION.SIGNIN_USER_REQUEST,
    payload: user,
  };
};

/**
 * @desc function for successful auth log in
 * @param {string} token - user token
 * @param {string} role - user role
 * @param {string} email - user email
 * @return {{type: string, payload: *}}
 */
export const logInSuccess = (token, role, email) => {
  return {
    type: ACTION.SIGNIN_USER_SUCCESS,
    payload: { token, role, email },
  };
};

/**
 * @desc function for failed auth log in
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const logInFailure = error => {
  return {
    type: ACTION.SIGNIN_USER_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for auth log out
 * @param {string} refreshToken - refresh token
 * @return {{type: string, payload: *}}
 */
export const logOutRequest = refreshToken => {
  return {
    type: ACTION.LOGOUT_USER_REQUEST,
    payload: refreshToken,
  };
};

/**
 * @desc function for successful auth log out
 * @return {{type: string}}
 */
export const logOutSuccess = () => {
  return {
    type: ACTION.LOGOUT_USER_SUCCESS,
  };
};

/**
 * @desc function for failed auth log out
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const logOutFailure = error => {
  return {
    type: ACTION.LOGOUT_USER_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for set cookie
 * @param {string} payload - toggle value
 * @return {{type: string, payload: *}}
 */
export const setCookie = payload => {
  return {
    type: ACTION.SET_COOKIE,
    payload,
  };
};


