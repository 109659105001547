import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 120,
  },
  formTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: 110,
    marginBottom: "48px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
      textAlign: "center",
    },
  },
  formWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 324,
    minHeight: 480,
    padding: "47px 37px 37px",
    background: "#EFEFEF",
    boxSizing: "border-box",
    "&:before": {
      content: "''",
      position: "absolute",
      left: 0,
      bottom: 0,
      width: 69,
      height: 5,
      background: theme.palette.text.primary,
    },
    "&:after": {
      content: "''",
      position: "absolute",
      left: 0,
      bottom: 0,
      width: 5,
      height: 95,
      background: theme.palette.text.primary,
    },
    [theme.breakpoints.down("xs")]: {
      width: 320,
    },
  },
  formLogoWrapper: {
    marginBottom: 58,
  },
  formLogo: {
    width: 100,
    height: 55,
  },
  formContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    width: "100%",
  },
  formTextField: {
    width: "100%",
    height: 92,
  },
  formTextInput: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  formLinksWrap: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 5,
  },
  formLink: {
    fontWeight: 900,
    fontSize: 11,
    lineHeight: "10px",
    color: "#000000",
    opacity: "0.55",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.primary.decor,
    }
  },
  formSubmitBtn: {
    background: "#D5D5D5",
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.primary.decor,
      background: "#D5D5D5",
    }
  },
  formSuccessMessage: {
    marginTop: 5,
    color: "#228B22",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  innerFormSection: {
    padding: "20px 36px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px 10px",
    },
  },
  innerFormHeader: {
    marginBottom: 15,
  },
  innerForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 10,
  },
  innerFormField: {
    width: 500,
    height: 78,
    marginBottom: 5,
    [theme.breakpoints.down("sm")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: 280,
    },
  },
  innerFormSelect: {
    width: 200,
    height: 56,
    marginBottom: 27,
  },
  innerFormTextarea: {
    height: 154,
  },
  inner_form_autocomplete: {
    height: 56,
    paddingLeft: "14px !important",
    cursor: "pointer",
  },
  innerFormSubmitBlock: {
    marginBottom: 16,
  },
  innerFormSubmitBtn: {
    height: 49,
    width: 500,
    [theme.breakpoints.down("sm")]: {
      width: 400,
    },
    [theme.breakpoints.down("xs")]: {
      width: 280,
    },
  },
  innerFormDescription: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 16,
  }
}));

export default useStyles;
