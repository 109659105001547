import { useEffect } from "react";

/**
 * @desc function for create scroll to top
 * @param {string} id - anchor id
 * @returns {JSX.Element}
 */
export const ScrollToTop = ({ id = null }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);

  return null;
};
