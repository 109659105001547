import { useSelector } from "react-redux";

import { Header } from "../../components/сommon/Header";
import { Footer } from "../../components/сommon/Footer";
import { GoToTop } from "../../components/сommon/GoToTop";
import { ChevronButton } from "../../components/сommon/buttons/ChevronButton";
import { ScrollToTop } from "../../components/сommon/ScrollToTop";
import { BlogSection } from "../../components/сommon/BlogSection";

/**
 * @desc Blog preview UI
 * @return {JSX.Element}
 */
export const BlogPreviewPage = () => {
  const { preview } = useSelector(({ adminBlogs }) => adminBlogs);

  return (
    <>
      <GoToTop />
      <ScrollToTop />
      <Header link="key" />
      <ChevronButton />
      <BlogSection news={preview} preview />
      <Footer />
    </>
  );
};
