import SvgIcon from "@material-ui/core/SvgIcon";

export const CheckboxIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M18.8654 0.00878906H3.77637C2.11951 0.00878906 0.776367 1.35193 0.776367 3.00879V18.0978C0.776367 19.7546 2.11951 21.0978 3.77637 21.0978H18.8654C20.5222 21.0978 21.8654 19.7546 21.8654 18.0978V3.00879C21.8654 1.35193 20.5222 0.00878906 18.8654 0.00878906Z" fill="white"
      transform="translate(0,1)"
    />
  </SvgIcon>
);
