import { useDispatch, useSelector } from "react-redux";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from "@material-ui/core/Typography";

import useStyles from "../useStyles";

import { setBlogMainImageType, deleteBlogsAllMainImages } from "../../../store/actions";

/**
 * @desc function for select blog main image type
 * @param {string} type - blog main image type
 * @param {array} mainImages - array with blog main images
 * @returns {JSX.Element}
 */
export const BlogMainImageType = ({ type, mainImages }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleMainImageTypeChange = (event) => {
    dispatch(setBlogMainImageType(event.target.value));
    !!mainImages.length && dispatch(deleteBlogsAllMainImages());
  };

  return (
    <div className={classes.upload_block}>
      <Typography align="center" className={classes.upload_title}>
        Banner type
      </Typography>
      <RadioGroup
        row
        aria-label="mainImageType"
        name="mainImageType"
        value={type}
        onChange={handleMainImageTypeChange}
      >
        <FormControlLabel value="IMAGE" control={<Radio />} label="Image" />
        <FormControlLabel value="VIDEO" control={<Radio />} label="Video" />
      </RadioGroup>
    </div>
  );
};
