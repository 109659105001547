import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Element } from "react-scroll";
import axios from "axios";

import { BlogsRoutes } from "../../routes/BlogsRoutes";

import { BlogsFilters } from "./BlogsFilters";
import { FloatFilter } from "../сommon/FloatFilter";
import { Preloader } from "../сommon/Preloader";
import { AddContentButton } from "../сommon/buttons/AddContentButton";

import { setAddBlogsQuery } from "../../store/actions";

import styles from "./Blogs.module.scss";

/**
 * @desc function for render blogs list
 * @returns {JSX.Element}
 */
export const Blogs = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const [filterVisibility, setFilterVisibility] = useState(false);
  const { blogs, pagination, loading, query: { contentType }} = useSelector(({ blogs }) => blogs);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();

    axios
      .get(`/media/news/urls`, { cancelToken: ourRequest.token })
      .then((res) => {
        if (res.status === 200) {
          setLinks(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset > 2000 && window.innerWidth > 959) {
        setFilterVisibility(true);
      }
      if (window.pageYOffset > 900 && window.innerWidth < 959) {
        setFilterVisibility(true);
      }
      if (window.pageYOffset < 2000 && window.innerWidth > 959) {
        setFilterVisibility(false);
      }
      if (window.pageYOffset < 900 && window.innerWidth < 959) {
        setFilterVisibility(false);
      }
    };

    return () => {
      window.onscroll = null;
    };
  }, []);

  const handleAddBlogs = () => {
    const page = pagination.page + 1;
    dispatch(setAddBlogsQuery(page));
  };

  return (
    <div name="blogs" className={styles.blogs}>
      <div style={{ display: "none" }}>
        {links.map((link) => <a key={link} href={`/blog/${link}`}>link</a>)}
      </div>
      <Element name="scroll-to-filter" />;
      <BlogsFilters filter={contentType} />
      {filterVisibility && <FloatFilter filter={contentType} />}
      <BlogsRoutes match={match} />
      {blogs.length !== pagination.totalCount && (
        <div className={styles.addBlogs}>
          {loading ? (
            <Preloader />
          ) : (
            <AddContentButton onClick={handleAddBlogs} />
          )}
        </div>
      )}
    </div>
  );
};
