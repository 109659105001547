import { ACTION } from "../constants/types";

/**
 * @desc function for set admin private files pagination
 * @param {object} query - number of page, size per page
 * @return {{type: string, payload: *}}
 */
export const setAdminFilesQuery = query => {
    return {
        type: ACTION.SET_ADMIN_FILES_QUERY,
        payload: query,
    };
};

/**
 * @desc function for successful receipt admin private files
 * @param {object} files - object with admin private files, page, totalCount
 * @return {{type: string, payload: *}}
 */
export const getAdminFilesSuccess = files => {
    return {
        type: ACTION.GET_ADMIN_FILES_SUCCESS,
        payload: files,
    };
};

/**
 * @desc function for failed receipt admin private files
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const getAdminFilesFailure = error => {
    return {
        type: ACTION.GET_ADMIN_FILES_FAILURE,
        payload: error,
    };
};

/**
 * @desc function for edit admin private file fields
 * @param {string} name - field name
 * @param {any} value - field value
 * @return {{type: string, payload: *}}
 */
export const editPrivateFileField = (name, value) => {
    return {
        type: ACTION.EDIT_PRIVATE_FILE_FIELDS,
        payload: { [name]: value },
    };
};

/**
 * @desc function for clean admin private file fields
 * @return {{type: string}}
 */
export const cleanAdminFiles = () => {
    return {
        type: ACTION.CLEAN_ADMIN_FILES,
    };
};

/**
 * @desc function for add admin private file
 * @param {object} file - object with admin private file
 * @return {{type: string, payload: *}}
 */
export const addPrivateFileRequest = file => {
    return {
        type: ACTION.ADD_PRIVATE_FILE_REQUEST,
        payload: file,
    };
};

/**
 * @desc function for successful addition admin private file
 * @return {{type: string}}
 */
export const addPrivateFileSuccess = () => {
    return {
        type: ACTION.ADD_PRIVATE_FILE_SUCCESS,
    };
};

/**
 * @desc function for failed addition admin private file
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const addPrivateFileFailure = error => {
    return {
        type: ACTION.ADD_PRIVATE_FILE_FAILURE,
        payload: error,
    };
};

/**
 * @desc function for add admin private file cropped image
 * @param {object} file - object with cropped image file
 * @return {{type: string, payload: *}}
 */
export const addPrivateFileCroppedImageRequest = file => {
    return {
        type: ACTION.ADD_PRIVATE_FILE_CROPPED_IMAGE_REQUEST,
        payload: file,
    };
};

/**
 * @desc function for successful addition admin private file cropped image
 * @param {string} imageUrl - image url
 * @return {{type: string, payload: *}}
 */
export const addPrivateFileCroppedImageSuccess = imageUrl => {
    return {
        type: ACTION.ADD_PRIVATE_FILE_CROPPED_IMAGE_SUCCESS,
        payload: imageUrl,
    };
};

/**
 * @desc function for failed addition admin private file cropped image
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const addPrivateFileCroppedImageFailure = error => {
    return {
        type: ACTION.ADD_PRIVATE_FILE_CROPPED_IMAGE_FAILURE,
        payload: error,
    };
};




/**
 * @desc function for delete admin private file cropped image
 * @param {string} url - image url
 * @return {{type: string, payload: *}}
 */
 export const deletePrivateFileCroppedImageRequest = url => {
  return {
    type: ACTION.DELETE_PRIVATE_FILE_CROPPED_IMAGE_REQUEST,
    payload: url,
  };
};

/**
 * @desc function for successful delete admin private file cropped image
 * @param {array} imageUrl - array with image urls
 * @return {{type: string, payload: *}}
 */
 export const deletePrivateFileCroppedImageSuccess = imageUrl => {
  return {
    type: ACTION.DELETE_PRIVATE_FILE_CROPPED_IMAGE_SUCCESS,
    payload: imageUrl,
  };
};

/**
 * @desc function for failed delete admin private file cropped image
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const deletePrivateFileCroppedImageFailure = error => {
  return {
    type: ACTION.DELETE_PRIVATE_FILE_CROPPED_IMAGE_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for opening successful modal window
 * @param {object} selectedFile - selected admin private file
 * @return {{type: string, payload: *}}
 */
export const openSuccessPrivateFileModal = selectedFile => {
    return {
        type: ACTION.PRIVATE_FILE_SUCCESS_MODAL_OPEN,
        payload: selectedFile,
    };
};

/**
 * @desc function for closing successful modal window
 * @return {{type: string}}
 */
export const closeSuccessPrivateFileModal = () => {
    return {
        type: ACTION.PRIVATE_FILE_SUCCESS_MODAL_CLOSE,
    };
};

/**
 * @desc function for fill admin private file form when editing
 * @param {object} file - object with selected admin private file
 * @return {{type: string, payload: *}}
 */
export const fillPrivateFileForm = file => {
    return {
        type: ACTION.FILL_PRIVATE_FILE_FORM,
        payload: file,
    };
};

/**
 * @desc function for clean admin private file form
 * @return {{type: string}}
 */
export const cleanPrivateFileForm = () => {
    return {
        type: ACTION.CLEAN_PRIVATE_FILE_FORM,
    };
};

/**
 * @desc function for edit admin private file
 * @param {object} file - object with selected admin private file
 * @param {number} fileId - selected admin private file id
 * @return {{type: string, payload: *}}
 */
export const editPrivateFileRequest = (file, fileId) => {
    return {
        type: ACTION.EDIT_PRIVATE_FILE_REQUEST,
        payload: { file, fileId },
    };
};

/**
 * @desc function for successful editing admin private file
 * @return {{type: string}}
 */
export const editPrivateFileSuccess = () => {
    return {
        type: ACTION.EDIT_PRIVATE_FILE_SUCCESS,
    };
};

/**
 * @desc function for failed editing admin private file
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const editPrivateFileFailure = error => {
    return {
        type: ACTION.EDIT_PRIVATE_FILE_FAILURE,
        payload: error,
    };
};

/**
 * @desc function for delete admin private file
 * @param {number} fileId - admin private file id
 * @return {{type: string, payload: *}}
 */
export const deletePrivateFileRequest = fileId => {
    return {
        type: ACTION.DELETE_PRIVATE_FILE_REQUEST,
        payload: fileId,
    };
};

/**
 * @desc function for successful deletion admin private file
 * @return {{type: string}}
 */
export const deletePrivateFileSuccess = () => {
    return {
        type: ACTION.DELETE_PRIVATE_FILE_SUCCESS,
    };
};

/**
 * @desc function for failed deletion admin private file
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const deletePrivateFileFailure = error => {
    return {
        type: ACTION.DELETE_PRIVATE_FILE_FAILURE,
        payload: error,
    };
};

/**
 * @desc function open admin private file modal window
 * @param {object} selectedFile - object with selected admin private file
 * @param {string} modalContent - string with modal content
 * @return {{type: string, payload: *}}
 */
export const openPrivateFileModal = (selectedFile, modalContent) => {
    return {
        type: ACTION.PRIVATE_FILE_MODAL_OPEN,
        payload: { selectedFile, modalContent },
    };
};

/**
 * @desc function for close admin private file modal window
 * @return {{type: string}}
 */
export const closePrivateFileModal = () => {
    return {
        type: ACTION.PRIVATE_FILE_MODAL_CLOSE,
    };
};

/**
 * @desc function for enable admin private file
 * @param {number} fileId - admin private file id
 * @param {boolean} active - toggle value
 * @return {{type: string, payload: *}}
 */
export const enablePrivateFileRequest = (fileId, active) => {
  return {
      type: ACTION.ENABLE_ADMIN_FILE_REQUEST,
      payload: { id: fileId, active },
  };
};

/**
 * @desc function for failed enable admin private file
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const enablePrivateFileFailure = error => {
  return {
      type: ACTION.ENABLE_ADMIN_FILE_FAILURE,
      payload: error,
  };
};

/**
 * @desc function for edit admin private file order
 * @param {number} fileOrder - admin private file order
 * @return {{type: string, payload: *}}
 */
export const editPrivateFileOrder = fileOrder => {
  return {
    type: ACTION.EDIT_ADMIN_FILE_ORDER,
    payload: fileOrder,
  };
};

/**
 * @desc function for set admin private file order
 * @param {number} fileId - admin private file id
 * @param {number} fileOrder - admin private file order
 * @return {{type: string, payload: *}}
 */
export const editPrivateFileOrderRequest = (fileId, fileOrder) => {
  return {
    type: ACTION.EDIT_ADMIN_FILE_ORDER_REQUEST,
    payload: { fileId, fileOrder },
  };
};

/**
 * @desc function for successful editing admin private file order
 * @return {{type: string}}
 */
export const editPrivateFileOrderSuccess = () => {
  return {
    type: ACTION.EDIT_ADMIN_FILE_ORDER_SUCCESS,
  };
};

/**
 * @desc function for failed editing admin private file order
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const editPrivateFileOrderFailure = error => {
  return {
    type: ACTION.EDIT_ADMIN_FILE_ORDER_FAILURE,
    payload: error,
  };
};
