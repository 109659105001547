import { Header } from "../../components/сommon/Header";
import { Footer } from "../../components/сommon/Footer";
import { ChevronButton } from "../../components/сommon/buttons/ChevronButton";
import { GoToTop } from "../../components/сommon/GoToTop";
import { ScrollToTop } from "../../components/сommon/ScrollToTop";
import { MergeImagesSection } from "../../components/MergeImagesSection";
import { ErrorModal } from "../../components/modals/ErrorModal";

/**
 * @desc Watermark UI
 * @return {JSX.Element}
 */
export const MergeImagesPage = () => (
  <>
    <GoToTop />
    <ScrollToTop />
    <Header />
    <ChevronButton />
    <MergeImagesSection />
    <Footer />
    <ErrorModal />
  </>
);
