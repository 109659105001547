import axios from "axios";
import { put, take, fork, call, all, cancel } from "redux-saga/effects";
import { ACTION } from "../constants/types";
import { logInSuccess, logInFailure, logOutSuccess, logOutFailure, cleanAdminBlogs, cleanAdminFiles, cleanUsers, cleanPrivateContent } from "../actions/";

/**
 * @desc request for auth log in
 * @param {object} user - user data
 */
export function* fetchUserLogin(user) {
  try {
    const res = yield call(axios.post, "uaa/login", user);
    if (res.status === 200 && res.data.accessToken && res.data.refreshToken && res.data.role) {
      yield put(logInSuccess(res.data.accessToken, res.data.role, res.data.email));
      localStorage.setItem("bpiAuthToken", `Bearer ${res.data.accessToken}`);
      localStorage.setItem("bpiRefreshToken", res.data.refreshToken);
      localStorage.setItem("bpiUserRole", res.data.role);
      localStorage.setItem("bpiUserEmail", res.data.email);
    } else {
      yield put(logInFailure(res.data));
    }
  } catch (error) {
    yield put(logInFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {object} - user data
 */
export function* userLogInFlow() {
  while (true) {
    const { payload } = yield take(ACTION.SIGNIN_USER_REQUEST);
    yield fork(fetchUserLogin, payload);
  }
}

/**
 * @desc request for auth log out
 * @param {string} refreshToken - refresh token
 */
export function* fetchUserLogout(refreshToken) {
  try {
    const res = yield call(axios.post, "uaa/logout", { refreshToken });
    if (res.status === 200) {
      yield put(logOutSuccess());
    }
  } catch (error) {
    yield put(logOutFailure(error.response.data));
  }
}

/**
 * @desc function for action interception
 * @returns {string} - refresh token
 */
export function* userLogOutFlow() {
  while (true) {
    const { payload } = yield take(ACTION.LOGOUT_USER_REQUEST);
    const task = yield fork(fetchUserLogout, payload);
    const action = yield take(ACTION.LOGOUT_USER_SUCCESS);
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

export function* watchLogOutFlow() {
  while (true) {
    yield take(ACTION.LOGOUT_USER_SUCCESS);
    yield put(cleanAdminBlogs());
    yield put(cleanAdminFiles());
    yield put(cleanUsers());
    yield put(cleanPrivateContent());
    localStorage.removeItem("bpiAuthToken");
    localStorage.removeItem("bpiRefreshToken");
    localStorage.removeItem("bpiUserRole");
    localStorage.removeItem("bpiUserEmail");
  }
}

export function* watchUserAuth() {
  yield all([fork(userLogInFlow), fork(userLogOutFlow), fork(watchLogOutFlow)]);
}
