import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  scroll_up_btn: {
    position: "fixed",
    zIndex: 20,
    bottom: 110,
    right: 30,
  },
  float_btn: {
    background: theme.palette.primary.main,
    width: 60,
    height: 60,
    borderRadius: 0,
    "@media (max-width: 959px)": {
      width: 45,
      height: 45,
    },

    "&:hover": {
      background: theme.palette.primary.dark,
    }
  },
  scroll_icon: {
    width: "100%",
    height: "38px",
    transform: "rotate(-90deg)",
    "@media (max-width: 959px)": {
      height: "28px",
    }
  }
}));
