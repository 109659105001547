import React from "react";

import { Header } from "../../components/сommon/Header";
import { BackButton } from "../../components/сommon/BackButton";
import { RegistrationForm } from "../../components/forms/RegistrationForm";

/**
 * @desc Auth sign up UI
 * @return {JSX.Element}
 */
export const SignUpPage = () => (
  <>
    <Header />
    <BackButton />
    <RegistrationForm />
  </>
);
