import SvgIcon from "@material-ui/core/SvgIcon";

export const CheckboxCheckedIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M18.8654 0.00878906H3.77637C2.11951 0.00878906 0.776367 1.35193 0.776367 3.00879V18.0978C0.776367 19.7546 2.11951 21.0978 3.77637 21.0978H18.8654C20.5222 21.0978 21.8654 19.7546 21.8654 18.0978V3.00879C21.8654 1.35193 20.5222 0.00878906 18.8654 0.00878906Z"
      fill="white"
      transform="translate(0,1)"
    />
    <path
      d="M8.6523 16.511L3.2463 11.104C3.09047 10.948 3.00293 10.7365 3.00293 10.516C3.00293 10.2955 3.09047 10.084 3.2463 9.92796L4.4223 8.75196C4.57831 8.59612 4.7898 8.50859 5.0103 8.50859C5.23081 8.50859 5.4423 8.59612 5.5983 8.75196L9.2403 12.394L17.0403 4.59396C17.1963 4.43812 17.4078 4.35059 17.6283 4.35059C17.8488 4.35059 18.0603 4.43812 18.2163 4.59396L19.3943 5.77196C19.5501 5.92797 19.6377 6.13945 19.6377 6.35996C19.6377 6.58047 19.5501 6.79196 19.3943 6.94796L9.8283 16.511C9.6723 16.6668 9.46081 16.7543 9.2403 16.7543C9.0198 16.7543 8.80831 16.6668 8.6523 16.511Z"
      fill="black"
      transform="translate(0,1)"
    />
  </SvgIcon>
);
