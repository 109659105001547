import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  blog_section: {
    margin: "65px 0 180px",
    padding: "0 140px",
    [theme.breakpoints.down("lg")]: {
      padding: "0 81px"
    },
    [theme.breakpoints.down("md")]: {
      padding: "0 50px"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "65px 0 60px",
      padding: "0 36px"
    },
    [theme.breakpoints.down("xs")]: {
      margin: "50px 0 45px",
    },
  },
  lastNews_header: {
    marginBottom: 10,
    fontSize: 20,
    lineHeight: "23px",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      lineHeight: "14px",
    },
  },
  blog_goback: {
    margin: "0 0 15px -140px",
    [theme.breakpoints.down("lg")]: {
      margin: "0 0 15px -81px"
    },
    [theme.breakpoints.down("md")]: {
      margin: "0 0 15px -50px"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 15px -36px"
    },
  },
}));

export default useStyles;
