import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  table_section: {
    padding: "20px 140px",
    [theme.breakpoints.down("md")]: {
      padding: "20px 81px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "20px 16px",
    },
  },
  table_title: {
    margin: "16px 0 24px 0",
  },
  table_btn: {
    width: 140,
    height: 36,
    margin: "0 20px",
  },
  tablefield_id: {
    maxWidth: 50,
  },
  tablefield_email: {
    width: 400,
  },
  tablefield_role: {
    textAlign: "center",
  },
  tablefield_title: {
    width: 400,
    fontSize: 16,
  },
  tablefield_description: {
    width: 450,
    fontSize: 16,
  },
  tablefield_fileDescription: {
    width: 300,
    fontSize: 16,
  },
  table_filter: {
    display: "flex",
    alignItems: "center",
    minWidth: 200,
  },
  tablefield_type: {
    width: 250,
    fontSize: 16,
  },
  tablefield_size: {
    width: 200,
    fontSize: 16,
  },
  tablefield_download: {
    width: 100,
    fontSize: 16,
  },
}));

export default useStyles;
