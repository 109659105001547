import axios from "axios";
import { put, call, take, fork, all, cancel, select, throttle } from "redux-saga/effects";
import { ACTION } from "../constants/types";
import {
  getUsersSuccess,
  getUsersFailure,
  enableUsersFailure,
  deleteUserSuccess,
  deleteUserFailure,
  editUserSuccess,
  editUserFailure,
} from "../actions/";

/**
 * @desc request for receipt admin users
 * @returns {object} list of admin users and pagination
 */
export function* fetchUsersRequest() {
  yield put({ type: ACTION.GET_USERS_REQUEST });
  const { users: { query: queryParams } } = yield select();
  try {
    const res = yield call(axios.get, "uaa/user", { params: queryParams });
    yield put(
      getUsersSuccess({ users: res.data.content, page: res.data.pageable.pageNumber, totalCount: res.data.totalElements })
    );
  } catch (error) {
    yield put(getUsersFailure(error));
  }
}

/**
 * @desc request for enable admin users
 * @param {number} userId - admin user id
 * @param {boolean} enable - toggle value
 */
export function* fetchEnableUser(userId, enable) {
  try {
    const res = yield call(axios.put, `uaa/user/enable/${userId}`, enable);
    yield res.data && fork(fetchUsersRequest);
  } catch (error) {
    yield put(enableUsersFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {id: number, enable: boolean}
 */
export function* enableUser() {
  while (true) {
    const { payload: { id, enable } } = yield take(ACTION.ENABLE_USER_REQUEST);
    const task = yield fork(fetchEnableUser, id, enable);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for enable admin users
 * @param {number} userId - admin user id
 */
export function* fetchDeleteUser(userId) {
  try {
    const res = yield call(axios.delete, `uaa/user/${userId}`);
    yield res && put(deleteUserSuccess());
    yield fork(fetchUsersRequest);
  } catch (error) {
    yield put(deleteUserFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {number} - admin user id
 */
export function* deleteUser() {
  while (true) {
    const { payload: id } = yield take(ACTION.DELETE_USER_REQUEST);
    const task = yield fork(fetchDeleteUser, id);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for enable admin users
 * @param {number} userId - admin user id
 * @param {string} role - admin user role
 */
export function* fetchEditUser(userId, role) {
  try {
    const res = yield call(axios.put, `uaa/user/${userId}`, role);
    yield res.data && put(editUserSuccess());
    yield fork(fetchUsersRequest);
  } catch (error) {
    yield put(editUserFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {id: number, role: string}
 */
export function* editUser() {
  while (true) {
    const { payload: { id, role } } = yield take(ACTION.EDIT_USER_REQUEST);
    const task = yield fork(fetchEditUser, id, role);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

export function* watchUsersManagement() {
  yield all([throttle(500, ACTION.SET_USERS_QUERY, fetchUsersRequest), fork(enableUser), fork(deleteUser), fork(editUser)]);
}
