import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { Preloader } from "../../сommon/Preloader";
import { ErrorHandler } from "../../сommon/ErrorHandler";
import UploadValidator from "../../сommon/UploadValidator";

import { addBlogsMainImageRequest, deleteBlogsMainImageRequest } from "../../../store/actions";

import useStyles from "../useStyles";

/**
 * @desc function for create blog main image for upload
 * @returns {JSX.Element}
 */
export const BlogMainImageUpload = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { blog, mainImageUploading, mainImageUploadError } = useSelector(({ adminBlogs }) => adminBlogs);
  const [sizeAlertOpen, setSizeAlertOpen] = useState(false);

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > 1024 * 1024 * 10) {
        setSizeAlertOpen(true);
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      dispatch(addBlogsMainImageRequest(formData));
    }
  };

  const removeImg = (url) => {
    dispatch(deleteBlogsMainImageRequest(url));
  };

  return (
    <div className={classes.upload_block}>
      <Typography align="center" className={classes.upload_title}>
        Laden des Hauptfotos
      </Typography>

      {!!blog.mainImages.length && (
        <div className={classes.upload_preview_img_box_grid}>
          {blog.mainImages.map((url) => (
            <div className={classes.upload_preview_img_wrapper_grid} key={url}>
              <img
                className={classes.upload_preview_img_grid}
                alt="Blog main preview"
                src={url}
              />
              <IconButton
                onClick={() => removeImg(url)}
                className={classes.upload_close_block_btn_grid}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ))}
        </div>
      )}

      {mainImageUploading ? (
        <Preloader className={classes.upload_preload_wrap} />
      ) : (
        <UploadValidator
          text="Bild hochladen"
          accept="image/*"
          onChange={onSelectFile}
          validators={["required"]}
          errorMessages={["Dieses Feld wird benötigt"]}
          value={!!blog.mainImages.length ? blog.mainImages.length : null}
          classNames={classes}
          startIcon={<CameraAltIcon />}
        />
      )}

      <Snackbar
        open={sizeAlertOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => setSizeAlertOpen(false)}
      >
        <MuiAlert elevation={6} variant="filled" onClose={() => setSizeAlertOpen(false)} severity="error">
          Dateigröße größer als 10 MB
        </MuiAlert>
      </Snackbar>

      {!!mainImageUploadError && <ErrorHandler error={mainImageUploadError} />}
    </div>
  );
};
