import { Header } from "../../components/сommon/Header";
import { Footer } from "../../components/сommon/Footer";
import { PersonalMenu } from "../../components/сommon/PersonalMenu";
import { FilesTable } from "../../components/tables/FilesTable";
import { GoToTop } from "../../components/сommon/GoToTop";
import { ScrollToTop } from "../../components/сommon/ScrollToTop";
import { ErrorModal } from "../../components/modals/ErrorModal";

/**
 * @desc Private files UI
 * @return {JSX.Element}
 */
export const PrivateFilesPage = () => (
  <>
    <GoToTop />
    <ScrollToTop />
    <Header />
    <PersonalMenu />
    <FilesTable />
    <Footer />
    <ErrorModal />
  </>
);
