import { Switch, Redirect, Route } from "react-router-dom";

import { BlogsList } from "../components/BlogsList";


const BlogListForYou = () => <BlogsList filter="FOR_YOU" />;

const BlogListInnovative = () => <BlogsList filter="INNOVATIVE" />;

const BlogListDigital = () => <BlogsList filter="DIGITAL" />;

export const BlogsRoutes = ({ match }) => {

  return (
    <Switch>
      <Route exact path={`${match.url}`} component={BlogsList} />
      <Route exact path={`${match.url}/fuer_sie`} component={BlogListForYou} />
      <Route exact path={`${match.url}/innovativ`} component={BlogListInnovative} />
      <Route exact path={`${match.url}/digital`} component={BlogListDigital} />
      <Redirect to="/" />
    </Switch>
  );
};
