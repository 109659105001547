import { useState } from "react";
import { useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import useStyles from "../useStyles";

/**
 * @desc function for create table content type filter
 * @param {string} contentType - current table content type
 * @param {function} setQuery - set new table content type
 * @param {array} options - array with table content types
 * @returns {JSX.Element}
 */
export const ContentTypeFilter = ({ contentType, setQuery, options }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen((prevState) => !prevState);

  const handleChange = (option) => {
    setOpen(false);
    dispatch(setQuery({ contentType: option.value, page: 0 }));
  };

  return (
    <div className={classes.wrapper}>
      <Button
        onClick={handleClick}
        className={classes.filter_btn}
        endIcon={
          open ? (
            <ArrowDropDownIcon className={classes.filter_icon} />
          ) : (
            <ArrowDropUpIcon className={classes.filter_icon} />
          )
        }
      >
        <Typography className={classes.table_filter_text}>
          {options.filter((option) => option.value === contentType)[0].title}
        </Typography>
      </Button>
      {open && (
        <div className={classes.paper}>
          {options.map((option, index) => (
            <div
              key={option.id}
              onClick={() => handleChange(option)}
              className={`${classes.option} ${option.value === contentType ? classes.option_active : ""}`}
            >
              {option.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
