import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

import { Header } from "../../components/сommon/Header";
import { Footer } from "../../components/сommon/Footer";
import { GoToTop } from "../../components/сommon/GoToTop";
import { ChevronButton } from "../../components/сommon/buttons/ChevronButton";
import { Preloader } from "../../components/сommon/Preloader";
import { ScrollToTop } from "../../components/сommon/ScrollToTop";
import { BlogSection } from "../../components/сommon/BlogSection";

/**
 * @desc Blog UI
 * @return {JSX.Element}
 */
export const BlogPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState({});

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    setLoading(true);

    axios
      .get(`/media/news/${id}`, { cancelToken: ourRequest.token })
      .then(res => {
        if (res.status === 200) {
          setNews(res.data);
          setLoading(false);
        }
      })
      .catch(error => {
        history.push("/");
        console.error(error);
      });

    return () => {
      ourRequest.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return loading
    ? (<Preloader className="preloadingSection" />)
    : (
      <>
        <GoToTop />
        <ScrollToTop id={id} />
        <Header link="unlock" />
        <ChevronButton />
        <BlogSection news={news} preview={false} />
        <Footer />
      </>
    );
};
