import { useState } from "react";
import ReactPlayer from "react-player";
import { Link } from "react-scroll";

import styles from "./Intro.module.scss";
import { ArrowDownIcon } from "../сommon/icons/ArrowDownIcon";
import { VideoOverlayIcon } from "../сommon/icons/VideoOverlayIcon";

/**
 * @desc function for create intro banner at home page
 * @param {string} url - intro banner url
 * @param {string} type - intro banner type
 * @returns {JSX.Element}
 */
export const Intro = ({ url, type }) => {
  const [play, setPlay] = useState(false);

  const handlePlay = () => {
    setPlay(true);
  }

  return (
    <div className={styles.intro}>
      {!play &&
        (type !== "VIDEO" && <div className={styles.introOverlay} onClick={handlePlay}>
          <VideoOverlayIcon style={{ width: "100%", height: "100%" }} />
        </div>)
      }
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        controls={true}
        playing={play}
        loop={true}
      />
      {type !== "VIDEO" &&
        <Link to="blogs" smooth={true} offset={-50} duration={500}>
          <button className={styles.introButtonDown}>
            <ArrowDownIcon />
          </button>
        </Link>
      }
    </div>
  );
}
