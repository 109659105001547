import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes/Routes";
import { ThemeProvider, StylesProvider, createGenerateClassName } from "@material-ui/core/styles";
import NoSsr from "@material-ui/core/NoSsr";
import { store } from "./store";
import i18n from "i18n-js";
import { fallbacks, translations, locale } from "./assets/locale/locale";
import theme from "./theme";

i18n.fallbacks = fallbacks;
i18n.translations = translations;
i18n.locale = locale;

export const App = () => {
  const generateClassName = createGenerateClassName({
    productionPrefix: navigator.userAgent === "ReactSnap" ? "snap" : "jss",
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <NoSsr>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
      </NoSsr>
    </StylesProvider>
  );
};
