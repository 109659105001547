import { GoToTop } from "../../components/сommon/GoToTop";
import { ScrollToTop } from "../../components/сommon/ScrollToTop";
import { Header } from "../../components/сommon/Header";
import { ChevronButton } from "../../components/сommon/buttons/ChevronButton";
import { ImpressumContent } from "../../components/ImpressumContent";
import { Footer } from "../../components/сommon/Footer";

/**
 * @desc Impressum UI
 * @return {JSX.Element}
 */
export const ImpressumPage = () => (
  <>
    <GoToTop />
    <ScrollToTop />
    <Header />
    <ChevronButton />
    <ImpressumContent />
    <Footer />
  </>
);
