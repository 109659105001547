import { all } from "redux-saga/effects";
import { watchUserAuth } from "./loginSaga";
import { watchUsersManagement } from "./usersSaga";
import { watchAdminBlogsManagement } from "./adminBlogsSaga";
import { watchBlogs } from "./blogsSaga";
import { watchAdminFilesManagement } from "./adminFilesSaga";
import { watchPrivateFiles } from "./privateFilesSaga";
import { watchErrorHanding } from "./errorsSaga";

export default function* rootSaga() {
  yield all([
    watchUserAuth(),
    watchUsersManagement(),
    watchAdminBlogsManagement(),
    watchBlogs(),
    watchAdminFilesManagement(),
    watchErrorHanding(),
    watchPrivateFiles(),
  ]);
}
