import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
  },
  filter_btn: {
    height: 28,
    width: 200,
    color: "transparent",
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "transparent !important",
    },
    padding: "0 6px 0 0",
  },
  table_filter_text: {
    color: "#393737",
    fontSize: 14,
    fontWeight: 500,
    width: 180,
    textAlign: "left",
  },
  filter_icon: {
    color: "#393737",
    marginRight: 0,
  },
  option: {
    fontSize: 14,
    fontWeight: 400,
    height: 24,
    cursor: "pointer",
    padding: "3px 6px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  option_active: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  paper: {
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    padding: "4px 0",
    backgroundColor: theme.palette.background.paper,
    width: 200,
    position: "absolute",
    top: 28,
    left: "calc(50% - 100px)",
  },
}));

export default useStyles;

