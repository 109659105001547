import axios from "axios";
import { put, call, all, takeLatest, take, fork, cancel, select } from "redux-saga/effects";
import { ACTION } from "../constants/types";
import {
  getAdminFilesSuccess,
  getAdminFilesFailure,
  addPrivateFileSuccess,
  openSuccessPrivateFileModal,
  addPrivateFileFailure,
  addPrivateFileCroppedImageSuccess,
  addPrivateFileCroppedImageFailure,
  deletePrivateFileCroppedImageSuccess,
  deletePrivateFileCroppedImageFailure,
  editPrivateFileSuccess,
  editPrivateFileFailure,
  deletePrivateFileSuccess,
  deletePrivateFileFailure,
  enablePrivateFileFailure,
  editPrivateFileOrderSuccess,
  editPrivateFileOrderFailure
} from "../actions/";

/**
 * @desc request for receipt admin private files
 * @returns {object} list of admin private files and pagination
 */
export function* fetchAdminFiles() {
  yield put({ type: ACTION.GET_ADMIN_FILES_REQUEST });
  const { adminFiles: { query: queryParams } } = yield select();
  try {
    const res = yield call(axios.get, "media/content", { params: queryParams });
    yield put(
      getAdminFilesSuccess({ files: res.data.content, page: res.data.pageable.pageNumber, totalCount: res.data.totalElements })
    );
  } catch (error) {
    yield put(getAdminFilesFailure(error));
  }
}

/**
 * @desc request for add admin private file
 * @param {object} file - object with admin private file
 * @returns {object} success message
 */
function* fetchAddFile(file) {
  try {
    const res = yield call(axios.post, "media/content", file);
    yield put(addPrivateFileSuccess());
    yield put(openSuccessPrivateFileModal(res.data));
  } catch (error) {
    yield put(addPrivateFileFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {object} - object with admin private file
 */
export function* addFile() {
  while (true) {
    const { payload: file } = yield take(ACTION.ADD_PRIVATE_FILE_REQUEST);
    const task = yield fork(fetchAddFile, file);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for admin private file cropped image upload
 * @param {object} file - object with admin private file cropped image
 * @returns {string} admin private file cropped image url for upload
 */
function* fetchPrivateFileCroppedImageUpload(file) {
  try {
    const res = yield call(axios.post, "file/file/public", file);
    yield put(addPrivateFileCroppedImageSuccess(res.data.file.url));
  } catch (error) {
    yield put(addPrivateFileCroppedImageFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {object} object with admin private file cropped image
 */
export function* privateFileCroppedImageUpload() {
  while (true) {
    const { payload: file } = yield take(ACTION.ADD_PRIVATE_FILE_CROPPED_IMAGE_REQUEST);
    const task = yield fork(fetchPrivateFileCroppedImageUpload, file);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for admin private file cropped image delete
 * @param {string} url - private file cropped image url
 * @returns {array} new cropped images array
 */
 function* fetchDeletePrivateFileCroppedImage(url) {
  try {
    const { adminFiles: { file } } = yield select();
    const res = yield call(axios.delete, `file/file/public?url=${url}`);
    yield res && put(deletePrivateFileCroppedImageSuccess(file.mainImages.filter(img => img !== url)));
  } catch (error) {
    yield put(deletePrivateFileCroppedImageFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {string} cropped image url for delete
 */
export function* deletePrivateFileCroppedImage() {
  while (true) {
    const { payload: url } = yield take(ACTION.DELETE_PRIVATE_FILE_CROPPED_IMAGE_REQUEST);
    const task = yield fork(fetchDeletePrivateFileCroppedImage, url);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for edit admin private file
 * @param {object} file - object with admin private file
 * @param {number} fileId - admin private file id
 * @returns {object} admin private file
 */
function* fetchEditFile(file, fileId) {
  try {
    const res = yield call(axios.post, `media/content/${fileId}`, file);
    yield put(editPrivateFileSuccess());
    yield put(openSuccessPrivateFileModal(res.data));
  } catch (error) {
    yield put(editPrivateFileFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {file: object, fileId: number}
 */
export function* editFile() {
  while (true) {
    const { payload: { file, fileId } } = yield take(ACTION.EDIT_PRIVATE_FILE_REQUEST);
    const task = yield fork(fetchEditFile, file, fileId);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for delete admin private file
 * @param {number} fileId - admin private file id
 */
function* fetchDeleteFile(fileId) {
  try {
    yield call(axios.delete, `media/content/${fileId}`);
    yield put(deletePrivateFileSuccess());
    yield fork(fetchAdminFiles);
  } catch (error) {
    yield put(deletePrivateFileFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {number} admin private file id
 */
export function* deleteFile() {
  while (true) {
    const { payload: fileId } = yield take(ACTION.DELETE_PRIVATE_FILE_REQUEST);
    const task = yield fork(fetchDeleteFile, fileId);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for enable admin private file
 * @param {number} fileId - admin private file id
 * @param {boolean} active - toggle value
 */
export function* fetchEnableFile(fileId, active) {
  try {
    const res = yield call(axios.put, `media/content/${fileId}/activate`, JSON.stringify(active), { headers: { "Content-Type": "application/json" } });
    yield res.data && fork(fetchAdminFiles);
  } catch (error) {
    yield put(enablePrivateFileFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {id: number, active: boolean}
 */
export function* enableFile() {
  while (true) {
    const { payload: { id, active } } = yield take(ACTION.ENABLE_ADMIN_FILE_REQUEST);
    const task = yield fork(fetchEnableFile, id, active);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for enable admin private file
 * @param {number} fileId - admin private file id
 * @param {number} fileOrder - admin private file order
 */
function* fetchEditPrivateFileOrder(fileId, fileOrder) {
  try {
    yield call(axios.put, `media/content/${fileId}/order`, fileOrder, { headers: { "Content-Type": "application/json" }});
    yield put(editPrivateFileOrderSuccess());
    yield fork(fetchAdminFiles);
  } catch (error) {
    yield put(editPrivateFileOrderFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {fileId: number, fileOrder: number}
 */
export function* editPrivateFileOrder() {
  while (true) {
    const { payload: { fileId, fileOrder } } = yield take(ACTION.EDIT_ADMIN_FILE_ORDER_REQUEST);
    const task = yield fork(fetchEditPrivateFileOrder, fileId, fileOrder);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

export function* watchAdminFilesManagement() {
  yield all([
    takeLatest(ACTION.SET_ADMIN_FILES_QUERY, fetchAdminFiles),
    fork(addFile),
    fork(privateFileCroppedImageUpload),
    fork(deletePrivateFileCroppedImage),
    fork(editFile),
    fork(deleteFile),
    fork(enableFile),
    fork(editPrivateFileOrder),
  ]);
}
