export const BlogLogo = ({ className }) => (
  <svg className={className} width="190" height="84" viewBox="0 0 190 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.7241 35.32L22.8831 11.861H22.7831L17.0421 35.32H9.36812L0.245117 0.85498H7.82312L13.2781 24.314H13.3781L19.3631 0.85498H26.4631L32.3551 24.604H32.4491L38.0971 0.85498H45.5331L36.2571 35.32H28.7241Z" fill="#161615" />
    <path d="M55.9022 0.85498V35.32H48.3242V0.85498H55.9022Z" fill="#161615" />
    <path d="M80.4717 0.854777C81.8986 0.833257 83.3161 1.08725 84.6467 1.60279C85.834 2.06889 86.9169 2.76602 87.8327 3.65379C88.708 4.5099 89.398 5.53682 89.8597 6.67079C90.3324 7.84416 90.5702 9.09885 90.5597 10.3638C90.6059 12.1997 90.1646 14.015 89.2807 15.6248C88.3178 17.1966 86.8438 18.3896 85.1057 19.0038V19.1038C85.9565 19.3192 86.7482 19.7224 87.4227 20.2838C88.0341 20.8149 88.5425 21.4538 88.9227 22.1688C89.3187 22.92 89.6033 23.7247 89.7677 24.5578C89.9418 25.419 90.0607 26.2904 90.1237 27.1668C90.1557 27.7141 90.1891 28.3578 90.2237 29.0978C90.2537 29.8378 90.3117 30.5978 90.3927 31.3668C90.4663 32.1087 90.5953 32.8441 90.7787 33.5668C90.9299 34.2 91.2015 34.7982 91.5787 35.3288H83.9937C83.5424 34.0714 83.2822 32.7534 83.2217 31.4188C83.1251 29.9061 82.9804 28.4581 82.7877 27.0748C82.6772 25.6141 82.1021 24.2272 81.1467 23.1168C79.9988 22.1744 78.5257 21.7224 77.0467 21.8588H69.4637V35.3288H61.8857V0.86378L80.4717 0.854777ZM77.7717 16.4458C79.1731 16.5518 80.5646 16.1394 81.6817 15.2868C82.1549 14.7885 82.5157 14.1947 82.74 13.5452C82.9642 12.8958 83.0467 12.2058 82.9817 11.5218C83.0432 10.8564 82.9587 10.1856 82.7342 9.55622C82.5096 8.92685 82.1505 8.35405 81.6817 7.87779C80.5576 7.04108 79.1691 6.63839 77.7717 6.74378H69.4717V16.4438L77.7717 16.4458Z" fill="#161615" />
    <path d="M28.7241 83.59L22.8831 60.131H22.7831L17.0421 83.59H9.36812L0.245117 49.125H7.82312L13.2781 72.584H13.3781L19.3631 49.125H26.4631L32.3551 72.874H32.4491L38.0971 49.125H45.5331L36.2571 83.59H28.7241Z" fill="#161615" />
    <path d="M55.9022 49.125V83.59H48.3242V49.125H55.9022Z" fill="#161615" />
    <path d="M80.4717 49.1248C81.8986 49.1034 83.3161 49.3574 84.6467 49.8728C85.834 50.3389 86.9169 51.036 87.8327 51.9238C88.7081 52.7799 89.398 53.8068 89.8597 54.9408C90.3324 56.1142 90.5702 57.3688 90.5597 58.6338C90.6059 60.4697 90.1646 62.285 89.2807 63.8948C88.3178 65.4666 86.8438 66.6596 85.1057 67.2738V67.3738C85.9565 67.5892 86.7482 67.9924 87.4227 68.5538C88.0341 69.0849 88.5425 69.7238 88.9227 70.4388C89.3187 71.19 89.6033 71.9947 89.7677 72.8278C89.9418 73.689 90.0607 74.5604 90.1237 75.4368C90.1557 75.9841 90.1891 76.6278 90.2237 77.3678C90.2537 78.1078 90.3117 78.8678 90.3927 79.6368C90.4663 80.3787 90.5953 81.1141 90.7787 81.8368C90.9299 82.47 91.2015 83.0682 91.5787 83.5988H83.9937C83.5424 82.3414 83.2822 81.0234 83.2217 79.6888C83.1251 78.1761 82.9804 76.7281 82.7877 75.3448C82.6772 73.8841 82.1021 72.4972 81.1467 71.3868C79.9988 70.4443 78.5257 69.9923 77.0467 70.1288H69.4637V83.5988H61.8857V49.1338L80.4717 49.1248ZM77.7717 64.7158C79.1731 64.8218 80.5646 64.4094 81.6817 63.5568C82.1549 63.0585 82.5157 62.4647 82.74 61.8152C82.9642 61.1658 83.0467 60.4758 82.9817 59.7918C83.0432 59.1264 82.9588 58.4556 82.7342 57.8262C82.5097 57.1968 82.1505 56.624 81.6817 56.1478C80.5576 55.311 79.1691 54.9083 77.7717 55.0138H69.4717V64.7138L77.7717 64.7158Z" fill="#161615" />
    <path d="M103.643 49.1245V63.4095L117.104 49.1245H126.565L113.104 62.7365L127.875 83.5896H118.363L107.987 68.0946L103.643 72.4875V83.5876H96.0615V49.1226L103.643 49.1245Z" fill="#161615" />
    <path d="M156.012 49.125V55.497H137.812V62.882H154.512V68.771H137.812V77.218H156.398V83.59H130.234V49.125H156.012Z" fill="#161615" />
    <path d="M168.369 49.125L182.753 72.244H182.853V49.125H189.946V83.59H182.368L168.027 60.517H167.927V83.59H160.842V49.125H168.369Z" fill="#161615" />
  </svg>
);