import { ACTION } from "../constants/types";

/**
 * @desc function for open error modal window
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const openErrorModal = error => {
  return {
    type: ACTION.ERROR_MODAL_OPEN,
    payload: error,
  };
};

/**
 * @desc function for close error modal window
 * @return {{type: string}}
 */
export const closeErrorModal = () => {
  return {
    type: ACTION.ERROR_MODAL_CLOSE,
  };
};
