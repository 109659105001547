import { ACTION } from "../constants/types";

/**
 * @desc function for set blogs pagination
 * @param {object} query - number of page, size per page
 * @return {{type: string, payload: *}}
 */
export const setBlogsQuery = query => {
  return {
    type: ACTION.SET_BLOGS_QUERY,
    payload: query,
  };
};

/**
 * @desc function for successful receipt blogs
 * @param {object} - object with blogs, page, totalCount
 * @return {{type: string, payload: *}}
 */
export const getBlogsSuccess = ({ blogs, page, totalCount }) => {
  return {
    type: ACTION.GET_BLOGS_SUCCESS,
    payload: {
      blogs,
      page,
      totalCount,
    },
  };
};

/**
 * @desc function for failed receipt blogs
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const getBlogsFailure = error => {
  return {
    type: ACTION.GET_BLOGS_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for set add blogs query
 * @param {number} query - number of blogs page
 * @return {{type: string, payload: *}}
 */
export const setAddBlogsQuery = query => {
  return {
    type: ACTION.SET_ADD_BLOGS_QUERY,
    payload: query,
  };
};

/**
 * @desc function for successful addition blogs
 * @param {object} - object with blogs, page, totalCount
 * @return {{type: string, payload: *}}
 */
export const addBlogsSuccess = ({ blogs, page, totalCount }) => {
  return {
    type: ACTION.ADD_BLOGS_SUCCESS,
    payload: {
      blogs,
      page,
      totalCount,
    },
  };
};

/**
 * @desc function for failed addition blogs
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const addBlogsFailure = error => {
  return {
    type: ACTION.ADD_BLOGS_FAILURE,
    payload: error,
  };
};
