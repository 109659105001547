import { Header } from "../../components/сommon/Header";
import { Footer } from "../../components/сommon/Footer";
import { PrivateFileForm } from "../../components/forms/PrivateFileForm";
import { PersonalMenu } from "../../components/сommon/PersonalMenu";
import { GoToTop } from "../../components/сommon/GoToTop";
import { ScrollToTop } from "../../components/сommon/ScrollToTop";
import { ErrorModal } from "../../components/modals/ErrorModal";

/**
 * @desc Form for create private files UI
 * @return {JSX.Element}
 */
export const PrivateFilesFormPage = () => (
  <>
    <GoToTop />
    <ScrollToTop />
    <Header container="container_admin" />
    <PersonalMenu />
    <PrivateFileForm />
    <Footer container="container_admin" />
    <ErrorModal />
  </>
);
