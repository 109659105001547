export const FooterLogoIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="403.275" height="96.567" viewBox="0 0 403.275 96.567">
    <g id="Gruppe_671" data-name="Gruppe 671" transform="translate(-113.338 -294.433)">
      <g id="Gruppe_670" data-name="Gruppe 670">
        <path id="Pfad_1048" data-name="Pfad 1048" d="M212.425,362.37a4.352,4.352,0,0,0-1.886-.33h-4.217v5.4h4.217a3.99,3.99,0,0,0,2.289-.607,2.356,2.356,0,0,0,.882-2.132,2.3,2.3,0,0,0-1.285-2.333m-8.231-2.145h7.028a4.813,4.813,0,0,1,3.372,1.175,4.247,4.247,0,0,1,1.245,3.291,4.781,4.781,0,0,1-1.126,3.191,4.326,4.326,0,0,1-3.491,1.344h-4.9v6.6h-2.128Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1049" data-name="Pfad 1049" d="M218.047,360.175h1.888v5.812a5.326,5.326,0,0,1,1.246-1.206,3.967,3.967,0,0,1,2.288-.608,3.453,3.453,0,0,1,3.373,1.734,6.549,6.549,0,0,1,.442,2.622v7.3h-1.928v-7.167a4.2,4.2,0,0,0-.32-1.845,2.075,2.075,0,0,0-1.969-.929,3.238,3.238,0,0,0-2.168.819c-.642.538-.964,1.574-.964,3.09v6.032h-1.888" fill="#161615" />
        <path id="Pfad_1050" data-name="Pfad 1050" d="M236.237,368.93a1.044,1.044,0,0,0,.885-.551,1.77,1.77,0,0,0,.121-.738,1.513,1.513,0,0,0-.724-1.4,3.575,3.575,0,0,0-1.967-.449,2.5,2.5,0,0,0-2.128.81,2.318,2.318,0,0,0-.442,1.324h-1.807A3.277,3.277,0,0,1,231.54,365a5.649,5.649,0,0,1,3.052-.826,6.237,6.237,0,0,1,3.253.768,2.567,2.567,0,0,1,1.244,2.372v6.561a.885.885,0,0,0,.121.478c.08.12.241.181.521.181.081,0,.163-.011.282-.011.12-.021.2-.041.321-.061v1.417c-.28.081-.522.14-.643.16a6.01,6.01,0,0,1-.641.029,1.544,1.544,0,0,1-1.446-.7,2.317,2.317,0,0,1-.322-1.058,5,5,0,0,1-1.685,1.327,5.105,5.105,0,0,1-2.411.569,3.688,3.688,0,0,1-2.609-.958,3.278,3.278,0,0,1-.963-2.411,3.146,3.146,0,0,1,.963-2.454,4.773,4.773,0,0,1,2.609-1.067m-1,4.786a2.245,2.245,0,0,0,1.445.479,4.454,4.454,0,0,0,1.969-.469,2.637,2.637,0,0,0,1.565-2.531v-1.549a2.875,2.875,0,0,1-.884.372,8.286,8.286,0,0,1-1.083.22l-1.165.149a4.538,4.538,0,0,0-1.566.43,1.748,1.748,0,0,0-.884,1.594A1.583,1.583,0,0,0,232.183,374.1Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1051" data-name="Pfad 1051" d="M243.788,364.452h1.806v1.964a4.458,4.458,0,0,1,1.126-1.395,2.792,2.792,0,0,1,2.008-.828c.039,0,.081.009.16.009s.242.021.443.05v2.015a1.785,1.785,0,0,0-.323-.041c-.08-.009-.2-.009-.321-.009a2.712,2.712,0,0,0-2.208.928,3.2,3.2,0,0,0-.764,2.133v6.55h-1.927" fill="#161615" />
        <path id="Pfad_1052" data-name="Pfad 1052" d="M251.659,364.452h1.886v1.616a6.427,6.427,0,0,1,1.205-1.226,3.826,3.826,0,0,1,2.17-.649,3.4,3.4,0,0,1,2.168.67,3.259,3.259,0,0,1,.843,1.124,4.1,4.1,0,0,1,3.453-1.794,3.1,3.1,0,0,1,3.093,1.655,5.107,5.107,0,0,1,.481,2.4v7.577h-2.007v-7.906a1.872,1.872,0,0,0-.563-1.555,2.277,2.277,0,0,0-1.406-.43,2.788,2.788,0,0,0-1.926.759,3.37,3.37,0,0,0-.8,2.512v6.62h-1.927V368.4a4.189,4.189,0,0,0-.282-1.686,1.728,1.728,0,0,0-1.646-.795,2.825,2.825,0,0,0-1.968.835,4.075,4.075,0,0,0-.884,3.041v6.032h-1.886" fill="#161615" />
        <path id="Pfad_1053" data-name="Pfad 1053" d="M275.632,368.93a1.043,1.043,0,0,0,.884-.551,1.789,1.789,0,0,0,.12-.738,1.568,1.568,0,0,0-.683-1.4,3.727,3.727,0,0,0-2.008-.449,2.549,2.549,0,0,0-2.128.81,3.138,3.138,0,0,0-.441,1.324h-1.767A3.256,3.256,0,0,1,270.934,365a5.745,5.745,0,0,1,3.052-.826,6.036,6.036,0,0,1,3.252.768,2.564,2.564,0,0,1,1.246,2.372v6.561a.885.885,0,0,0,.119.478.635.635,0,0,0,.522.181c.081,0,.2-.011.283-.011l.36-.061v1.417a6.987,6.987,0,0,1-.682.16,5.568,5.568,0,0,1-.643.029,1.634,1.634,0,0,1-1.446-.7,4.049,4.049,0,0,1-.32-1.058,4.654,4.654,0,0,1-1.688,1.327,5.09,5.09,0,0,1-2.408.569,3.6,3.6,0,0,1-2.572-.958,3.166,3.166,0,0,1-1-2.411,3.044,3.044,0,0,1,1-2.454,4.653,4.653,0,0,1,2.572-1.067m-.964,4.786a2.211,2.211,0,0,0,1.4.479,4.441,4.441,0,0,0,1.968-.469,2.608,2.608,0,0,0,1.607-2.531v-1.549a3.137,3.137,0,0,1-.924.372,8.333,8.333,0,0,1-1.084.22l-1.124.149a4.047,4.047,0,0,0-1.566.43,1.677,1.677,0,0,0-.884,1.594A1.58,1.58,0,0,0,271.617,374.1Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1054" data-name="Pfad 1054" d="M281.215,374.311l6.745-8.155h-6.223v-1.7h8.794v1.554l-6.708,8.107h6.908v1.715h-9.516" fill="#161615" />
        <path id="Pfad_1055" data-name="Pfad 1055" d="M300.729,369.226a4.983,4.983,0,0,0-.562-1.982,2.732,2.732,0,0,0-2.608-1.374,2.894,2.894,0,0,0-2.21.946,3.379,3.379,0,0,0-.923,2.41m5.581-4.455a4.305,4.305,0,0,1,1.768,1.466,6.007,6.007,0,0,1,.763,2,17.194,17.194,0,0,1,.159,2.5h-8.351a4.871,4.871,0,0,0,.842,2.792,2.706,2.706,0,0,0,2.371,1.035,3.085,3.085,0,0,0,2.368-.985,3.4,3.4,0,0,0,.723-1.326h1.888a3.906,3.906,0,0,1-.523,1.393,3.993,3.993,0,0,1-.924,1.257,4.262,4.262,0,0,1-2.167,1.147,6.431,6.431,0,0,1-1.527.17,4.743,4.743,0,0,1-3.615-1.538,5.96,5.96,0,0,1-1.484-4.334,6.67,6.67,0,0,1,1.484-4.448,5.176,5.176,0,0,1,6.225-1.136Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1056" data-name="Pfad 1056" d="M307.155,364.452V372a3.547,3.547,0,0,0,.281,1.426,1.932,1.932,0,0,0,1.888,1.018,2.679,2.679,0,0,0,2.73-1.785,6.633,6.633,0,0,0,.4-2.625v-5.581h1.927v11.376h-1.806v-1.685a3.768,3.768,0,0,1-.924,1.1,4.018,4.018,0,0,1-2.651.887,3.491,3.491,0,0,1-3.333-1.616,5.378,5.378,0,0,1-.442-2.332v-7.727" fill="#161615" />
        <path id="Pfad_1057" data-name="Pfad 1057" d="M318.158,361.271h1.928v3.181h1.849v1.554h-1.849v7.43a.861.861,0,0,0,.4.8,1.815,1.815,0,0,0,.765.119h.278c.122-.01.242-.02.4-.041v1.517a4.893,4.893,0,0,1-.725.131,5.825,5.825,0,0,1-.761.051,2.093,2.093,0,0,1-1.808-.679,3.081,3.081,0,0,1-.483-1.786v-7.539h-1.525v-1.554h1.525" fill="#161615" />
        <path id="Pfad_1058" data-name="Pfad 1058" d="M324.263,360.225h1.927v2.163h-1.927m0,2.113h1.927v11.327h-1.927Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1059" data-name="Pfad 1059" d="M330.446,372.26a2.492,2.492,0,0,0,.484,1.465,3.018,3.018,0,0,0,2.487.916,3.857,3.857,0,0,0,1.887-.449,1.592,1.592,0,0,0,.806-1.434,1.2,1.2,0,0,0-.643-1.117,7.66,7.66,0,0,0-1.65-.537l-1.522-.393a6.976,6.976,0,0,1-2.132-.806,2.377,2.377,0,0,1-1.243-2.142,3.329,3.329,0,0,1,1.165-2.615,4.611,4.611,0,0,1,3.134-1,4.3,4.3,0,0,1,3.693,1.507,3.1,3.1,0,0,1,.684,2.064h-1.771a2.136,2.136,0,0,0-.481-1.176,2.905,2.905,0,0,0-2.289-.759,2.793,2.793,0,0,0-1.645.418,1.279,1.279,0,0,0-.522,1.088,1.361,1.361,0,0,0,.722,1.2,4.312,4.312,0,0,0,1.246.458l1.244.31a9.426,9.426,0,0,1,2.772.968,2.519,2.519,0,0,1,1.122,2.3,3.507,3.507,0,0,1-1.164,2.622,4.85,4.85,0,0,1-3.491,1.107,4.789,4.789,0,0,1-3.615-1.147,4.414,4.414,0,0,1-1.123-2.85" fill="#161615" />
        <path id="Pfad_1060" data-name="Pfad 1060" d="M347.913,365.062a4.433,4.433,0,0,1,1.445,3.208h-1.844a3.433,3.433,0,0,0-.765-1.735,2.559,2.559,0,0,0-1.969-.707,2.694,2.694,0,0,0-2.607,1.784,7.447,7.447,0,0,0-.524,2.862,5.36,5.36,0,0,0,.722,2.871,2.482,2.482,0,0,0,2.289,1.177,2.531,2.531,0,0,0,1.889-.726,4.553,4.553,0,0,0,.965-2h1.844a4.822,4.822,0,0,1-1.6,3.31,4.925,4.925,0,0,1-3.253,1.047,4.334,4.334,0,0,1-3.534-1.624,6.286,6.286,0,0,1-1.323-4.078,6.884,6.884,0,0,1,1.446-4.667,4.687,4.687,0,0,1,3.69-1.665,4.935,4.935,0,0,1,3.133.939" fill="#161615" />
        <path id="Pfad_1061" data-name="Pfad 1061" d="M351.727,360.175h1.89v5.812a5.345,5.345,0,0,1,1.246-1.206,3.962,3.962,0,0,1,2.286-.608,3.349,3.349,0,0,1,3.334,1.734,5.859,5.859,0,0,1,.483,2.622v7.3H359v-7.167a4.3,4.3,0,0,0-.282-1.845,2.07,2.07,0,0,0-1.969-.929,3.241,3.241,0,0,0-2.167.819c-.643.538-.963,1.574-.963,3.09v6.032h-1.89" fill="#161615" />
        <path id="Pfad_1062" data-name="Pfad 1062" d="M371.806,369.226a4.42,4.42,0,0,0-.56-1.982,2.739,2.739,0,0,0-2.611-1.374,2.894,2.894,0,0,0-2.21.946,3.384,3.384,0,0,0-.924,2.41m5.624-4.455a4.454,4.454,0,0,1,1.727,1.466,6.072,6.072,0,0,1,.763,2,17.082,17.082,0,0,1,.158,2.5h-8.349a4.871,4.871,0,0,0,.844,2.792,2.7,2.7,0,0,0,2.367,1.035,3.083,3.083,0,0,0,2.369-.985,2.965,2.965,0,0,0,.723-1.326h1.888a3.306,3.306,0,0,1-.522,1.393,4.04,4.04,0,0,1-.924,1.257,4.322,4.322,0,0,1-2.128,1.147,7.017,7.017,0,0,1-1.568.17,4.785,4.785,0,0,1-3.61-1.538,6.109,6.109,0,0,1-1.486-4.334,6.64,6.64,0,0,1,1.486-4.448,4.9,4.9,0,0,1,3.892-1.714A5.22,5.22,0,0,1,371.125,364.771Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1063" data-name="Pfad 1063" d="M376.1,364.452h1.849v1.616a4.9,4.9,0,0,1,1.687-1.437,4.619,4.619,0,0,1,2.006-.438,3.389,3.389,0,0,1,3.295,1.694,6.48,6.48,0,0,1,.442,2.642v7.3h-1.927v-7.167a3.955,3.955,0,0,0-.324-1.684,1.845,1.845,0,0,0-1.847-1.057,3.8,3.8,0,0,0-1.125.135,2.9,2.9,0,0,0-1.364.929,3.2,3.2,0,0,0-.641,1.177,8.844,8.844,0,0,0-.123,1.7v5.963H376.1" fill="#161615" />
        <rect id="Rechteck_1429" data-name="Rechteck 1429" width="2.132" height="15.603" transform="translate(394.976 360.225)" fill="#161615" />
        <path id="Pfad_1064" data-name="Pfad 1064" d="M400.72,364.452h1.846v1.616a4.888,4.888,0,0,1,1.689-1.437,4.621,4.621,0,0,1,2.007-.438,3.39,3.39,0,0,1,3.293,1.694,6.512,6.512,0,0,1,.443,2.642v7.3h-1.93v-7.167a3.946,3.946,0,0,0-.319-1.684,1.854,1.854,0,0,0-1.849-1.057,3.807,3.807,0,0,0-1.125.135,2.89,2.89,0,0,0-1.364.929,3.171,3.171,0,0,0-.642,1.177,8.729,8.729,0,0,0-.12,1.7v5.963H400.72" fill="#161615" />
        <path id="Pfad_1065" data-name="Pfad 1065" d="M419.113,364.8a5.819,5.819,0,0,1,1.163,1.118v-5.745h1.808v15.653H420.4v-1.586a4.1,4.1,0,0,1-3.7,1.985,4.21,4.21,0,0,1-3.294-1.6,6.3,6.3,0,0,1-1.4-4.257,7.335,7.335,0,0,1,1.28-4.3,4.568,4.568,0,0,1,5.826-1.275m-4.3,8.523a2.687,2.687,0,0,0,2.49,1.235,2.588,2.588,0,0,0,2.167-1.135,5.48,5.48,0,0,0,.842-3.279,4.756,4.756,0,0,0-.881-3.19,2.677,2.677,0,0,0-2.168-1.036,2.854,2.854,0,0,0-2.331,1.106,4.992,4.992,0,0,0-.884,3.248A5.542,5.542,0,0,0,414.817,373.325Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1066" data-name="Pfad 1066" d="M426.622,364.452V372a3.015,3.015,0,0,0,.28,1.426,1.9,1.9,0,0,0,1.888,1.018,2.709,2.709,0,0,0,2.729-1.785,7.4,7.4,0,0,0,.4-2.625v-5.581h1.886v11.376H432l.038-1.685a3.805,3.805,0,0,1-.921,1.1,4.018,4.018,0,0,1-2.651.887,3.46,3.46,0,0,1-3.336-1.616,4.835,4.835,0,0,1-.48-2.332v-7.727" fill="#161615" />
        <path id="Pfad_1067" data-name="Pfad 1067" d="M437.944,372.26a2.493,2.493,0,0,0,.485,1.465,2.968,2.968,0,0,0,2.486.916,3.885,3.885,0,0,0,1.892-.449,1.592,1.592,0,0,0,.8-1.434,1.267,1.267,0,0,0-.642-1.117,7.628,7.628,0,0,0-1.645-.537l-1.527-.393a6.945,6.945,0,0,1-2.128-.806,2.372,2.372,0,0,1-1.244-2.142,3.33,3.33,0,0,1,1.162-2.615,4.619,4.619,0,0,1,3.133-1,4.349,4.349,0,0,1,3.7,1.507,3.064,3.064,0,0,1,.683,2.064h-1.806a2.132,2.132,0,0,0-.443-1.176,2.907,2.907,0,0,0-2.288-.759,2.706,2.706,0,0,0-1.645.418,1.324,1.324,0,0,0,.2,2.284,4.259,4.259,0,0,0,1.241.458l1.246.31a10.441,10.441,0,0,1,2.771.968,2.512,2.512,0,0,1,1.127,2.3,3.525,3.525,0,0,1-1.164,2.622,4.867,4.867,0,0,1-3.5,1.107,4.8,4.8,0,0,1-3.616-1.147,4.441,4.441,0,0,1-1.124-2.85" fill="#161615" />
        <path id="Pfad_1068" data-name="Pfad 1068" d="M448.063,361.271h1.93v3.181H451.8v1.554h-1.807v7.43a.788.788,0,0,0,.4.8,1.72,1.72,0,0,0,.723.119h.321c.122-.01.241-.02.362-.041v1.517a3.63,3.63,0,0,1-.683.131,6.372,6.372,0,0,1-.764.051,2.1,2.1,0,0,1-1.807-.679,3.1,3.1,0,0,1-.483-1.786v-7.539H446.5v-1.554h1.564" fill="#161615" />
        <path id="Pfad_1069" data-name="Pfad 1069" d="M454.208,364.452h1.809v1.964a4.773,4.773,0,0,1,1.084-1.395,2.859,2.859,0,0,1,2-.828c.042,0,.122.009.2.009s.241.021.442.05v2.015a2.376,2.376,0,0,0-.322-.041c-.12-.009-.2-.009-.325-.009a2.719,2.719,0,0,0-2.207.928,3.248,3.248,0,0,0-.8,2.133v6.55h-1.89" fill="#161615" />
        <path id="Pfad_1070" data-name="Pfad 1070" d="M461.6,360.225h1.966v2.163H461.6m0,2.113h1.966v11.327H461.6Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1071" data-name="Pfad 1071" d="M474.327,369.226a5,5,0,0,0-.522-1.982,2.8,2.8,0,0,0-2.647-1.374,2.946,2.946,0,0,0-2.21.946,3.759,3.759,0,0,0-.925,2.41m5.621-4.455a4.174,4.174,0,0,1,1.728,1.466,5.178,5.178,0,0,1,.762,2,12.812,12.812,0,0,1,.162,2.5h-8.315a4.614,4.614,0,0,0,.8,2.792,2.759,2.759,0,0,0,2.373,1.035,3.087,3.087,0,0,0,3.092-2.311h1.884a3.658,3.658,0,0,1-.482,1.393,4.793,4.793,0,0,1-.964,1.257,4.309,4.309,0,0,1-2.127,1.147,7.039,7.039,0,0,1-1.566.17,4.858,4.858,0,0,1-3.614-1.538,6.137,6.137,0,0,1-1.488-4.334,6.672,6.672,0,0,1,1.488-4.448,4.916,4.916,0,0,1,3.894-1.714A5.207,5.207,0,0,1,473.644,364.771Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1072" data-name="Pfad 1072" d="M492.6,369.226a5,5,0,0,0-.563-1.982,2.773,2.773,0,0,0-2.609-1.374,2.89,2.89,0,0,0-2.21.946,3.58,3.58,0,0,0-.962,2.41m5.622-4.455a4.2,4.2,0,0,1,1.727,1.466,4.68,4.68,0,0,1,.762,2,12.713,12.713,0,0,1,.163,2.5h-8.315a5.22,5.22,0,0,0,.8,2.792,2.792,2.792,0,0,0,2.366,1.035,3.11,3.11,0,0,0,2.41-.985,3.977,3.977,0,0,0,.724-1.326h1.888a4.767,4.767,0,0,1-.522,1.393,5.816,5.816,0,0,1-.924,1.257,4.608,4.608,0,0,1-2.17,1.147,6.478,6.478,0,0,1-1.562.17,4.708,4.708,0,0,1-3.576-1.538,5.948,5.948,0,0,1-1.487-4.334,6.456,6.456,0,0,1,1.487-4.448,4.915,4.915,0,0,1,3.895-1.714A5.063,5.063,0,0,1,491.877,364.771Z" fill="#161615" fillRule="evenodd" />
        <rect id="Rechteck_1430" data-name="Rechteck 1430" width="2.21" height="2.314" transform="translate(496.895 373.514)" fill="#161615" />
        <path id="Pfad_1073" data-name="Pfad 1073" d="M503.32,360.225l4.5,13.28,4.422-13.28H514.6l-5.7,15.6h-2.21l-5.7-15.6" fill="#161615" />
        <rect id="Rechteck_1431" data-name="Rechteck 1431" width="2.211" height="2.314" transform="translate(514.402 373.514)" fill="#161615" />
        <path id="Pfad_1074" data-name="Pfad 1074" d="M203.953,339.926h6.706a4.459,4.459,0,0,1,3.9,1.636,3.866,3.866,0,0,1,.683,2.233,3.527,3.527,0,0,1-.844,2.422,4.312,4.312,0,0,1-1.245.907,5.749,5.749,0,0,1,1.768,1.028,3.679,3.679,0,0,1,1.043,2.821,4.22,4.22,0,0,1-.923,2.721,5.346,5.346,0,0,1-4.5,1.835h-6.586m6.626-1.8a3.249,3.249,0,0,0,2.771-1.125,3.034,3.034,0,0,0,.522-1.726,2.306,2.306,0,0,0-1.526-2.313,5.651,5.651,0,0,0-2.128-.329H206v5.493Zm-.724-7.207a4.746,4.746,0,0,0,2.089-.37,2.16,2.16,0,0,0,1.165-2.1,2,2,0,0,0-1.246-2.052,5.614,5.614,0,0,0-2.088-.3H206v4.827Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1075" data-name="Pfad 1075" d="M220.176,344.154V351.7a3.608,3.608,0,0,0,.241,1.424,1.993,1.993,0,0,0,1.927,1.018,2.7,2.7,0,0,0,2.731-1.786,7.585,7.585,0,0,0,.361-2.62v-5.584h1.929v11.375h-1.807v-1.685a3.235,3.235,0,0,1-.924,1.1,4.028,4.028,0,0,1-2.651.9,3.394,3.394,0,0,1-3.293-1.622,4.845,4.845,0,0,1-.482-2.335v-7.726" fill="#161615" />
        <path id="Pfad_1076" data-name="Pfad 1076" d="M230.134,344.154h1.808v1.614a4.949,4.949,0,0,1,1.726-1.435,4.49,4.49,0,0,1,2.008-.439,3.289,3.289,0,0,1,3.253,1.695,5.811,5.811,0,0,1,.481,2.642v7.3h-1.967V348.36a4.027,4.027,0,0,0-.281-1.684,1.887,1.887,0,0,0-1.846-1.058,3.649,3.649,0,0,0-1.126.142,2.944,2.944,0,0,0-1.4.926,3.063,3.063,0,0,0-.6,1.175,8.485,8.485,0,0,0-.161,1.706v5.962h-1.888" fill="#161615" />
        <path id="Pfad_1077" data-name="Pfad 1077" d="M248.607,344.5a5.789,5.789,0,0,1,1.164,1.115v-5.742h1.848v15.653h-1.727v-1.585a4.17,4.17,0,0,1-1.607,1.514,4.45,4.45,0,0,1-2.088.471,4.258,4.258,0,0,1-3.292-1.6,6.264,6.264,0,0,1-1.366-4.257,7.3,7.3,0,0,1,1.245-4.3,4.159,4.159,0,0,1,3.654-1.824,4.052,4.052,0,0,1,2.169.549m-4.3,8.523a2.735,2.735,0,0,0,2.49,1.237,2.588,2.588,0,0,0,2.168-1.137,5.194,5.194,0,0,0,.884-3.281,5,5,0,0,0-.884-3.191,2.792,2.792,0,0,0-2.168-1.036,2.932,2.932,0,0,0-2.369,1.107,5.032,5.032,0,0,0-.884,3.25A5.558,5.558,0,0,0,244.31,353.026Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1078" data-name="Pfad 1078" d="M261.979,348.931a5.037,5.037,0,0,0-.523-1.985,2.775,2.775,0,0,0-2.61-1.377,2.868,2.868,0,0,0-2.208.948,3.6,3.6,0,0,0-.964,2.414m5.623-4.459a4.329,4.329,0,0,1,1.726,1.467,4.639,4.639,0,0,1,.763,2,12.767,12.767,0,0,1,.161,2.51h-8.312a4.85,4.85,0,0,0,.8,2.784,2.757,2.757,0,0,0,2.368,1.036,2.97,2.97,0,0,0,2.37-.985,2.876,2.876,0,0,0,.722-1.328h1.888a3.638,3.638,0,0,1-.483,1.394,5.686,5.686,0,0,1-.923,1.257,4.629,4.629,0,0,1-2.167,1.147,6.987,6.987,0,0,1-1.567.171,4.871,4.871,0,0,1-3.613-1.535,6.131,6.131,0,0,1-1.447-4.337,6.461,6.461,0,0,1,1.486-4.447,4.863,4.863,0,0,1,3.855-1.716A5.2,5.2,0,0,1,261.3,344.472Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1079" data-name="Pfad 1079" d="M267.12,351.96a2.472,2.472,0,0,0,.481,1.463,2.979,2.979,0,0,0,2.489.921,3.894,3.894,0,0,0,1.888-.449,1.594,1.594,0,0,0,.8-1.435,1.285,1.285,0,0,0-.643-1.118,7.635,7.635,0,0,0-1.647-.54l-1.526-.386a7.036,7.036,0,0,1-2.128-.81,2.362,2.362,0,0,1-1.244-2.142,3.318,3.318,0,0,1,1.164-2.612,4.579,4.579,0,0,1,3.131-1,4.324,4.324,0,0,1,3.7,1.507,3.084,3.084,0,0,1,.683,2.064H272.46a1.863,1.863,0,0,0-.442-1.178,2.91,2.91,0,0,0-2.29-.758,2.791,2.791,0,0,0-1.646.419A1.313,1.313,0,0,0,267.52,347a1.4,1.4,0,0,0,.763,1.2,4.526,4.526,0,0,0,1.246.468l1.245.3a9.9,9.9,0,0,1,2.769.966,2.525,2.525,0,0,1,1.126,2.305,3.566,3.566,0,0,1-1.164,2.632,4.919,4.919,0,0,1-3.5,1.1,4.8,4.8,0,0,1-3.613-1.145,4.419,4.419,0,0,1-1.124-2.852" fill="#161615" />
        <path id="Pfad_1080" data-name="Pfad 1080" d="M277.118,344.154l3.052,9.262,3.173-9.262h2.087l-4.3,11.374h-2.008l-4.216-11.374" fill="#161615" />
        <path id="Pfad_1081" data-name="Pfad 1081" d="M294.426,348.931a5.02,5.02,0,0,0-.563-1.985,2.736,2.736,0,0,0-2.611-1.377,2.928,2.928,0,0,0-2.207.948,3.376,3.376,0,0,0-.923,2.414m5.582-4.459a4.493,4.493,0,0,1,1.767,1.467,5.955,5.955,0,0,1,.761,2,16.849,16.849,0,0,1,.161,2.51H288.04a4.881,4.881,0,0,0,.843,2.784,2.714,2.714,0,0,0,2.369,1.036,3.087,3.087,0,0,0,2.371-.985,2.887,2.887,0,0,0,.723-1.328h1.886a3.777,3.777,0,0,1-.522,1.394,3.945,3.945,0,0,1-.923,1.257,4.28,4.28,0,0,1-2.168,1.147,6.371,6.371,0,0,1-1.525.171,4.753,4.753,0,0,1-3.616-1.535,5.975,5.975,0,0,1-1.486-4.337,6.662,6.662,0,0,1,1.486-4.447,4.946,4.946,0,0,1,3.9-1.716A5.044,5.044,0,0,1,293.7,344.472Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1082" data-name="Pfad 1082" d="M298.8,344.154h1.806v1.964a4.786,4.786,0,0,1,1.084-1.4,2.876,2.876,0,0,1,2.008-.827c.041,0,.081.01.2.01.079.011.2.031.4.05v2.013a2.546,2.546,0,0,0-.323-.039,2.067,2.067,0,0,0-.281-.011,2.792,2.792,0,0,0-2.248.929,3.428,3.428,0,0,0-.763,2.143v6.54H298.8" fill="#161615" />
        <path id="Pfad_1083" data-name="Pfad 1083" d="M312.9,352.967a5.976,5.976,0,0,0,.763-3.25,5.247,5.247,0,0,0-.763-2.971,2.543,2.543,0,0,0-2.249-1.177,3.3,3.3,0,0,0-2.328.957,4.419,4.419,0,0,0-.965,3.191,6.8,6.8,0,0,0,.4,2.6,2.8,2.8,0,0,0,2.809,1.884,2.574,2.574,0,0,0,2.33-1.236m-7.267-13.091h1.846v5.653a4.2,4.2,0,0,1,1.486-1.237,4.249,4.249,0,0,1,1.888-.438,4.462,4.462,0,0,1,3.453,1.464,6.192,6.192,0,0,1,1.326,4.3A7.3,7.3,0,0,1,314.3,354.1a4.167,4.167,0,0,1-3.616,1.786,3.7,3.7,0,0,1-2.168-.629,5.435,5.435,0,0,1-1.164-1.185v1.455H305.63Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1084" data-name="Pfad 1084" d="M323.5,348.631a1,1,0,0,0,.885-.549,1.764,1.764,0,0,0,.12-.729,1.489,1.489,0,0,0-.683-1.414,3.587,3.587,0,0,0-1.968-.45,2.51,2.51,0,0,0-2.127.808,2.67,2.67,0,0,0-.483,1.327h-1.766a3.27,3.27,0,0,1,1.366-2.922,5.634,5.634,0,0,1,3.052-.828,6.218,6.218,0,0,1,3.253.768,2.652,2.652,0,0,1,1.244,2.382v6.55a.854.854,0,0,0,.12.477c.081.122.239.182.52.182.083,0,.161-.009.283-.009.08-.021.2-.041.321-.059v1.414a5.15,5.15,0,0,1-.682.159,5.268,5.268,0,0,1-.643.031,1.524,1.524,0,0,1-1.406-.7,2.3,2.3,0,0,1-.32-1.057,5.108,5.108,0,0,1-1.687,1.336,5.235,5.235,0,0,1-2.41.557,3.628,3.628,0,0,1-2.609-.958,3.292,3.292,0,0,1-1.005-2.413,3.182,3.182,0,0,1,1.005-2.452,4.65,4.65,0,0,1,2.571-1.067m-.966,4.786a2.258,2.258,0,0,0,1.447.479,4.176,4.176,0,0,0,1.927-.468,2.6,2.6,0,0,0,1.607-2.534v-1.543a2.955,2.955,0,0,1-.885.367,8.3,8.3,0,0,1-1.084.219l-1.165.151a4.588,4.588,0,0,0-1.566.428,1.685,1.685,0,0,0-.884,1.595A1.585,1.585,0,0,0,319.483,353.8Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1085" data-name="Pfad 1085" d="M329.522,344.154h1.808v1.614a4.947,4.947,0,0,1,1.725-1.435,4.494,4.494,0,0,1,2.009-.439,3.287,3.287,0,0,1,3.252,1.695,5.769,5.769,0,0,1,.482,2.642v7.3H336.83V348.36a4.044,4.044,0,0,0-.28-1.684,1.892,1.892,0,0,0-1.847-1.058,3.642,3.642,0,0,0-1.125.142,2.927,2.927,0,0,0-1.4.926,2.547,2.547,0,0,0-.6,1.175,8.558,8.558,0,0,0-.161,1.706v5.962h-1.888" fill="#161615" />
        <path id="Pfad_1086" data-name="Pfad 1086" d="M347.875,344.5a4.935,4.935,0,0,1,1.161,1.115v-5.742h1.849v15.653h-1.728v-1.585a4.05,4.05,0,0,1-1.565,1.514,4.731,4.731,0,0,1-2.128.471,4.173,4.173,0,0,1-3.249-1.6,6.069,6.069,0,0,1-1.408-4.257,7.294,7.294,0,0,1,1.244-4.3,4.252,4.252,0,0,1,3.654-1.824,3.968,3.968,0,0,1,2.17.549m-4.3,8.523a2.915,2.915,0,0,0,4.7.1,5.463,5.463,0,0,0,.846-3.281,4.759,4.759,0,0,0-.887-3.191,2.788,2.788,0,0,0-2.167-1.036,2.931,2.931,0,0,0-2.368,1.107,5.276,5.276,0,0,0-.884,3.25A5.924,5.924,0,0,0,343.579,353.026Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1087" data-name="Pfad 1087" d="M367.311,344.5a4.9,4.9,0,0,1,1.162,1.115v-5.742h1.848v15.653H368.6v-1.585a4.1,4.1,0,0,1-1.568,1.514,4.572,4.572,0,0,1-2.09.471,4.2,4.2,0,0,1-3.289-1.6,6.277,6.277,0,0,1-1.406-4.257,7.326,7.326,0,0,1,1.285-4.3,4.136,4.136,0,0,1,3.616-1.824,3.87,3.87,0,0,1,2.167.549m-4.259,8.523a2.626,2.626,0,0,0,2.449,1.237,2.656,2.656,0,0,0,2.209-1.137,5.477,5.477,0,0,0,.845-3.281,4.787,4.787,0,0,0-.883-3.191,2.737,2.737,0,0,0-2.171-1.036,2.938,2.938,0,0,0-2.368,1.107,5.289,5.289,0,0,0-.882,3.25A5.574,5.574,0,0,0,363.052,353.026Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1088" data-name="Pfad 1088" d="M380.722,348.931a5,5,0,0,0-.564-1.985,2.771,2.771,0,0,0-2.607-1.377,2.926,2.926,0,0,0-2.209.948,3.587,3.587,0,0,0-.964,2.414M380,344.472a4.078,4.078,0,0,1,1.728,1.467,4.688,4.688,0,0,1,.763,2,11.974,11.974,0,0,1,.2,2.51h-8.351a5.2,5.2,0,0,0,.8,2.784,2.807,2.807,0,0,0,2.369,1.036,3.112,3.112,0,0,0,2.407-.985,3.88,3.88,0,0,0,.724-1.328h1.89a4.78,4.78,0,0,1-.524,1.394,4.673,4.673,0,0,1-.922,1.257,4.3,4.3,0,0,1-2.169,1.147,6.473,6.473,0,0,1-1.567.171,4.734,4.734,0,0,1-3.576-1.535,5.978,5.978,0,0,1-1.482-4.337,6.464,6.464,0,0,1,1.482-4.447,4.949,4.949,0,0,1,3.9-1.716A5.145,5.145,0,0,1,380,344.472Z" fill="#161615" fillRule="evenodd" />
        <path id="Pfad_1089" data-name="Pfad 1089" d="M385.06,344.154h1.847v1.964a3.654,3.654,0,0,1,1.083-1.4,2.808,2.808,0,0,1,2.008-.827c.039,0,.081.01.16.01s.24.031.442.05v2.013c-.121-.02-.2-.028-.321-.039a2.388,2.388,0,0,0-.281-.011,2.788,2.788,0,0,0-2.248.929,3.2,3.2,0,0,0-.762,2.143v6.54H385.06" fill="#161615" />
      </g>
      <path id="Pfad_1090" data-name="Pfad 1090" d="M128.485,339.926h15.442c4.21,0,7.177,1.255,8.983,3.764a8.71,8.71,0,0,1,1.564,5.136,8.111,8.111,0,0,1-1.924,5.578,9.8,9.8,0,0,1-2.889,2.1,12.452,12.452,0,0,1,4.132,2.369,8.666,8.666,0,0,1,2.406,6.483,10.028,10.028,0,0,1-2.166,6.284q-3.249,4.211-10.348,4.212h-15.2m15.281-4.163c2.967,0,5.093-.857,6.376-2.589a6.511,6.511,0,0,0,1.205-3.963,5.269,5.269,0,0,0-3.491-5.328,13.293,13.293,0,0,0-4.932-.756h-9.666V371.69Zm-1.644-16.579a10.669,10.669,0,0,0,4.772-.858c1.805-.895,2.687-2.507,2.687-4.836,0-2.352-.922-3.935-2.848-4.742a12.861,12.861,0,0,0-4.772-.686h-8.7v11.122Z" fill="#161615" fillRule="evenodd" />
      <path id="Pfad_1091" data-name="Pfad 1091" d="M179.323,344.865a10.621,10.621,0,0,0-4.413-.757h-9.7v12.448h9.7a9.247,9.247,0,0,0,5.295-1.4c1.364-.925,2.045-2.568,2.045-4.907,0-2.649-.962-4.443-2.927-5.388m-18.97-4.939h16.162a11.006,11.006,0,0,1,7.74,2.719,9.869,9.869,0,0,1,2.928,7.578,11.019,11.019,0,0,1-2.647,7.339c-1.726,2.069-4.413,3.1-8.021,3.1H165.206v15.187h-4.853Z" fill="#161615" fillRule="evenodd" />
      <rect id="Rechteck_1432" data-name="Rechteck 1432" width="4.931" height="35.926" transform="translate(189.825 339.926)" fill="#161615" />
      <path id="Pfad_1092" data-name="Pfad 1092" d="M113.338,294.433h5.681V385.32h75.738V391H113.338" fill="#c80023" />
    </g>
  </svg>
);