import { useHistory } from "react-router-dom";

import classNames from "classnames";
import styles from "./BlogsFilters.module.scss";

/**
 * @desc function for create blog filters
 * @returns {JSX.Element}
 */
export const BlogsFilters = ({ filter }) => {
  const history = useHistory();

  const handleFilter = path => history.push(path);

  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filters}>
        <button
          className={classNames(
            styles.filtersButton,
            styles.filtersButtonYellow,
            { [`${styles.filtersOpacity}`]: filter && filter === "FOR_YOU" }
          )}
          onClick={() => handleFilter("/home/fuer_sie")}
        >
          für sie
      </button>
        <button
          className={classNames(
            styles.filtersButton,
            styles.filtersButtonPurple,
            { [`${styles.filtersOpacity}`]: filter && filter === "INNOVATIVE" }
          )}
          onClick={() => handleFilter("/home/innovativ")}
        >
          innovativ
      </button>
        <button
          className={classNames(
            styles.filtersButton,
            styles.filtersButtonBlue,
            { [`${styles.filtersOpacity}`]: filter && filter === "DIGITAL" }
          )}
          onClick={() => handleFilter("/home/digital")}
        >
          digital
      </button>
        <div className={styles.filtersResetContainer}>
          <div
            className={classNames(
              styles.filtersIconForYou,
              { [`${styles.filtersOpacity}`]: filter && filter === "FOR_YOU" }
            )}
          ></div>
          <div
            className={classNames(
              styles.filtersIconInnovativ,
              { [`${styles.filtersOpacity}`]: filter && filter === "INNOVATIVE" }
            )}
          ></div>
          <div
            className={classNames(
              styles.filtersIconDigital,
              { [`${styles.filtersOpacity}`]: filter && filter === "DIGITAL" }
            )}
          ></div>
          <button
            className={styles.filtersResetButton}
            onClick={() => handleFilter("/home")}
          ></button>
        </div>
      </div>
      <div className={styles.filtersTextContainer}>
        <p className={styles.filtersText}>
          Wählen Sie aus unseren drei Themenschwerpunkten.
        </p>
        <p className={styles.filtersText}>
          Es erwarten Sie Hintergrundberichte, Interviews, Videos und vieles mehr.
        </p>
      </div>
    </div>
  );
}
