import { GoToTop } from "../../components/сommon/GoToTop";
import { Header } from "../../components/сommon/Header";
import { Footer } from "../../components/сommon/Footer";
import { PersonalMenu } from "../../components/сommon/PersonalMenu";
import { BlogForm } from "../../components/forms/BlogForm";
import { ErrorModal } from "../../components/modals/ErrorModal";

/**
 * @desc Form for create blog UI
 * @return {JSX.Element}
 */
export const BlogFormPage = () => (
  <>
    <GoToTop />
    <Header container="container_admin" />
    <PersonalMenu />
    <BlogForm />
    <Footer container="container_admin" />
    <ErrorModal />
  </>
);
