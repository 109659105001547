import { useState, useEffect } from "react";
import Slider from "react-slick";
import classNames from "classnames";
import axios from "axios";
import { useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";

import styles from "./HomePageSlider.module.scss";
import { SliderButtonIcon } from "../сommon/icons/SliderButtonIcon";

/**
 * @desc function for create slider at home page
 * @returns {JSX.Element}
 */
export const HomePageSlider = () => {
  const history = useHistory();
  const [centralNews, setCentralNews] = useState();

  useEffect(() => {
    axios
      .get("/media/news/central")
      .then(res => {
        if (res.status === 200) {
          setCentralNews(res.data);
        }
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  const SampleNextArrow = props => {
    const { className, style, onClick } = props;

    return (
      <SliderButtonIcon
        className={className}
        style={{
          ...style,
          position: "absolute",
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          width: "66px",
          height: "38px",
          zIndex: "1",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = props => {
    const { className, style, onClick } = props;

    return (
      <SliderButtonIcon
        className={className}
        style={{
          ...style,
          position: "absolute",
          top: "50%",
          left: 0,
          transform: "rotate(180deg) translateY(50%)",
          width: "66px",
          height: "38px",
          zIndex: "1",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleRedirect = (url) => {
    history.push(`/blog/${url}`);
  }

  return (
    <div className={styles.slider}>
      <div className={styles.sliderHeader}>
        <Typography variant="h1" color="textPrimary" className={styles.sliderTitle}>
          Unsere Themen.
        </Typography>
        <Typography color="textPrimary" className={styles.sliderText}>
          Die deutsche Pharmaindustrie steht für Innovationskraft, Zukunftsgewandtheit und Verantwortung – für Sie und für die gesamte Gesellschaft. Wir sorgen dafür, dass jederzeit eine exzellente Auswahl an Medikamenten verfügbar ist, und wir arbeiten ständig an deren Verbesserung. Um das zu erreichen, wirken wir in vielfältigen Bereichen. Informieren Sie sich über unser Engagement!
        </Typography>
      </div>
      <Slider {...settings}>
        {centralNews?.map((blog, index) => (
          <div
            key={index}
            className={classNames(
              styles.sliderWrapper,
              { [`${styles.sliderIconSie}`]: blog.type === "FOR_YOU" },
              { [`${styles.sliderIconDigital}`]: blog.type === "DIGITAL" },
              { [`${styles.sliderIconInnovativ}`]: blog.type === "INNOVATIVE" },
              { [`${styles.sliderIconCentral}`]: blog.type === "CENTRAL" }
            )}
            onClick={() => handleRedirect(blog.prettyUrl)}
          >
            <img src={blog.croppedImage} alt="" className={styles.sliderImage} />
            <div className={styles[`overlay-${blog.type}`]}>
              <Typography className={styles.overlayText}>
                {blog.title}
              </Typography>
              <Typography className={styles.overlayText}>
                {blog.description}
              </Typography>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
