import { ACTION } from "../constants/types";

const INIT_STATE = {
  token: localStorage.getItem("bpiAuthToken"),
  role: localStorage.getItem("bpiUserRole"),
  loading: false,
  error: null,
  cookie: false,
  email: localStorage.getItem("bpiUserEmail"),
};

export const loginReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION.SIGNIN_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION.SIGNIN_USER_SUCCESS:
      return { ...state, token: action.payload.token, role: action.payload.role, email: action.payload.email, loading: false };
    case ACTION.SIGNIN_USER_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case ACTION.LOGOUT_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION.LOGOUT_USER_SUCCESS:
      return { ...state, token: null, role: null, email: null, loading: false, error: null };
    case ACTION.LOGOUT_USER_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case ACTION.SET_COOKIE:
      return { ...state, cookie: action.payload };

    default:
      return state;
  }
};
