import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { Header } from "../../components/сommon/Header";
import { PasswordForm } from "../../components/forms/PasswordForm";
import { Preloader } from "../../components/сommon/Preloader";

/**
 * @desc Auth password UI
 * @return {JSX.Element}
 */
export const PasswordPage = () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const [validationLoading, setValidLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`uaa/user/password/change/${token}`)
      .then(res => res.data ? setValidLoading(false) : history.push("/"))
      .catch(() => history.push("/"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return validationLoading
    ? (<Preloader className="preloadingSection" />)
    : (
      <>
        <Header />
        <PasswordForm token={token} />
      </>
    );
};