import Typography from "@material-ui/core/Typography";

import useStyles from "./useStyles";
import { PrivatContentLogo } from "../сommon/icons/PrivatContentLogo";

/**
 * @desc function for create banner at private content page
 * @returns {JSX.Element}
 */
export const PrivateContentBanner = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.banner_section} container`}>
      <div className={classes.banner_content}>
        <Typography variant="h1" className={classes.banner_header}>Download Portal.</Typography>
        <Typography className={classes.banner_text}>
          Willkommen im Downloadbereich von „Wir wirken“. Hier finden Sie alle aktuellen Werbemittel der Kampagne: Motive, Grafiken und vieles mehr, das Sie zur Einbindung in Ihrer Kommunikation nutzen können. Gemeinsam wirkt unsere Kampagne noch stärker!
        </Typography>
      </div>
      <PrivatContentLogo className={classes.banner_img} />
    </div>
  );
}
