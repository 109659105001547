import React, { useEffect } from "react";
import { Switch, Redirect, Route, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AxiosConfig } from "../AxiosConfig";
import i18n from "i18n-js";

import { MainPage } from "../pages/MainPage"
import { SignInPage } from "../pages/SignInPage";
import { SignUpPage } from "../pages/SignUpPage";
import { RecoveryPasswordPage } from "../pages/RecoveryPasswordPage";
import { ImpressumPage } from "../pages/ImpressumPage";
import { PrivacyPage } from "../pages/PrivacyPage";
import { PasswordPage } from "../pages/PasswordPage";
import { PrivateContentPage } from "../pages/PrivateContentPage";
import { UsersPage } from "../pages/UsersPage";
import { BlogsPage } from "../pages/BlogsPage";
import { BlogFormPage } from "../pages/BlogFormPage";
import { BlogPage } from "../pages/BlogPage";
import { BlogPreviewPage } from "../pages/BlogPreviewPage";
import { PrivateFilesPage } from "../pages/PrivateFilesPage";
import { PrivateFilesFormPage } from "../pages/PrivateFilesFormPage";
import { MergeImagesPage } from "../pages/MergeImagesPage";

const PrivateRoute = ({ component: Component, location, token, role, ...rest }) => (
  <Route {...rest} render={props => (token && role ? <Component {...props} /> : <Redirect to={{ pathname: "/signin" }} />)} />
);
const AdminRoute = ({ component: Component, location, token, role, ...rest }) => (
  <Route {...rest} render={props => (token && role === "ROLE_ADMIN" ? <Component {...props} /> : <Redirect to={{ pathname: "/signin" }} />)} />
);
const AdminPreviewRoute = ({ component: Component, location, token, role, blogId, ...rest }) => (
  <Route {...rest} render={props => (blogId && token && role === "ROLE_ADMIN" ? <Component {...props} /> : <Redirect to={{ pathname: "/signin" }} />)} />
);
const PublicOnlyRoute = ({ component: Component, location, token, ...rest }) => (
  <Route {...rest} render={props => (token ? <Redirect to={{ pathname: "/" }} /> : <Component {...props} />)} />
);
const PremiumUserRoute = ({ component: Component, location, token, role, imageForMergeId, contentForMergeId, ...rest }) => (
    <Route {...rest} render={props => token && imageForMergeId && contentForMergeId && ["ROLE_ADMIN", "ROLE_PREMIUM_USER"].includes(role) ? <Component {...props} /> : <Redirect to={{ pathname: "/signin" }} />} />
);

export const Routes = () => {
  const { token, role } = useSelector(({ login }) => login);
  const { preview } = useSelector(({ adminBlogs }) => adminBlogs);
  const { imageForMergeId, contentForMergeId } = useSelector(({ privateFiles }) => privateFiles);
  const location = useLocation();
  const history = useHistory();

  const DEFAULT_LANG = "de";

  useEffect(() => {
    const lang = localStorage.getItem("language");
    i18n.locale = lang || DEFAULT_LANG;
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      if (token) {
        history.push("/content");
      } else {
        history.push("/home");
      }
    }
  }, [token, history, location]);

  AxiosConfig();

  return (
    <Switch>
      <Route path="/home" component={MainPage} />
      <Route path="/blog/:id" component={BlogPage} />
      <Route path="/impressum" component={ImpressumPage} />
      <Route path="/privacy" component={PrivacyPage} />
      <PublicOnlyRoute path="/signin" token={token} component={SignInPage} />
      <PublicOnlyRoute path="/signup" token={token} component={SignUpPage} />
      <PublicOnlyRoute path="/password" token={token} component={PasswordPage} />
      <PublicOnlyRoute path="/recovery_password" token={token} component={RecoveryPasswordPage} />
      <PrivateRoute path="/content" token={token} role={role} component={PrivateContentPage} />
      <AdminRoute path="/users" token={token} role={role} component={UsersPage} />
      <AdminRoute path="/blogs" token={token} role={role} component={BlogsPage} />
      <AdminRoute path="/blog_form" token={token} role={role} component={BlogFormPage} />
      <AdminPreviewRoute path="/blog_preview" blogId={preview.id} token={token} role={role} component={BlogPreviewPage} />
      <AdminRoute path="/files" token={token} role={role} component={PrivateFilesPage} />
      <AdminRoute path="/file_form" token={token} role={role} component={PrivateFilesFormPage} />
      <PremiumUserRoute path="/logos" token={token} role={role} imageForMergeId={imageForMergeId} contentForMergeId={contentForMergeId} component={MergeImagesPage} />
      <Redirect to="/" />
   </Switch>
  );
};
