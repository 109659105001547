import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MaterialTable from "material-table";
import { Typography, TablePagination } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import { TypeHandler } from "../../сommon/TypeHandler";
import { PrivateFileModal } from "../../modals/PrivateFileModal";
import { ContentTypeFilter } from "../../tablesFilters/ContentTypeFilter";

import localization from "../../../assets/locale/materialTableLocale";
import getBytesFromFileSize from "../../../utils/getBytesFromFileSize";

import {
  cleanPrivateFileForm,
  openPrivateFileModal,
  fillPrivateFileForm,
  setAdminFilesQuery,
  enablePrivateFileRequest,
} from "../../../store/actions";

import useStyles from "../useStyles";

/**
 * @desc function for create files table
 * @returns {JSX.Element}
 */
export const FilesTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { files, pagination, query, loading } = useSelector(({ adminFiles }) => adminFiles);

  useEffect(() => {
    dispatch(setAdminFilesQuery(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterOptions = [
    { id: 0, title: "Alles", value: "" },
    { id: 2, title: "Für Sie", value: "FOR_YOU" },
    { id: 3, title: "Digital", value: "DIGITAL" },
    { id: 4, title: "Innovativ", value: "INNOVATIVE" },
  ];

  const columns = [
    {
      title: "ID",
      field: "id",
      width: 100,
      render: rowData => (
        <Typography noWrap className={classes.tablefield_id}>
          {rowData.id}
        </Typography>
      ),
      sorting: false,
    },
    {
      title: "Title",
      field: "title",
      width: 400,
      render: rowData => (
        <Typography noWrap className={classes.tablefield_title}>
          {rowData.title}
        </Typography>
      ),
      sorting: false,
    },
    {
      title: "Dateigröße",
      field: "contentLength",
      width: 200,
      render: rowData => (
        <Typography noWrap className={classes.tablefield_size}>
          {getBytesFromFileSize(rowData.contentLength)}
        </Typography>
      ),
      sorting: false,
    },
    {
      title: "Downloads",
      field: "downloadCount",
      width: 200,
      render: rowData => (
        <Typography noWrap className={classes.tablefield_download}>
          {rowData.downloadCount}
        </Typography>
      ),
      sorting: false,
    },
    {
      title: "Einzigartiger Downloads",
      field: "uniqueDownloadCount",
      width: 200,
      render: rowData => (
          <Typography noWrap className={classes.tablefield_download}>
            {rowData.uniqueDownloadCount}
          </Typography>
      ),
      sorting: false,
    },
    {
      title: "Aktivierung",
      field: "enabled",
      align: "center",
      width: 300,
      render: rowData => (
        <Button
          variant="contained"
          className={classes.table_btn}
          onClick={() => dispatch(enablePrivateFileRequest(rowData.id, !rowData.active))}
        >
          {rowData.active ? "Deaktivieren" : "Aktivieren"}
        </Button>
      ),
      sorting: false,
    },
    {
      title: (
        <div className={classes.table_filter}>
          <ContentTypeFilter contentType={query.contentType} setQuery={setAdminFilesQuery} options={filterOptions} />
        </div>
      ),
      field: "type",
      width: 250,
      render: rowData => (
        <Typography className={classes.tablefield_type}>
          <TypeHandler type={rowData.type} />
        </Typography>
      ),
      sorting: false,
    },
    {
      title: "Reihenfolge",
      field: "order",
      width: 50,
      render: rowData => (
        <Typography noWrap className={classes.tablefield_id}>
          #{rowData.order}
        </Typography>
      ),
      sorting: false,
    },
  ];

  return (
    <section className={`${classes.table_section} container`}>
      <Typography align="center" variant="h3" className={classes.table_title}>
        Private Dateien Verwaltung
      </Typography>
      <MaterialTable
        columns={columns}
        isLoading={loading}
        data={files}
        paging={false}
        title="Dateiliste"
        actions={[
          {
            icon: "import_export",
            tooltip: "Datei-Reihenfolge ändern",
            onClick: (event, rowData) => dispatch(openPrivateFileModal(rowData, "ORDER")),
          },
          {
            icon: "edit",
            tooltip: "Datei bearbeiten",
            onClick: (event, rowData) => {
              const file = { ...rowData };
              file.mainFile = { size: rowData.contentLength, id: rowData.mainFileId };
              file.additionalFile = rowData.additionalFiles.map(el => {
                return { size: el.contentLength, id: el.id };
              });
              file.deleteAdditionalFilesIds = [];
              file.logos = rowData.logos.map(el => {
                return { size: el.contentLength, id: el.id };
              });
              file.deleteLogosIds = [];
              delete file.tableData;
              delete file.additionalFiles;
              dispatch(fillPrivateFileForm(file));
              history.push("/file_form");
            },
          },
          {
            icon: "delete",
            tooltip: "Datei löschen",
            onClick: (event, rowData) => dispatch(openPrivateFileModal(rowData, "DELETE")),
          },
          {
            icon: "add",
            tooltip: "Datei hinzufügen",
            isFreeAction: true,
            onClick: () => {
              dispatch(cleanPrivateFileForm());
              history.push("/file_form");
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          minBodyHeight: 340,
          draggable: false,
          search: false,
        }}
        localization={localization}
        components={{
          Pagination: () => (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              page={query.page}
              count={pagination.totalCount}
              rowsPerPage={query.size}
              SelectProps={{ inputProps: { "aria-label": "rows per page" } }}
              onChangePage={(e, page) => dispatch(setAdminFilesQuery({ page }))}
              onChangeRowsPerPage={e => dispatch(setAdminFilesQuery({ size: +e.target.value, page: 0 }))}
            />
          ),
          Action: props => {
            return props.action.icon === "delete" && props.data.active ? (
              <IconButton aria-label={props.action.icon} disabled={props.data.active}>
                <Icon>{props.action.icon}</Icon>
              </IconButton>
            ) : (
              <Tooltip title={props.action.tooltip}>
                <IconButton aria-label={props.action.icon} onClick={event => props.action.onClick(event, props.data)}>
                  <Icon>{props.action.icon}</Icon>
                </IconButton>
              </Tooltip>
            );
          },
        }}
      />

      <PrivateFileModal />
    </section>
  );
};
