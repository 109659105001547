import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  banner_section: {
    display: "flex",
    justifyContent: "space-between",
    height: 440,
    marginTop: 85,
    background: theme.palette.primary.decor,
    [theme.breakpoints.down("sm")]: {
      height: 210,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      height: 310,
    },
  },
  banner_content: {
    width: "50%",
    marginRight: 90,
    padding: "100px 10px 0 140px",
    [theme.breakpoints.down("md")]: {
      marginRight: 10,
      padding: "100px 10px 0 20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      padding: "30px 10px 0 80px",
    },
    [theme.breakpoints.down("xs")]: {
      width: 258,
      textAlign: "center",
      margin: "0 auto",
      padding: "35px 0 0 0",
      order: 2,
    },
  },
  banner_header: {
    marginBottom: 21,
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      fontSize: 18,
      textAlign: "left",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },
  banner_text: {
    fontSize: 18,
    color: "#fff",
    textAlign: "justify",
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "8px 0 45px 0",
    },
  },
  banner_img: {
    width: "50%",
    height: 300,
    [theme.breakpoints.down("sm")]: {
      height: 150,
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      marginTop: 8,
      order: 1,
    },
  }
}));

export default useStyles;
