import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  sliderItem: {
    display: "flex !important",
  },
  sliderImage: {
    display: "flex",
    width: "100%",
    height: "560px",
    [theme.breakpoints.down("sm")]: {
      height: "360px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "260px",
    },
  },
}));

export default useStyles;
