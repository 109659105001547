import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import {
  closePrivateFileModal,
  editPrivateFileOrder,
  editPrivateFileOrderRequest,
  deletePrivateFileRequest
} from "../../../store/actions";

import { Preloader } from "../../сommon/Preloader";
import { ErrorHandler } from "../../сommon/ErrorHandler";

import useStyles from "../useStyles";

/**
 * @desc function for create private file modal window
 * @returns {JSX.Element}
 */
export const PrivateFileModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    isModalOpen,
    selectedFile,
    modalContent,
    fileOrder,
    actionLoading,
    actionError
  } = useSelector(({ adminFiles }) => adminFiles);

  const confirmBtnClick = () => {
    modalContent === "DELETE" && dispatch(deletePrivateFileRequest(selectedFile.id));
    modalContent === "ORDER" && dispatch(editPrivateFileOrderRequest(selectedFile.id, fileOrder));
  };

  const handleOrder = event => {
    if (+event.target.value <= selectedFile.maxOrder && +event.target.value > 0 && Number.isInteger(+event.target.value)) {
      dispatch(editPrivateFileOrder(+event.target.value));
    }
  };

  const closeModal = () => dispatch(closePrivateFileModal());

  return (
    isModalOpen && (
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        disableScrollLock={true}
      >
        <Fade in={isModalOpen}>
          <div className={classes.paper}>
            <div className={classes.modal_wrap}>
              <Typography className={classes.modal_text}>
                {`Privaten Inhalt ID ${selectedFile.id}, Reihenfolge #${selectedFile.order}`}
              </Typography>
              <Typography className={classes.modal_text}>
                {modalContent === "DELETE" && "Möchten Sie privaten Inhalt löschen?"}
                {modalContent === "ORDER" && "Möchten Sie privaten Inhalt ändern?"}
              </Typography>
              <IconButton className={classes.modal_close_btn} onClick={closeModal} aria-label="close">
                <CloseIcon />
              </IconButton>

              {modalContent === "ORDER" && !!fileOrder && (
                <TextField
                  value={fileOrder}
                  onChange={handleOrder}
                  variant="outlined"
                  size="small"
                  type="number"
                  className={classes.modal_number_input}
                />
              )}

              <div className={classes.modal_buttons}>
                <Button onClick={closeModal} variant="contained" className={classes.modal_btn}>
                  Nein
                </Button>
                {actionLoading ? (
                  <div className={classes.modal_btn}>
                    <Preloader />
                  </div>
                ) : (
                  <Button onClick={confirmBtnClick} variant="contained" className={classes.modal_btn}>
                    Ja
                  </Button>
                )}
              </div>

              {!!actionError && <ErrorHandler error={actionError} />}
            </div>
          </div>
        </Fade>
      </Modal>
    )
  );
};
