import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  goBack_btn: {
    display: "flex",
    height: 72,
    width: 176,
    borderRadius: 0,
    justifyContent: "flex-end",
    padding: "10px 20px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 52,
      padding: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 60,
      height: 34,
    },
  },
  goBack_icon: {
    fontSize: 62,
    [theme.breakpoints.down("sm")]: {
      fontSize: 44,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
    },
  },
}));

export default useStyles;
