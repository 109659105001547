import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  editor_block: {
    marginBottom: 16,
  },
  editor_wrapper: {
    border: "1px solid #000000",
    borderRadius: 2,
    padding: 20,
    marginBottom: 5,
    width: 800,
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      width: 300,
    },
  },
  editor_title: {
    margin: "10px auto 20px auto",
    fontSize: 20,
    fontWeight: 500,
  },
  editor_error: {
    margin: "0 auto 10px auto",
    fontSize: 12,
  },
}));

export default useStyles;
