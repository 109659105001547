import { useDispatch, useSelector } from "react-redux";

import { Preloader } from "../../сommon/Preloader";
import { ErrorHandler } from "../../сommon/ErrorHandler";

import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";

import { logOutRequest } from "../../../store/actions";

import useStyles from "../useStyles";

/**
 * @desc function for create logout modal window
 * @param {boolean} isModalOpen - toggle value
 * @param {function} closeModal - close modal window
 * @returns {JSX.Element}
 */

const LogoutModal = ({ isModalOpen, closeModal }) => {
  const { actionError } = useSelector(({ users }) => users);
  const { loading } = useSelector(({ login }) => login);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleLogout = () => {
    const refreshToken = localStorage.getItem("bpiRefreshToken");
    dispatch(logOutRequest(refreshToken));
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={() => closeModal()}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      disableScrollLock={true}
    >
      <Fade in={isModalOpen}>
        <div className={classes.paper}>
          <div className={classes.modal_wrap}>
            <Typography className={classes.modal_text}>
              Möchten Sie sich wirklich ausloggen?
            </Typography>

            <IconButton className={classes.modal_close_btn} onClick={closeModal} aria-label="close">
              <CloseIcon />
            </IconButton>

            <div className={classes.modal_buttons}>
              <Button onClick={closeModal} variant="contained" className={classes.modal_btn}>
                Nein
              </Button>
              {loading ? (
                <div className={classes.modal_btn}>
                  <Preloader />
                </div>
              ) : (
                <Button onClick={handleLogout} variant="contained" className={classes.modal_btn}>
                  Ja
                </Button>
              )}
            </div>

            {!!actionError && <ErrorHandler error={actionError} />}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default LogoutModal;
