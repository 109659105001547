import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import LogoutModal from "../../modals/LogoutModal/LogoutModal";

import { Button, Typography, Drawer, List, ListItem, ListItemIcon } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import GroupIcon from "@material-ui/icons/Group";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import ViewListIcon from "@material-ui/icons/ViewList";
import AttachmentIcon from "@material-ui/icons/Attachment";


import { useStyles } from "./useStyles";

/**
 * @desc function for create aside personal menu
 * @param {string} container - type of personal menu container
 * @returns {JSX.Element}
 */
export const PersonalMenu = ({ container = "container" }) => {
  const classes = useStyles();
  const { role, email } = useSelector(({ login }) => login);
  const [open, setOpen] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const openModal = () => {
    setLogoutModalOpen(true);
  };

  const closeModal = () => {
    setLogoutModalOpen(false);
  };

  const toggleDrawer = open => event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpen(open);
  };

  return (
    <section className={`${classes.privateMenuSection} ${container}`}>
      <Button variant="contained" onClick={toggleDrawer(true)} className={classes.handlePrivateMenuBtn}>
        <MenuIcon className={classes.handlePrivateMenuIcon} />
      </Button>
      <Drawer anchor={"left"} open={open} onClose={toggleDrawer(false)}>
        <div onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <List className={classes.privateMenuList}>
            <div>
              <Link to="/content" className={classes.privateMenuLink}>
                <ListItem button>
                  <ListItemIcon>
                    <SystemUpdateAltIcon className={classes.privateMenuLinkIcon} />
                  </ListItemIcon>
                  <Typography variant="body1" className={classes.privateMenuText}>Download Portal</Typography>
                </ListItem>
              </Link>
              {role === "ROLE_ADMIN" && (
                <>
                  <Link to="/blogs" className={classes.privateMenuLink}>
                    <ListItem button>
                      <ListItemIcon>
                        <ListAltIcon className={classes.privateMenuLinkIcon} />
                      </ListItemIcon>
                      <Typography variant="body1" className={classes.privateMenuText}>Blog-Verwaltung</Typography>
                    </ListItem>
                  </Link>
                  <Link to="/blog_form" className={classes.privateMenuLink}>
                    <ListItem button>
                      <ListItemIcon>
                        <PlaylistAddIcon className={classes.privateMenuLinkIcon} />
                      </ListItemIcon>
                      <Typography variant="body1" className={classes.privateMenuText}>Unsere Zentralen Themen</Typography>
                    </ListItem>
                  </Link>
                  <Link to="/files" className={classes.privateMenuLink}>
                    <ListItem button>
                      <ListItemIcon>
                        <ViewListIcon className={classes.privateMenuLinkIcon} />
                      </ListItemIcon>
                      <Typography variant="body1" className={classes.privateMenuText}>Private Dateiverwaltung</Typography>
                    </ListItem>
                  </Link>
                  <Link to="/file_form" className={classes.privateMenuLink}>
                    <ListItem button>
                      <ListItemIcon>
                        <AttachmentIcon className={classes.privateMenuLinkIcon} />
                      </ListItemIcon>
                      <Typography variant="body1" className={classes.privateMenuText}>Private Dateien Formular</Typography>
                    </ListItem>
                  </Link>
                  <Link to="/users" className={classes.privateMenuLink}>
                    <ListItem button>
                      <ListItemIcon>
                        <GroupIcon className={classes.privateMenuLinkIcon} />
                      </ListItemIcon>
                      <Typography variant="body1" className={classes.privateMenuText}>Benutzerverwaltung</Typography>
                    </ListItem>
                  </Link>
                </>
              )}
            </div>
            <div>
              <ListItem
                button
                onClick={openModal}
                className={classes.privateMenuLink}
              >
                <ListItemIcon>
                  <MeetingRoomIcon className={classes.privateMenuLinkIcon} />
                </ListItemIcon>
                <Typography variant="body1" className={classes.privateMenuText}>Ausloggen</Typography>
              </ListItem>
              <ListItem button className={classes.privateMenuLink}>
                <Typography noWrap className={classes.privateMenuEmail}>
                  {email}
              </Typography>
              </ListItem>
            </div>
          </List>
        </div>
      </Drawer>
      <LogoutModal isModalOpen={logoutModalOpen} closeModal={closeModal} />
    </section>
  );
};
