import { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import i18n from 'i18n-js';

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import { PrivateFilesPreviewSlider } from "../сommon/PrivateFilesPreviewSlider";

import {
  setPrivateContentQuery,
  selectPrivateContent,
  setAddPrivateContentQuery,
  setPrivateContentForDownload,
  downloadPrivateContentRequest,
  selectPrivateContentForImagesMerge,
} from "../../store/actions";

import { ImageSelectorAccordion } from "../сommon/ImageSelectorAccordion";
import { LoadIcon } from "../сommon/icons/LoadIcon";
import { Preloader } from "../сommon/Preloader";
import { AddContentButton } from "../сommon/buttons/AddContentButton";
import { CheckboxIcon } from "../сommon/icons/CheckboxIcon";
import { CheckboxCheckedIcon } from "../сommon/icons/CheckboxCheckedIcon";
import getBytesFromFileSize from "../../utils/getBytesFromFileSize";

import useStyles from "./useStyles";
import { Fragment } from "react";

/**
 * @desc function for create private content
 * @returns {JSX.Element}
 */
export const PrivateContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [filterVisibility, setFilterVisibility] = useState(false);
  const [windowSize, setWindowSize] = useState(0);
  const {
    selectedContent,
    privateFilesForDownload,
    privateFiles,
    pagination,
    loading,
    actionLoading,
    query: { contentType }
  } = useSelector(({ privateFiles }) => privateFiles);
  const { role } = useSelector(({ login }) => login);

  useEffect(() => {
    dispatch(setPrivateContentQuery({ page: 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !!privateFiles.length && dispatch(selectPrivateContent(privateFiles[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateFiles]);

  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset > 550 && window.innerWidth > 959) {
        setFilterVisibility(true);
      }
      if (window.pageYOffset > 300 && window.innerWidth < 959) {
        setFilterVisibility(true);
      }
      if (window.pageYOffset < 550 && window.innerWidth > 959) {
        setFilterVisibility(false);
      }
      if (window.pageYOffset < 300 && window.innerWidth < 959) {
        setFilterVisibility(false);
      }
    };

    return () => {
      window.onscroll = null;
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    if (window.innerWidth) {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleAddFileForDownload = (e, object, file) => {
    if (e.target.checked) {
      dispatch(setPrivateContentForDownload([...privateFilesForDownload, object]));
      file && dispatch(selectPrivateContent(file));
    } else {
      const filteredFiles = privateFilesForDownload.filter(file => file.id !== object.id);
      dispatch(setPrivateContentForDownload(filteredFiles));
    }
  };

  const downloadPrivateFiles = () => {
    !!privateFilesForDownload.length && dispatch(downloadPrivateContentRequest());
  };

  const addPrivateFiles = () => {
    const page = pagination.page + 1;
    dispatch(setAddPrivateContentQuery(page));
  };

  const selectContentForMerge = (imageId, contentId, resultFormat, forLogos) => {
    if (["ROLE_ADMIN", "ROLE_PREMIUM_USER"].includes(role) && ["image/jpeg", "image/png"].includes(resultFormat) && forLogos) {
      dispatch(selectPrivateContentForImagesMerge(imageId, contentId, resultFormat));
      history.push("/logos");
    }
  }

  const handleSelectFile = file => {
    dispatch(selectPrivateContent(file));
    selectContentForMerge(file.mainFileId, file.id, file.contentType, file.forLogos);
  }

  const preparedPrivateFiles = useMemo(() => {
    let SIZE = 3;

    if (windowSize < 1280) {
      SIZE = 2;
    }
    if (windowSize < 600) {
      SIZE = 1;
    }

    return privateFiles.reduce((p, c) => {
      if (p[p.length - 1].length == SIZE) {
        p.push([]);
      }

      p[p.length - 1].push(c);
      return p;
    }, [[]]);
  }, [privateFiles, windowSize])

  return (
    <section className={`${classes.content_section} container`}>
      {/* {filterVisibility && <FloatFilter filter={contentType} setFilter={setPrivateContentQuery} />} */}
      <div className={classes.content_header}>
        <Typography className={classes.content_header_text}>{i18n.t("privatContent.title")}</Typography>
        <div className={classes.content_accordion}>
          <ImageSelectorAccordion />
        </div>
      </div>
      <div className={classes.content_body}>
        <div className={classes.content_wrapper}>
          {loading && <div className={classes.content_body_overlay} />}
          <Grid container className={classes.content_container}>
            {preparedPrivateFiles.map((filesArr, index) => {
              const isPreviewVisible = filesArr.find((file) => file.id === selectedContent.id);

              return (
                <Fragment key={index}>
                  {filesArr.map((item) => (
                    <Grid key={item.id} className={classes.content_file} onClick={() => handleSelectFile(item)}>
                      <div
                        className={classes.content_img}
                        style={{ backgroundImage: item.mainImages.length > 0 ? `url(${item.mainImages[0]})` : "url(img/mockup.jpg)" }}
                      >
                        <div className={`visibility_hidden ${classes.content_overlay}`}>
                          <Typography className={`${classes.content_overlay_text} ${classes.content_overlay_size}`}>
                            {getBytesFromFileSize(item.contentLength)}
                          </Typography>
                          <Typography className={`${classes.content_overlay_text} ${classes.content_overlay_title}`}>
                            {item.title}
                          </Typography>
                          <Typography className={`${classes.content_overlay_text} ${classes.content_overlay_description}`}>
                            {item.description}
                          </Typography>
                        </div>
                      </div>
                      <Checkbox
                        icon={<CheckboxIcon className={classes.content_checkbox_icon} />}
                        checkedIcon={<CheckboxCheckedIcon className={classes.content_checkbox_icon} />}
                        className={classes.content_checkbox}
                        onChange={e =>
                          handleAddFileForDownload(e, {
                            id: item.mainFileId,
                            contentId: item.id,
                            title: item.title,
                            contentLength: item.contentLength,
                            type: item.type,
                            mainImages: item.mainImages,
                          }, item)
                        }
                        checked={privateFilesForDownload.some(el => el.id === item.mainFileId)}
                        onClick={e => e.stopPropagation()}
                      />
                    </Grid>
                  ))}
                  {selectedContent.id && isPreviewVisible && (

                    <Grid container className={classes.content_container_preview}>
                      <Grid
                        className={classes.content_file_preview}
                        // onClick={() => selectContentForMerge(selectedContent.mainFileId, selectedContent.id, selectedContent.contentType, selectedContent.forLogos)}
                      >
                        <PrivateFilesPreviewSlider mainImages={selectedContent?.mainImages?.length > 0 ? selectedContent.mainImages : ["img/mockup.jpg"]} />
                        {/* <div className={classes.content_img} style={{ backgroundImage: selectedContent.mainImages.length > 0 ? `url(${selectedContent.mainImages[0]})` : "url(img/mockup.jpg)" }}>
                          <div className={`visibility_hidden ${classes.content_overlay}`}>
                            <Typography className={`${classes.content_overlay_text} ${classes.content_overlay_size}`}>
                              {getBytesFromFileSize(selectedContent?.contentLength)}
                            </Typography>
                            <Typography className={`${classes.content_overlay_text} ${classes.content_overlay_title}`}>
                              {selectedContent?.title}
                            </Typography>
                            <Typography className={`${classes.content_overlay_text} ${classes.content_overlay_description}`}>
                              {selectedContent?.description}
                            </Typography>
                          </div>
                        </div> */}
                      </Grid>
                    </Grid>
                  )}
                </Fragment>
              )
            })}
          </Grid>

          {/* <Grid container className={classes.content_container_conditions}>
            {selectedContent.id &&
              selectedContent.additionalFiles.map(file => (
                <Grid key={file.id} className={classes.content_file_conditions}>
                  <div
                    className={classes.content_img}
                    style={{ backgroundImage: selectedContent.mainImage ? `url(${selectedContent.mainImage})` : "url(img/mockup.jpg)" }}
                  >
                    <div className={`visibility_hidden ${classes.content_overlay}`}>
                      <Typography className={`${classes.content_overlay_text} ${classes.content_overlay_size}`}>
                        {getBytesFromFileSize(file.contentLength)}
                      </Typography>
                      <Typography className={`${classes.content_overlay_text} ${classes.content_overlay_title_sm}`}>
                        {selectedContent.title}
                      </Typography>
                      <Typography className={`${classes.content_overlay_text} ${classes.content_overlay_description_sm}`}>
                        {selectedContent.description}
                      </Typography>
                    </div>
                  </div>
                  <Checkbox
                    icon={<CheckboxIcon className={classes.content_checkbox_icon} />}
                    checkedIcon={<CheckboxCheckedIcon className={classes.content_checkbox_icon} />}
                    className={classes.content_conditions_checkbox}
                    onChange={e =>
                      handleAddFileForDownload(e, {
                        id: file.id,
                        contentId: selectedContent.id,
                        title: selectedContent.title,
                        contentLength: file.contentLength,
                        type: selectedContent.type,
                        mainImage: selectedContent.mainImage,
                      })
                    }
                    checked={privateFilesForDownload.some(el => el.id === file.id)}
                  />
                </Grid>
              ))}
          </Grid> */}
        </div>

        {privateFiles.length !== pagination.totalCount &&
          <div className={classes.content_add_blogs_btn_wrap}>
            {loading
              ? (<Preloader />)
              : (<AddContentButton onClick={addPrivateFiles} />)
            }
          </div>
        }
      </div>

      <div className={classes.content_footer}>
        <Typography className={classes.content_counter}>{privateFilesForDownload.length}</Typography>
        <Typography className={classes.content_load_text}>Ausgewählte Dateien herunterladen</Typography>
        {actionLoading ? (
          <Preloader className={classes.content_load_preloader} />
        ) : (
          <IconButton onClick={downloadPrivateFiles}>
            <LoadIcon className={classes.content_load_icon} />
          </IconButton>
        )}
      </div>
    </section>
  );
};
