import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";

import useStyles from "./useStyles";

/**
 * @desc function for create impressum page
 * @returns {JSX.Element}
 */
export const ImpressumContent = () => {
  const classes = useStyles();
  const metaDescriptionText = "Impressum des Bundesverbands der Pharmazeutischen Industrie e.V., Friedrichstr. 148, 10117 Berlin. Tel. +49 30 27 909 - 0, E-Mail: info@bpi.de";

  return (
    <section className={`${classes.impressum_section} container`}>
      <Helmet>
        <meta name="description" content={metaDescriptionText} />
        <meta name="keywords" content="Impressum" />
      </Helmet>
      <div className={classes.impressum_block}>
        <Typography variant="h3" className={classes.impressum_title}>Impressum</Typography>
        <div className={classes.impressum_row}>
          <Typography className={classes.impressum_paragraph}>Angaben gem. § 5 TMG</Typography>
        </div>
        <div className={classes.impressum_row}>
          <div className={classes.impressum_left_column}>
            <Typography className={classes.impressum_paragraph}>Anbieter:</Typography>
          </div>
          <div className={classes.impressum_right_column}>
            <Typography className={classes.impressum_paragraph}>Bundesverband der Pharmazeutischen</Typography>
            <Typography className={classes.impressum_paragraph}>Industrie e.V.</Typography>
          </div>
        </div>
        <div className={classes.impressum_row}>
          <div className={classes.impressum_left_column}>
            <Typography className={classes.impressum_paragraph}>Anschrift:</Typography>
          </div>
          <div className={classes.impressum_right_column}>
            <Typography className={classes.impressum_paragraph}>Friedrichstraße 148</Typography>
            <Typography className={classes.impressum_paragraph}>10117 Berlin</Typography>
          </div>
        </div>
        <div className={classes.impressum_row}>
          <div className={classes.impressum_left_column}>
            <Typography className={classes.impressum_paragraph}>Telefon:</Typography>
          </div>
          <div className={classes.impressum_right_column}>
            <Typography className={classes.impressum_paragraph}>+49 30 27 909 - 0</Typography>
          </div>
        </div>
        <div className={classes.impressum_row}>
          <div className={classes.impressum_left_column}>
            <Typography className={classes.impressum_paragraph}>Fax:</Typography>
          </div>
          <div className={classes.impressum_right_column}>
            <Typography className={classes.impressum_paragraph}>+49 30 27 909 - 361</Typography>
          </div>
        </div>
        <div className={classes.impressum_row}>
          <div className={classes.impressum_left_column}>
            <Typography className={classes.impressum_paragraph}>E-Mail:</Typography>
          </div>
          <div className={classes.impressum_right_column}>
            <a href="mailto:info@bpi.de" className={`${classes.impressum_paragraph} ${classes.impressum_link}`}>
              info@bpi.de
            </a>
          </div>
        </div>
        <div className={classes.impressum_row}>
          <div className={classes.impressum_left_column}>
            <Typography className={classes.impressum_paragraph}>Web:</Typography>
          </div>
          <div className={classes.impressum_right_column}>
            <a
              href="http://bpi.de"
              className={`${classes.impressum_paragraph} ${classes.impressum_link}`}
              target="_blank"
              rel="noreferrer"
            >
              www.bpi.de
            </a>
          </div>
        </div>
        <div className={classes.impressum_row}>
          <div className={classes.impressum_left_column}>
            <Typography className={classes.impressum_paragraph}>Gesetzlicher Vertreter:</Typography>
          </div>
          <div className={classes.impressum_right_column}>
            <Typography className={classes.impressum_paragraph}>Dr. Kai Joachimsen</Typography>
            <Typography className={classes.impressum_paragraph}>Hauptgeschäftsführer und Mitglied des</Typography>
            <Typography className={classes.impressum_paragraph}>Vorstandes</Typography>
          </div>
        </div>
        <div className={classes.impressum_row}>
          <div className={classes.impressum_left_column}>
            <Typography className={classes.impressum_paragraph}>Verantwortlich für den Inhalt:</Typography>
          </div>
          <div className={classes.impressum_right_column}>
            <Typography className={classes.impressum_paragraph}>Dr. Kai Joachimsen</Typography>
            <Typography className={classes.impressum_paragraph}>Hauptgeschäftsführer und Mitglied des</Typography>
            <Typography className={classes.impressum_paragraph}>Vorstandes</Typography>
            <Typography className={classes.impressum_paragraph}>Bundesverband der Pharmazeutischen</Typography>
            <Typography className={classes.impressum_paragraph}>Industrie e.V.</Typography>
            <Typography className={classes.impressum_paragraph}>Friedrichstraße 148</Typography>
            <Typography className={classes.impressum_paragraph}>10117 Berlin</Typography>
            <a href="mailto:kjoachimsen@bpi.de" className={`${classes.impressum_paragraph} ${classes.impressum_link}`}>
              kjoachimsen@bpi.de
            </a>
          </div>
        </div>
        <div className={classes.impressum_row}>
          <div className={classes.impressum_left_column}>
            <Typography className={classes.impressum_paragraph}>HR-Nr.</Typography>
          </div>
          <div className={classes.impressum_right_column}>
            <Typography className={classes.impressum_paragraph}>Amtsgericht Berlin Charlottenburg</Typography>
            <Typography className={classes.impressum_paragraph}>Registernummer: VR 22186 B</Typography>
          </div>
        </div>
        <div className={classes.impressum_row}>
          <div className={classes.impressum_left_column}>
            <Typography className={classes.impressum_paragraph}>Datenschutzbeauftragter:</Typography>
          </div>
          <div className={classes.impressum_right_column}>
            <Typography className={classes.impressum_paragraph}>Roman Maczkowsky</Typography>
            <Typography className={classes.impressum_paragraph}>m-privacy GmbH</Typography>
            <Typography className={classes.impressum_paragraph}>Berlin</Typography>
            <a href="mailto:datenschutz@bpi.de" className={`${classes.impressum_paragraph} ${classes.impressum_link}`}>
              datenschutz@bpi.de
            </a>
          </div>
        </div>
        <div className={classes.impressum_row}>
          <div className={classes.impressum_left_column}>
            <Typography className={classes.impressum_paragraph}>USt Nr.</Typography>
          </div>
          <div className={classes.impressum_right_column}>
            <Typography className={classes.impressum_paragraph}>DE22 78 44 507</Typography>
          </div>
        </div>
      </div>
      <div className={classes.impressum_block}>
        <Typography variant="h3" className={classes.impressum_title}>Konzept & Design</Typography>
        <div className={classes.impressum_row}>
          <Typography className={classes.impressum_paragraph}>BLUMBERRY GmbH</Typography>
        </div>
        <div className={classes.impressum_row}>
          <Typography className={classes.impressum_paragraph}>CONTENT. CONSULTING. CAMPAIGNING.</Typography>
        </div>
        <div className={classes.impressum_row}>
          <Typography className={classes.impressum_paragraph}>Litfaß-Platz 1, DE-10178 Berlin</Typography>
        </div>
        <div className={classes.impressum_row}>
          <a
            href="http://blumberry.de"
            className={`${classes.impressum_paragraph} ${classes.impressum_link}`}
            target="_blank"
            rel="noreferrer"
          >
            www.blumberry.de
          </a>
        </div>
      </div>
      <div className={classes.impressum_block}>
        <Typography variant="h3" className={classes.impressum_title}>Technische Realisierung</Typography>
        <div className={classes.impressum_row}>
          <Typography className={classes.impressum_paragraph}>impltech GmbH</Typography>
        </div>
        <div className={classes.impressum_row}>
          <Typography className={classes.impressum_paragraph}>Greifswalderstraße 226, DE-10405 Berlin</Typography>
        </div>
        <div className={classes.impressum_row}>
          <a
            href="http://impltech.de"
            className={`${classes.impressum_paragraph} ${classes.impressum_link}`}
            target="_blank"
            rel="noreferrer"
          >
            www.impltech.de
          </a>
        </div>
      </div>
      <div className={classes.impressum_block}>
        <Typography variant="h3" className={classes.impressum_title}>Haftung für Inhalte</Typography>
        <Typography className={classes.impressum_text_section}>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        </Typography>
      </div>
      <div className={classes.impressum_block}>
        <Typography variant="h3" className={classes.impressum_title}>Haftung für Links</Typography>
        <Typography className={classes.impressum_text_section}>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        </Typography>
        <Typography className={classes.impressum_text_section}>
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        </Typography>
      </div>
      <div className={classes.impressum_block}>
        <Typography variant="h3" className={classes.impressum_title}>Urheberrecht</Typography>
        <Typography className={classes.impressum_text_section}>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtesbedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </Typography>
      </div>
      <div className={classes.impressum_block}>
        <Typography variant="h3" className={classes.impressum_title}>Quellenangaben der verwendeten Bilder</Typography>
        <Typography className={classes.impressum_text_section}>
          Für Artikel des BPI – Shutterstock
        </Typography>
        <Typography className={classes.impressum_text_section}>
          Für Artikel der Mitgliedsunternehmen – Die Quellenangaben liegen beim jeweiligen Mitgliedsunternehmen.
        </Typography>
        <Typography className={classes.impressum_text_section}>
          Die <span className={classes.impressum_decor}>Datenschutzerklärung des BPI e.V.</span> finden Sie hier: <a href="https://bpi.de/datenschutz" className={classes.impressum_decor} target="_blank" rel="noreferrer">www.bpi.de/datenschutz</a>
        </Typography>
      </div>
    </section>
  );
}
