import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  add_blogs_btn: {
    height: 38,
    width: 38,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    borderRadius: 20,
    color: "transparent",
    transition: "all 0.5s",
    "&:hover": {
      transform: "rotate(90deg)"
    },
  },
}));

export default useStyles;
