import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import i18n from "i18n-js";

import { Typography } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Button, InputAdornment, IconButton } from "@material-ui/core";

import { Preloader } from "../../сommon/Preloader";
import { ErrorHandler } from "../../сommon/ErrorHandler";

import { logInRequest } from "../../../store/actions";

import useStyles from "../useStyles";
import { HeaderLogoIcon } from "../../сommon/icons/HeaderLogoIcon";
import { SignupUnlockIcon } from "../../сommon/icons/SignupUnlockIcon";

/**
 * @desc function for create login form
 * @returns {JSX.Element}
 */
export const LoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { error, loading } = useSelector(({ login }) => login);
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleChange = event => setUser({
    ...user,
    [event.target.name]: event.target.value,
  });
  const handleEmailChange = event => setUser({
    ...user,
    [event.target.name]: event.target.value.toLowerCase()
  });
  const handleShowPassword = () => setShowPassword(prev => !prev);
  const handleSubmit = () => dispatch(logInRequest(user));

  return (
    <div className={classes.form}>
      <div className={classes.formTitle}>
        <SignupUnlockIcon className={classes.formTitleImg} />
        <Typography variant="h3" color="textPrimary">
          {i18n.t("login.title")}
        </Typography>
      </div>
      <div className={classes.formWrapper}>
        <div className={classes.formLogoWrapper}>
          <HeaderLogoIcon className={classes.formLogo} />
        </div>
        <ValidatorForm className={classes.formContent} noValidate={false} onSubmit={handleSubmit}>
          <div>
            <TextValidator
              className={classes.formTextField}
              variant="filled"
              placeholder="Mail"
              name="email"
              value={user.email}
              onChange={e => handleEmailChange(e)}
              inputProps={{
                maxLength: 50,
                className: classes.formTextInput,
              }}
              validators={["required", "isEmail"]}
              errorMessages={["Dieses Feld wird benötigt", "Ungültiges Email-Format"]}
            />

            <TextValidator
              className={classes.formTextField}
              variant="filled"
              placeholder="Passwort"
              name="password"
              value={user.password}
              onChange={e => handleChange(e)}
              InputProps={{
                type: showPassword ? "text" : "password",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleShowPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 30,
                className: classes.formTextInput,
              }}
              validators={["required", `matchRegexp:^[a-zA-ZäöüßÄÖÜẞ0-9\\!\\#\\$\\%\\&\\'\\(\\)\\*\\+\\,\\-\\.\\/\\:\\;\\<\\>\\=\\?\\@\\[\\]\\{\\}\\\\\\\\\\^\\_\\"\\~\\|]{4,20}$`]}
              errorMessages={["Dieses Feld wird benötigt", "Das Passwort muss aus 4 bis 20 Symbolen bestehen"]}
            />
            <div className={classes.formLinksWrap}>
              <Link to="/recovery_password" className={classes.formLink}>
                Passwort vergessen
              </Link>
              <Link to="/signup" className={classes.formLink}>
                Registrierung
              </Link>
            </div>
          </div>

          {!!error && <ErrorHandler error={error} />}

          {loading ? (
            <Preloader />
          ) : (
            <Button className={classes.formSubmitBtn} type="submit">
              Einloggen
            </Button>
          )
          }
        </ValidatorForm>
      </div>
    </div>
  );
};
