import { combineReducers } from "redux";
import { blogsReducer } from "./blogsReducer";
import { loginReducer } from "./loginReducer";
import { errorsReducer } from "./errorsReducer";
import { usersReducer } from "./usersReducer";
import { adminBlogsReducer } from "./adminBlogsReducer";
import { adminFilesReducer } from "./adminFilesReducer";
import { privateFilesReducer } from "./privateFilesReducer";

export const rootReducer = combineReducers({
  blogs: blogsReducer,
  login: loginReducer,
  errors: errorsReducer,
  users: usersReducer,
  adminBlogs: adminBlogsReducer,
  adminFiles: adminFilesReducer,
  privateFiles: privateFilesReducer,
});
