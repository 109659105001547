import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

import { Typography, Button } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { Preloader } from "../../сommon/Preloader";
import { ErrorHandler } from "../../сommon/ErrorHandler";

import useStyles from "../useStyles";
import { HeaderLogoIcon } from "../../сommon/icons/HeaderLogoIcon";
import { SignupUnlockIcon } from "../../сommon/icons/SignupUnlockIcon";
import { SuccessModal } from "../../modals/SuccessModal";

/**
 * @desc function for create recovery password form
 * @returns {JSX.Element}
 */
export const RecoveryPasswordForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const unmounted = useRef(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const handleChange = event => setEmail(event.target.value.toLowerCase());
  
  const closeModal = () => {
    setSuccessModalOpen(false);
    history.push("/home");
  };
  const handleSubmit = () => {
    setLoading(true);
    setError(null);
    axios
      .put("uaa/user/password/recovery", { email })
      .then(res => {
        if (!unmounted.current) {
          if (res.status === 200) {
            setSuccessModalOpen(true);
          }
        }
      })
      .catch(error => {
        if (!unmounted.current) {
          setError(error);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    return () => { unmounted.current = true };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.form}>
      <div className={classes.formTitle}>
        <SignupUnlockIcon className={classes.formTitleImg} />
        <Typography variant="h3" color="textPrimary">
          Passwort zurücksetzen
        </Typography>
      </div>
      <div className={classes.formWrapper}>
        <div className={classes.formLogoWrapper}>
          <HeaderLogoIcon className={classes.formLogo} />
        </div>
        <ValidatorForm className={classes.formContent} noValidate={false} onSubmit={handleSubmit} autoComplete="off">
          <div>
            <TextValidator
              className={`${classes.formTextField} ${classes.formTextFieldMail}`}
              variant="filled"
              placeholder="Mail"
              name="email"
              value={email}
              onChange={e => handleChange(e)}
              inputProps={{
                maxLength: 50,
                className: classes.formTextInput,
              }}
              validators={["required", "isEmail"]}
              errorMessages={["Dieses Feld wird benötigt", "Ungültiges Email-Format"]}
            />

            <div className={classes.formLinksWrap}>
              <Link to="/signin" className={classes.formLink}>
                Einloggen
              </Link>
            </div>
          </div>

          {!!error && <ErrorHandler error={error} />}

          {loading ? (
            <Preloader />
          ) : (
            <Button className={classes.formSubmitBtn} type="submit">
              Anmelden
            </Button>
          )
          }
        </ValidatorForm>
        <SuccessModal
          isModalOpen={successModalOpen}
          onCloseModal={closeModal}
          text="Passwort erfolgreich zurückgesetzt. Wir haben den Link zu Ihrer E-Mail gesendet."
        />
      </div>
    </div>
  );
};
