import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  blog_main_slider: {
    marginBottom: 100,
  },
  blog_body: {
    marginBottom: 90,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 60,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 30,
    },
  },
  blog_header: {
    margin: "0 0 15px 0",
  },
  blog_img_wrap: {
    display: "flex",
    margin: "80px auto",
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      margin: "10px auto",
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  blog_img_stretched: {
    display: "block",
    width: "100%",
    objectFit: "cover",
  },
  blog_img: {
    display: "flex",
    objectFit: "cover",
    margin: "0 auto",
    width: "100%",
    maxWidth: "fit-content",
  },
  blog_embed_wrap: {
    maxWidth: 650,
    margin: "25px auto",
    transition: "background-color .15s ease;",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  blog_embed: {
    margin: "0 auto",
    width: "100%",
    boxSizing: "border-box",
  },
  blog_embed_caption: {
    textAlign: "center",
    fontSize: 18,
    lineHeight: "23px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      lineHeight: "14px",
    },
  },
  blog_menu_dropdown_wrap: {
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    marginBottom: 16,
    padding: "5px 10px 5px 0",
    height: 24,
    color: theme.palette.text.primary,
    cursor: "pointer",
    "&:hover .dropdown_share_menu": {
      visibility: "visible",
      opacity: 1,
    }
  },
  blog_menu_dropdown: {
    position: "absolute",
    padding: 5,
    borderRadius: 5,
    boxShadow: "0 0 4px rgba(0, 0, 0, 0.15)",
    right: -80,
    top: 0,
    minWidth: 80,
    background: "#FFFFFF",
    opacity: 1,
    visibility: "hidden",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    "&:hover": {
      visibility: "visible",
    }
  },
  blog_dropdown_link: {
    display: "flex",
    marginRight: 5,
    textDecoration: "none",
    "&:last-child": {
      marginRight: 0,
    },
  },
  blog_dropdown_icon: {
    display: "flex",
    color: theme.palette.text.primary,
    fontSize: 20,
    "&:hover": {
      opacity: 0.5,
    },
  },
  blog_h1: {
    fontSize: 51,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
    },
  },
  blog_h2: {
    fontSize: 36,
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  blog_h3: {
    fontSize: 22,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  blog_h4: {
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  blog_h5: {
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  blog_h6: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  blog_paragraph: {
    fontSize: 20,
    lineHeight: "23px",
    wordWrap: "break-word",
    wordBreak: "break-word",
    "&:not(:last-child)": {
      marginBottom: 10,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      lineHeight: "14px",
    },
  },
  blog_outer_link: {
    color: "#4b7ab2",
  },
  blog_inner_link: {
    color: theme.palette.primary.decor,
  }
}));

export default useStyles;
