import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { BlogBodyEditor } from "../../BlogBodyEditor";
import { BlogSuccessModal } from "../../modals/BlogSuccessModal";
import { Preloader } from "../../сommon/Preloader";
import { SelectContentType } from "../SelectContentType";
import { BlogMainImageType } from "../../uploads/BlogMainImageType";

import { editBlogField, addBlogRequest, editBlogRequest, addBlogsMainVideo } from "../../../store/actions/";

import useStyles from "../useStyles";
import { BlogMainImageUpload } from "../../uploads/BlogMainImageUpload";
import { BlogCroppedImageUpload } from "../../uploads/BlogCroppedImageUpload";

/**
 * @desc function for create blog form
 * @returns {JSX.Element}
 */
export const BlogForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { blog, actionLoading } = useSelector(({ adminBlogs }) => adminBlogs);
  const [sizeAlertOpen, setSizeAlertOpen] = useState(false);

  const handleFieldChange = (event) => {
    dispatch(editBlogField(event.target.name, event.target.value));
  };

  const handleVideoFieldChange = (event) => {
    dispatch(addBlogsMainVideo(event.target.value));
  };

  const submitForm = () => {
    const serialisedBlog = { ...blog };
    serialisedBlog.body = JSON.stringify(blog.body);
    delete serialisedBlog.id;
    delete serialisedBlog.tableData;
    if (serialisedBlog.body.length > 20000) {
      setSizeAlertOpen(true);
      return;
    }
    if (!blog.croppedImage || !blog.mainImages.length) {
      window.scrollTo({ top: 400, behavior: "smooth" });
    }
    if (blog.id) {
      blog.body.blocks && blog.croppedImage && blog.mainImages.length > 0 && dispatch(editBlogRequest(serialisedBlog, blog.id));
    } else {
      blog.body.blocks && blog.croppedImage && blog.mainImages.length > 0 && dispatch(addBlogRequest(serialisedBlog));
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 300, behavior: "smooth" });
  };

  const options = [
    { title: "Unsere Zentralen Themen", value: "CENTRAL" },
    { title: "Für Sie", value: "FOR_YOU" },
    { title: "Digital", value: "DIGITAL" },
    { title: "Innovativ", value: "INNOVATIVE" },
  ];

  return (
    <section className={`${classes.innerFormSection} container_admin`}>
      <Typography
        align="center"
        variant="h2"
        className={classes.innerFormHeader}
      >
        Blogs erstellen und bearbeiten
      </Typography>
      <Typography align="center" className={classes.innerFormDescription}>
        {blog.id ? `Bearbeiten Sie Blog #${blog.id}` : "Neue Blog-Erstellung"}
      </Typography>
      <ValidatorForm
        noValidate={false}
        onSubmit={() => submitForm()}
        onError={() => scrollToTop()}
        autoComplete="off"
        className={classes.innerForm}
      >
        <TextValidator
          label="Titel"
          variant="outlined"
          name="title"
          value={blog.title}
          onChange={(e) => handleFieldChange(e)}
          className={classes.innerFormField}
          inputProps={{ maxLength: 150 }}
          validators={["required"]}
          errorMessages={["Dieses Feld wird benötigt"]}
        />

        <TextValidator
          label="Pretty url"
          variant="outlined"
          name="prettyUrl"
          value={blog.prettyUrl}
          onChange={(e) => handleFieldChange(e)}
          className={classes.innerFormField}
          inputProps={{ maxLength: 150 }}
          validators={["required", "matchRegexp:^[a-zA-Z0-9-]*$"]}
          errorMessages={["Dieses Feld wird benötigt", "Only letters, numbers, dash"]}
        />

        <TextValidator
          label="Key words"
          variant="outlined"
          name="keyWords"
          value={blog.keyWords}
          onChange={(e) => handleFieldChange(e)}
          className={classes.innerFormField}
          inputProps={{ maxLength: 150 }}
          validators={["required"]}
          errorMessages={["Dieses Feld wird benötigt"]}
        />

        <TextValidator
          label="Beschreibung"
          variant="outlined"
          name="description"
          value={blog.description}
          onChange={(e) => handleFieldChange(e)}
          className={`${classes.innerFormField} ${classes.innerFormTextarea}`}
          multiline
          rows={5}
          rowsMax={5}
          inputProps={{ maxLength: 300 }}
          validators={["required"]}
          errorMessages={["Dieses Feld wird benötigt"]}
        />

        <SelectContentType selectedOption={blog.type} selectOption={editBlogField} options={options} />
        <BlogMainImageType type={blog.mainImageType} mainImages={blog.mainImages} />

        {blog.mainImageType === "IMAGE"
          ? <BlogMainImageUpload />
          : <TextValidator
            label="Video url"
            variant="outlined"
            // name="videoUrl"
            value={blog.mainImages[0]}
            onChange={(e) => handleVideoFieldChange(e)}
            className={classes.innerFormField}
            inputProps={{ maxLength: 150 }}
            validators={["required"]}
            errorMessages={["Dieses Feld wird benötigt"]}
          />
        }
        <BlogCroppedImageUpload />
        <BlogBodyEditor />

        <div className={classes.innerFormSubmitBlock}>
          {actionLoading ? (
            <Preloader />
          ) : (
            <Button variant="contained" className={classes.innerFormSubmitBtn} type="submit">
              Speichern
            </Button>
          )}
        </div>
      </ValidatorForm>

      <Snackbar
        open={sizeAlertOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => setSizeAlertOpen(false)}
      >
        <MuiAlert elevation={6} variant="filled" onClose={() => setSizeAlertOpen(false)} severity="error">
          Blog-Inhalt über 20.000 Zeichen
        </MuiAlert>
      </Snackbar>

      <BlogSuccessModal />
    </section>
  );
};
