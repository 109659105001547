import { createMuiTheme } from "@material-ui/core/styles";
import { deDE } from "@material-ui/core/locale";

const theme = createMuiTheme(
  {
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl"],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      primary: {
        light: "#EAEAEA",
        main: "#DDDDDD",
        dark: "#D5D5D5",
        decor: "#D60B52",
        gradient: "transparent linear-gradient(90deg, #F99300 0%, #FEA407 56%, #FFD000 100%) 0% 0% no-repeat padding-box",
      },
      text: {
        primary: "#393737",
      }
    },
    spacing: 8,
    typography: {
      fontFamily: ["HelveticaNeue", "sans-serif"].join(","),
      h1: {
        fontSize: 51,
        lineHeight: "63px",
        fontWeight: 900,
        wordBreak: "break-word",
        "@media (max-width: 959px)": {
          fontSize: 28,
          lineHeight: "34px",
        },
        "@media (max-width: 599px)": {
          fontSize: 20,
          lineHeight: "25px",
        },
      },
      h2: {
        fontSize: 36,
        lineHeight: "36px",
        fontWeight: 700,
        wordBreak: "break-word",
        "@media (max-width: 959px)": {
          fontSize: 30,
          lineHeight: "30px",
        },
        "@media (max-width: 599px)": {
          fontSize: 22,
          lineHeight: "21px",
        },
      },
      h3: {
        fontSize: 20,
        lineHeight: "25px",
        fontWeight: 900,
        wordBreak: "break-word",
        "@media (max-width: 959px)": {
          fontSize: 18,
          lineHeight: "23px",
        },
        "@media (max-width: 599px)": {
          fontSize: 16,
          lineHeight: "21px",
        },
      },
      body1: {
        lineHeight: "18px",
        "@media (max-width: 959px)": {
          fontSize: 14,
          lineHeight: "17px",
        },
        "@media (max-width: 599px)": {
          fontSize: 12,
          // lineHeight: "15px",
        },
      },
      // body2: {
      //   fontSize: 18,
      //   lineHeight: "22px",
      //   fontWeight: 900,
      // },
    },

    overrides: {
      MuiButton: {
        root: {
          minWidth: 40,
          height: 56,
          lineHeight: "15px",
          fontSize: 12,
          boxShadow: "none",
          textTransform: "none",
          fontWeight: 900,
          borderRadius: 2,
          backgroundColor: "transparent",
          textDecoration: "underline",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "transparent",
          },
        },
        text: {
          padding: 0,
        },
        contained: {
          color: "#FFFFFF",
          boxShadow: "none",
          backgroundColor: "#D60B52",
          textDecoration: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "#D60B52 !important",
          },
        },
      },
      MuiInput: {
        underline: {
          "&:before": {
            borderBottom: "none",
          }
        }
      },
      MuiFilledInput: {
        root: {
          backgroundColor: "#EFEFEF",
          fontSize: 12,
          lineHeight: "15px",
          "&:hover": {
            backgroundColor: "#EFEFEF",
          },
          "&$focused": {
            backgroundColor: "#EFEFEF",
          }
        },
        underline: {
          "&:before": {
            borderBottom: "1px solid #FFFFFF",
          },
          "&:hover::before": {
            borderBottom: "1px solid #FFFFFF",
          },
          "&:after": {
            borderBottom: "2px solid #FFFFFF",
          },
        },
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: 0,
          marginRight: 0,
        }
      },
      MuiPaper: {
        elevation1: {
          boxShadow: "none",
        },
      },
      MuiAutocomplete: {
        paper: {
          boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        },
        inputRoot: {
          "& .MuiAutocomplete-input": {
            padding: "6px 0 !important",
          }
        },
        input: {
          textAlign: "left",
        }
      },
      MuiAccordion: {
        root: {
          width: 320,
          "&:before": {
            display: "none",
          },
          "&$expanded": {
            margin: 0,
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          justifyContent: "space-between",
          backgroundColor: "#EFEFEF",
          minHeight: 53,
          "&$expanded": {
            minHeight: 53,
          },
        },
        content: {
          alignItems: "center",
          "&$expanded": {
            margin: "12px 0",
          },
        },
        expandIcon: {
          padding: 0,
          "&$expanded": {
            transform: "rotate(90deg)",
          },
        },
      },
      MuiAccordionDetails: {
        root: {
          flexDirection: "column",
          marginTop: 2,
          padding: "30px 16px 47px",
          backgroundColor: "#EFEFEF",
        },
      },
      MuiTablePagination: {
        root: {
          display: "flex",
          justifyContent: "center",
        },
      },
      MuiIconButton: {
        root: {
          color: "#393737",
        },
      },
      MuiToolbar: {
        gutters: {
          "@media (min-width: 600px)": {
            paddingLeft: '16px',
          },
        },
      },
      MuiTable: {
        root: {
          "& tr td:last-child div": {
            justifyContent: "center"
          }
        },
      },
      MuiCheckbox: {
        root: {
          color: "#fff",
        },
        colorSecondary: {
          "&$checked": {
            color: "#fff",
          },
        },
        colorPrimary: {
          color: "#D60B52",
          "&$checked": {
            color: "#D60B52",
          },
        }
      },
    },
  },
  deDE
);

export default theme;
