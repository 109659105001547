import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  backButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: 118,
    height: 57,
    marginBottom: "70px",
    paddingRight: "8px",
    background: theme.palette.primary.decor,
  },
}));