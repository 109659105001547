import Slider from "react-slick";

import useStyles from "./useStyles";
import { SliderButtonIcon } from "../icons/SliderButtonIcon";

/**
 * @desc function for create blog main slider at blog page
 * @param {array} mainImages - array with main images
 * @returns {JSX.Element}
 */
export const BlogMainSlider = ({ mainImages }) => {
  const classes = useStyles();
  const SampleNextArrow = props => {
    const { className, style, onClick } = props;

    return (
      <SliderButtonIcon
        className={className}
        style={{
          ...style,
          position: "absolute",
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          width: "66px",
          height: "38px",
          zIndex: "1",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = props => {
    const { className, style, onClick } = props;

    return (
      <SliderButtonIcon
        className={className}
        style={{
          ...style,
          position: "absolute",
          top: "50%",
          left: 0,
          transform: "rotate(180deg) translateY(50%)",
          width: "66px",
          height: "38px",
          zIndex: "1",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    !!mainImages?.length && (
      <Slider {...settings}>
        {mainImages.map(url => (
          <div key={url} className={classes.sliderItem}>
            <div
              className={classes.sliderImage}
              style={{
                backgroundImage: `url(${url})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                textDecoration: "none",
              }}
            >
            </div>
          </div>
        ))}
      </Slider>
    )
  );
};
