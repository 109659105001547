import { useDispatch, useSelector } from "react-redux";

import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";

import { ErrorHandler } from "../../сommon/ErrorHandler";

import { closeErrorModal } from "../../../store/actions";

import useStyles from "../useStyles";

/**
 * @desc function for create error modal window
 * @returns {JSX.Element}
 */
export const ErrorModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isModalOpen, error } = useSelector(({ errors }) => errors);

  const closeModal = () => {
    dispatch(closeErrorModal());
  };

  return (
    isModalOpen && (
      <Modal
        open={isModalOpen}
        onClose={() => closeModal()}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableScrollLock={true}
      >
        <Fade in={isModalOpen}>
          <div className={classes.paper}>
            <div className={classes.modal_wrap}>
              <ErrorIcon className={classes.modal_error_icon} />

              {!!error && <ErrorHandler error={error} />}

              <IconButton className={classes.modal_close_btn} onClick={closeModal} aria-label="close">
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
    )
  );
};
