import { ACTION } from "../constants/types";

const INIT_STATE = {
  error: null,
  isModalOpen: false,
};

export const errorsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION.ERROR_MODAL_OPEN:
      return { ...state, error: action.payload, isModalOpen: true };
    case ACTION.ERROR_MODAL_CLOSE:
      return { ...state, error: null, isModalOpen: false };

    default:
      return state;
  }
};
