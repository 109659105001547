/**
 * @desc function for making string with color from content type
 * @param {string} type - string with content type
 * @return {string} - color
 */
const getDecorColor = (type) => {
  switch (type) {
    case "FOR_YOU":
      return "#EABC3C";
    case "DIGITAL":
      return "#006B85";
    case "INNOVATIVE":
      return "#80429B";
      case "CENTRAL":
      return "#D60B52";
    default:
      return "#393737";
  };
};

export default getDecorColor;
