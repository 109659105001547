import axios from "axios";
import { put, call, all, takeLatest, select } from "redux-saga/effects";
import { ACTION } from "../constants/types";
import {
  getPrivateContentSuccess,
  getPrivateContentFailure,
  addPrivateContentSuccess,
  addPrivateContentFailure,
  downloadPrivateContentSuccess,
  downloadPrivateContentFailure,
  selectPrivateContent,
  getMainImageForMergeSuccess,
  getMainImageForMergeFailure,
  getLogosIdsSuccess,
  getLogosIdsFailure,
} from "../actions/";

/**
 * @desc request for receipt private files
 * @returns {object} list of private files and pagination
 */
export function* fetchPrivateFiles() {
  yield put({ type: ACTION.GET_PRIVATE_CONTENT_REQUEST });
  const { privateFiles: { query: queryParams } } = yield select();
  try {
    const res = yield call(axios.get, "media/content/active", { params: queryParams });
    yield put(
      getPrivateContentSuccess({
        privateFiles: res.data.content,
        page: res.data.pageable.pageNumber,
        totalCount: res.data.totalElements,
      })
    );
    yield !!res.data.content.length ? put(selectPrivateContent(res.data.content[0])) : put(selectPrivateContent({ id: null }));
  } catch (error) {
    yield put(getPrivateContentFailure(error));
  }
}

/**
 * @desc request for add private file
 * @returns {object} - params for private files
 */
export function* fetchAddPrivateFiles() {
  yield put({ type: ACTION.ADD_PRIVATE_CONTENT_REQUEST });
  const { privateFiles: { query: queryParams } } = yield select();
  try {
    const res = yield call(axios.get, "media/content/active", { params: queryParams });
    yield put(
      addPrivateContentSuccess({
        privateFiles: res.data.content,
        page: res.data.pageable.pageNumber,
        totalCount: res.data.totalElements,
      })
    );
  } catch (error) {
    yield put(addPrivateContentFailure(error));
  }
}

/**
 * @desc request for download private file
 */
export function* fetchDownloadPrivateFiles() {
  const { privateFiles: { privateFilesForDownload: filesForDownload } } = yield select();
  const idsStr = filesForDownload.map(el => `${el.id}`).join(",");
  const contentId = filesForDownload.map(el => `${el.contentId}`).join(",");
  const params = { fileId: idsStr, contentId };

  try {
    const res = yield call(axios.get, `file/file`, { responseType: "blob", params });
    yield put(downloadPrivateContentSuccess());
    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `bpi_${new Date().toLocaleString()}.zip`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    yield put(downloadPrivateContentFailure(error));
  }
}

/**
 * @desc request for receipt main image for merge
 * @return {string} - image for watermark url
 */
export function* fetchMainImageForMerge() {
  const { privateFiles: { imageForMergeId } } = yield select();
  try {
    const res = yield call(axios.get, `file/file/${imageForMergeId}/download/`, { responseType: "blob" });
    console.log(res.data);
    const url = URL.createObjectURL(new Blob([res.data]));
    yield put(getMainImageForMergeSuccess(url));
  } catch (error) {
    yield put(getMainImageForMergeFailure(error));
  }
}

/**
 * @desc request for receipt watermarks for merge
 * @return {object} - object with watermarks
 */
export function* fetchLogosIds() {
  const { privateFiles: { contentForMergeId } } = yield select();
  try {
    const res = yield call(axios.get, `media/content/${contentForMergeId}/logos/`);
    yield put(getLogosIdsSuccess(res.data));
  } catch (error) {
    yield put(getLogosIdsFailure(error));
  }
}

export function* watchPrivateFiles() {
  yield all([
    takeLatest(ACTION.SET_PRIVATE_CONTENT_QUERY, fetchPrivateFiles),
    takeLatest(ACTION.SET_ADD_PRIVATE_CONTENT_QUERY, fetchAddPrivateFiles),
    takeLatest(ACTION.DOWNLOAD_PRIVATE_CONTENT_REQUEST, fetchDownloadPrivateFiles),
    takeLatest(ACTION.GET_MAIN_IMAGE_FOR_MERGE_REQUEST, fetchMainImageForMerge),
    takeLatest(ACTION.GET_LOGOS_IDS_REQUEST, fetchLogosIds),
  ]);
}
