import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import EditorJs from "react-editor-js";
import Embed from "@editorjs/embed";
import ImageTool from "@editorjs/image";
import Header from "@editorjs/header";
import Typography from "@material-ui/core/Typography";

import { editBlogField } from "../../store/actions/";

import useStyles from "./useStyles";

/**
 * @desc function for using EditorJs
 * @returns {JSX.Element}
 */
export const BlogBodyEditor = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { blog } = useSelector(({ adminBlogs }) => adminBlogs);

  const EDITOR_JS_TOOLS = {
    header: Header,
    embed: Embed,
    image: {
      class: ImageTool,
      config: {
        uploader: {
          /**
           * Upload file to the server and return an uploaded image data
           * @param {File} file - file selected from the device or pasted by drag-n-drop
           * @return {Promise<{file: {url: string}, success: number} | void>}
           */
          uploadByFile(file) {
            let formData = new FormData();
            formData.append("file", file);
            return axios
              .post("file/file/public", formData)
              .then(res => {
                return Promise.resolve(res.data);
              })
              .catch(error => console.log(error.response));
          },
        },
      },
    },
  };

  return (
    <div className={classes.editor_block}>
      <Typography align="center" className={classes.editor_title}>
        Füllen Sie den Inhalt für das Blog
      </Typography>
      <div className={classes.editor_wrapper}>
        <EditorJs
          data={blog.body}
          tools={EDITOR_JS_TOOLS}
          onChange={(api, outputData) => dispatch(editBlogField("body", { blocks: outputData.blocks }))}
          minHeight={200}
        />
      </div>
      {!blog.body.blocks.length && (
        <Typography align="center" color="error" className={classes.editor_error}>
          Inhalt für das Blog wird benötigt
        </Typography>
      )}
    </div>
  );
};
