import { Link } from "react-router-dom";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "../icons/TwitterIcon";
import YouTubeIcon from "../icons/YouTubeIcon";

import styles from "./Footer.module.scss";
import { FooterLogoIcon } from "../icons/FooterLogoIcon";
// import { CookieConfirmation } from "../../CookieConfirmation";

/**
 * @desc function for create footer
 * @param {string} container - type of footer container
 * @returns {JSX.Element}
 */
export const Footer = ({ container = "container" }) => (
  <footer className={`${styles.footer} ${container}`}>
    {/*<CookieConfirmation />*/}
    <div className={styles.footerControls}>
      <a href="https://www.bpi.de/de" target="_blank" rel="noreferrer">
        <FooterLogoIcon className={styles.logo} />
      </a>
      <div className={styles.socialList}>
        <a
          className={styles.socialNetworks}
          href="https://www.linkedin.com/company/bundesverband-der-pharmazeutischen-industrie/?originalSubdomain=de"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon className={styles.socialLink} />
        </a>
        <a
          className={styles.socialNetworks}
          href="https://twitter.com/BPI_Pharma"
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon className={styles.socialLink} />
        </a>
        <a
          className={styles.socialNetworks}
          href="https://www.youtube.com/channel/UCdMK8NGoWys_hDuLLtxxDiw"
          target="_blank"
          rel="noreferrer"
        >
          <YouTubeIcon className={styles.socialLink} />
        </a>
      </div>
      <ul className={styles.navList}>
        <li className={styles.navItem}>
          <a href="mailto:wirwirken@bpi.de" className={styles.navLink}>wirwirken@bpi.de</a>
        </li>
        <li className={styles.navItem}>
          <Link to="/impressum" className={styles.navLink}>Impressum</Link>
        </li>
        <li className={styles.navItem}>
          <Link to="/privacy" className={styles.navLink}>Datenschutz</Link>
        </li>
      </ul>
    </div>
    <div className={styles.footerText}>
      <p className={styles.footerLocation}>
        Die Kampagne „Wir wirken“ wird vom BPI mit Unterstützung seiner rund&nbsp;
          <a
          href="https://www.bpi.de/de/bpi/organisation/mitglieder"
          target="_blank"
          rel="noreferrer"
          className={styles.footerLocationLink}
        >
          270 Mitgliedsunternehmen
          </a>&nbsp;umgesetzt.
        </p>
    </div>
  </footer>
);
