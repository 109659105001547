import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  preloaderWrap: {
    height: 49,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  preloader: {
    color: theme.palette.primary.decor,
  },
}));

export default useStyles;