import Slider from "react-slick";
import { Link } from "react-router-dom";

import getDecorColor from "../../../utils/getDecorColor";

import Typography from "@material-ui/core/Typography";
import { SliderButtonIcon } from "../icons/SliderButtonIcon";

import useStyles from "./useStyles";

/**
 * @desc function for create blog last news slider at blog page
 * @param {array} lastNews - array with last news
 * @param {string} newsType - blog type
 * @returns {JSX.Element}
 */
export const BlogSlider = ({ lastNews, newsType }) => {
  const classes = useStyles();
  const SampleNextArrow = props => {
    const { className, style, onClick } = props;

    return (
      <SliderButtonIcon
        className={className}
        style={{
          ...style,
          position: "absolute",
          top: "50%",
          right: 0,
          transform: "translateY(-50%)",
          width: "66px",
          height: "38px",
          zIndex: "1",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = props => {
    const { className, style, onClick } = props;

    return (
      <SliderButtonIcon
        className={className}
        style={{
          ...style,
          position: "absolute",
          top: "50%",
          left: 0,
          transform: "rotate(180deg) translateY(50%)",
          width: "66px",
          height: "38px",
          zIndex: "1",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    );
  };

  const getSliderClassName = () => {
    switch (lastNews.length) {
      case 3:
        return classes.slider_75;
      case 2:
        return classes.slider_50;
      case 1:
        return classes.slider_25;
      default:
        return classes.slider_100;
    }
  };

  const getSliderTabletClassName = () => {
    switch (lastNews.length) {
      case 2:
        return classes.slider_66;
      case 1:
        return classes.slider_33;
      default:
        return classes.slider_100;
    }
  };

  const settings = {
    className: getSliderClassName(),
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: lastNews.length > 3 ? 4 : lastNews.length,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          className: getSliderTabletClassName(),
          slidesToShow: lastNews.length > 2 ? 3 : lastNews.length,
        },
      },
      {
        breakpoint: 800,
        settings: {
          className: lastNews.length > 1 ? classes.slider_100 : classes.slider_50,
          slidesToShow: lastNews.length > 1 ? 2 : lastNews.length,
        },
      },
      {
        breakpoint: 480,
        settings: {
          className: classes.slider_100,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    !!lastNews.length && (
      <Slider {...settings}>
        {lastNews.map(news => (
          <div key={news.id} className={classes.sliderItem}>
            <Link
              to={`/blog/${news.prettyUrl}`}
              className={classes.sliderImage}
              style={{
                backgroundImage: `url(${news.croppedImage})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                textDecoration: "none",
              }}
            >
              <div className={classes.sliderBox}>
                <Typography component="span" className={classes.sliderText} style={{ color: getDecorColor(newsType) }}>
                  {news.title}
                </Typography>
                <Typography component="span" className={classes.sliderText} style={{ color: getDecorColor(newsType) }}>
                  Weiterlesen
                </Typography>
                <div className={classes.decor_horizontal} style={{ background: getDecorColor(newsType) }}></div>
                <div className={classes.decor_vertical} style={{ background: getDecorColor(newsType) }}></div>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    )
  );
};
