import { useDispatch, useSelector } from "react-redux";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { CheckboxIcon } from "../icons/CheckboxIcon";
import { CheckboxCheckedIcon } from "../icons/CheckboxCheckedIcon";
import { TypeHandler } from "../TypeHandler";
import { Preloader } from "../Preloader";
import getBytesFromFileSize from "../../../utils/getBytesFromFileSize";

import { setPrivateContentForDownload, downloadPrivateContentRequest } from "../../../store/actions";

import useStyles from "./useStyles";
import { LoadIcon } from "../icons/LoadIcon";

/**
 * @desc function for create image selector accordion at privat content
 * @returns {JSX.Element}
 */
export const ImageSelectorAccordion = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { privateFilesForDownload, actionLoading } = useSelector(({ privateFiles }) => privateFiles);

  const removeFileForDownload = id => {
    const filteredFiles = privateFilesForDownload.filter(file => file.id !== id);
    dispatch(setPrivateContentForDownload(filteredFiles));
  };

  const downloadPrivateFiles = () => {
    !!privateFilesForDownload.length && dispatch(downloadPrivateContentRequest());
  };

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.panel_icon} />}>
        <Typography className={classes.panel_text}>Meine Inhalte</Typography>
        <Typography className={classes.panel_counter}>{privateFilesForDownload.length}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {privateFilesForDownload.length === 0
          ? <Typography className={classes.panel_text}>Keine privaten Dateien zum Herunterladen</Typography>
          : privateFilesForDownload.map(file => (
            <div key={file.id} className={classes.details_item}>
              <div
                className={classes.details_item_img}
                style={{ backgroundImage: file.mainImages.length > 0 ? `url(${file.mainImages[0]})` : "url(img/mockup.jpg)" }}
              />
              <div className={classes.details_item_text}>
                <Typography noWrap variant="body2">
                  {file.title}
                </Typography>
                <Typography noWrap variant="body2">
                  <TypeHandler type={file.type} />
                </Typography>
                <Typography variant="body2">{getBytesFromFileSize(file.contentLength)}</Typography>
              </div>
              <Checkbox
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxCheckedIcon />}
                className={classes.details_item_checkbox}
                onChange={() => removeFileForDownload(file.id)}
                checked={privateFilesForDownload.includes(file)}
              />
            </div>
          ))}
      </AccordionDetails>
      <AccordionDetails className={classes.details_load}>
        <Typography variant="body2" className={classes.details_load_text}>
          Ausgewählte Dateien herunterladen
        </Typography>
        {actionLoading ? (
          <Preloader className={classes.details_load_preloader} size={30} />
        ) : (
          <IconButton onClick={downloadPrivateFiles} className={classes.details_load_btn}>
            <LoadIcon className={classes.details_load_icon} />
          </IconButton>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
