import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  privateMenuSection: {
    display: "flex",
    marginBottom: 20,
  },
  handlePrivateMenuBtn: {
    height: 72,
    width: 106,
    display: "flex",
    borderRadius: 0,
    justifyContent: "flex-end",
    padding: "10px 20px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 16px",
      height: 56,
      width: 86,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "6px 10px",
      height: 34,
      width: 56,
    },
  },
  handlePrivateMenuIcon: {
    fontSize: 32,
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
    },
  },
  privateMenuList: {
    height: "95vh",
    minHeight: 500,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  privateMenuText: {
    fontSize: 16,
    color: "#393737",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  privateMenuLinkIcon: {
    color: theme.palette.primary.decor,
  },
  privateMenuLink: {
    textDecoration: "none",
  },
  privateMenuEmail: {
    fontSize: 16,
    width: 250,
    color: "#000000",
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      width: 220,
    },
  }
}));
