import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AttachmentIcon from "@material-ui/icons/Attachment";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import UploadValidator from "../../сommon/UploadValidator";

import { editPrivateFileField } from "../../../store/actions";

import useStyles from "../useStyles";
import getBytesFromFileSize from "../../../utils/getBytesFromFileSize";

/**
 * @desc function for create private file for upload
 * @returns {JSX.Element}
 */
export const PrivateFileUpload = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { file } = useSelector(({ adminFiles }) => adminFiles);
  const [sizeAlertOpen, setSizeAlertOpen] = useState(false);

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > 1024 * 1024 * 10) {
        setSizeAlertOpen(true);
        return;
      }
      dispatch(editPrivateFileField("mainFile", file));
    }
  };

  const removeFile = () => {
    dispatch(editPrivateFileField("mainFile", { size: null }));
  };

  const closeAlert = () => setSizeAlertOpen(false);

  return (
    <div className={classes.upload_block}>
      <Typography align="center" className={classes.upload_title}>
        Wählen Sie die Hauptdatei
      </Typography>

      <UploadValidator
        text="Datei hochladen"
        accept="*/*"
        onChange={onSelectFile}
        validators={["required"]}
        errorMessages={["Dieses Feld wird benötigt"]}
        value={file.mainFile.size}
        classNames={classes}
        startIcon={<AttachmentIcon />}
      />

      {file.mainFile.size && (
        <div className={classes.upload_close_block}>
          <Typography noWrap align="center" className={classes.upload_close_block_text}>
            {file.mainFile.name ? "Neue Hauptdatei " : "Alte Hauptdatei "}
            {`${getBytesFromFileSize(file.mainFile.size)}.`}
            {file.mainFile.name ? ` Name: ${file.mainFile.name}` : ""}
          </Typography>
          <IconButton onClick={removeFile} className={classes.upload_close_block_btn}>
            <CloseIcon />
          </IconButton>
        </div>
      )}

      <Snackbar
        open={sizeAlertOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => setSizeAlertOpen(false)}
      >
        <MuiAlert elevation={6} variant="filled" onClose={closeAlert} severity="error">
          Dateigröße größer als 10 MB
        </MuiAlert>
      </Snackbar>
    </div>
  );
};
