import { ValidatorComponent } from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

class UploadValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      value,
      text,
      accept,
      onChange,
      classNames,
      validatorListener,
      startIcon,
      ...rest
    } = this.props;

    return (
      <div className={classNames.upload_validator_wrap}>
        <Button
          {...rest}
          ref={r => {
            this.input = r;
          }}
          component="label"
          className={classNames.upload_btn}
          startIcon={startIcon}
        >
          {text}
          <input type="file" accept={accept} onChange={onChange} hidden />
        </Button>
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <Typography color="error" style={{ fontSize: 12, margin: "0 auto" }}>
        {this.getErrorMessage()}
      </Typography>
    );
  }
}

export default UploadValidator;
