import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  impressum_section: {
    margin: "65px auto 50px",
    paddingLeft: 243,
    [theme.breakpoints.down("959")]: {
      margin: "65px auto 40px",
      paddingLeft: 80,
    },
    [theme.breakpoints.down("599")]: {
      margin: "50px auto 12px",
      padding: "0 10px 0 37px",
    },
  },
  impressum_block: {
    marginBottom: 20,
  },
  impressum_title: {
    marginBottom: 20,
    paddingRight: 140,
    [theme.breakpoints.down("959")]: {
      paddingRight: 80,
    },
    [theme.breakpoints.down("599")]: {
      paddingRight: 37,
    },
  },
  impressum_row: {
    display: "flex",
    marginBottom: 10,
  },
  impressum_left_column: {
    width: "30%",
    marginRight: 10,
    [theme.breakpoints.down("599")]: {
      width: "20%",
    },
  },
  impressum_paragraph: {
    wordWrap: "break-word",
  },
  impressum_link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    transition: "all 0.2s",
    "&:hover": {
      color: "#D5D5D5",
    }
  },
  impressum_text_section: {
    paddingRight: 140,
    textAlign: "justify",
    "&:not(:last-child)": {
      marginBottom: 10,
    },
    [theme.breakpoints.down("959")]: {
      paddingRight: 80,
    },
    [theme.breakpoints.down("599")]: {
      paddingRight: 37,
    },
  },
  impressum_decor: {
    color: theme.palette.primary.decor,
  }
}));

export default useStyles;
