import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  privacy_section: {
    margin: "65px auto 50px",
    paddingLeft: 243,
    [theme.breakpoints.down("959")]: {
      margin: "65px auto 40px",
      paddingLeft: 80,
    },
    [theme.breakpoints.down("599")]: {
      margin: "50px auto 12px",
      padding: "0 10px 0 37px",
    },
  },
  privacy_header: {
    color: "#4b7ab2",
    marginBottom: 20,
  },
  privacy_block: {
    "&:not(:last-child)": {
      marginBottom: 20,
    },
  },
  privacy_row: {
    "&:not(:last-child)": {
      marginBottom: 10,
    },
  },
  privacy_paragraph: {
    lineHeight: "30px",
    paddingRight: 140,
    textAlign: "justify",
    wordWrap: "break-word",
    [theme.breakpoints.down("959")]: {
      paddingRight: 80,
    },
    [theme.breakpoints.down("599")]: {
      paddingRight: 0,
    },
  },
  privacy_list: {
    marginLeft: 100,
    listStyleType: "disc",
    [theme.breakpoints.down("1199")]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down("599")]: {
      marginLeft: 25,
    },
  },
  privacy_list_item: {
    paddingLeft: 10,
    wordWrap: "break-word",
    lineHeight: "30px",
    [theme.breakpoints.down("959")]: {
      fontSize: 14,
      lineHeight: "28px",
    },
    [theme.breakpoints.down("599")]: {
      fontSize: 12,
      lineHeight: "26px",
    },
  },
  privacy_ordered_list: {
    marginLeft: 40,
    [theme.breakpoints.down("959")]: {
      marginLeft: 35,
    },
    [theme.breakpoints.down("599")]: {
      marginLeft: 20,
    },
  },
  privacy_ordered_title: {
    marginBottom: 20,
    paddingRight: 140,
    color: "#4b7ab2",
    fontSize: 36,
    lineHeight: "36px",
    fontWeight: 700,
    wordWrap: "break-word",
    [theme.breakpoints.down("959")]: {
      paddingRight: 80,
      fontSize: 30,
      lineHeight: "30px",
    },
    [theme.breakpoints.down("599")]: {
      paddingRight: 37,
      fontSize: 22,
      lineHeight: "21px",
    },
  },
  privacy_ordered_item: {
    "&:not(:last-child)": {
      marginBottom: 20,
    },
    "&::marker": {
      color: "#4b7ab2",
      fontSize: 36,
      lineHeight: "36px",
      fontWeight: 700,
      "@media (max-width: 959px)": {
        fontSize: 30,
        lineHeight: "30px",
      },
      "@media (max-width: 599px)": {
        fontSize: 22,
        lineHeight: "21px",
      },
    }
  },
  privacy_ordered_paragraph: {
    marginLeft: -40,
    [theme.breakpoints.down("959")]: {
      marginLeft: -35,
    },
    [theme.breakpoints.down("599")]: {
      marginLeft: -20,
    },
  },
  privacy_link: {
    color: "#4b7ab2",
    textDecoration: "none",
  },
  privacy_link_red: {
    color: "#d51130",
  }
}));

export default useStyles;
