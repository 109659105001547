import Button from "@material-ui/core/Button";
import useStyles from "./useStyles";

/**
 * @desc function for create button for add blogs
 * @param {function} onClick - add new blogs
 * @param {string} color - button color
 * @returns {JSX.Element}
 */
export const AddContentButton = ({ onClick, color = "#393737" }) => {
  const classes = useStyles();
  return (
    <Button onClick={onClick} className={classes.add_blogs_btn}>
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.25 0.727051V14.9771H0V24.4771H14.25V38.7271H23.75V24.4771H38V14.9771H23.75V0.727051H14.25Z" fill={color} />
      </svg>
    </Button>
  );
};
