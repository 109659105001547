/**
 * @desc function for making string with bytes from bytes number
 * @param {number} bytes - number of bytes
 * @param {number} decimals - number of decimals
 * @return {string} - bytes
 */
const getBytesFromFileSize = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export default getBytesFromFileSize;
