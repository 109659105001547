import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  slider_100: {
    width: "100%",
  },
  slider_75: {
    width: "75%",
  },
  slider_50: {
    width: "50%",
  },
  slider_25: {
    width: "25%",
  },
  slider_66: {
    width: "calc(100% * 2 / 3)",
  },
  slider_33: {
    width: "calc(100% / 3)",
  },
  sliderItem: {
    display: "flex !important",
  },
  sliderImage: {
    display: "flex",
    width: "100%",
    height: "270px",
    borderRight: "1px solid #fff",
  },
  sliderBox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignSelf: "flex-end",
    width: "100%",
    height: 67,
    padding: "17px 19px",
    background: "#F5F5F5",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      height: 50,
      padding: 10,
    },
    [theme.breakpoints.down("xs")]: {
      height: 40,
      padding: 5,
    },
  },
  sliderText: {
    display: "-webkit-box",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "16px",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textDecoration: "none",
  },
  decor_horizontal: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: 35,
    height: 3,
    [theme.breakpoints.down("sm")]: {
      width: 29,
    },
    [theme.breakpoints.down("xs")]: {
      width: 21,
      height: 2,
    },
  },
  decor_vertical: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: 3,
    height: 49,
    [theme.breakpoints.down("sm")]: {
      height: 40,
    },
    [theme.breakpoints.down("xs")]: {
      width: 2,
      height: 28,
    },
  },
}));

export default useStyles;
