import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";

import useStyles from "./useStyles";

/**
 * @desc function for create privacy page
 * @returns {JSX.Element}
 */
export const PrivacyContent = () => {
  const classes = useStyles();
  const metaDescriptionText = "Finden Sie hier unsere ausführliche Datenschutzerklärung. Bei Fragen erreichen Sie unseren Datenschutzbeauftragten unter datenschutz@bpi.de";

  return (
    <section className={`${classes.privacy_section} container`}>
      <Helmet>
        <meta name="description" content={metaDescriptionText} />
        <meta name="keywords" content="Datenschutz" />
      </Helmet>
      <Typography variant="h1" className={classes.privacy_header}>Datenschutzerklärung</Typography>
      <div className={classes.privacy_block}>
        <div className={classes.privacy_row}>
          <Typography className={classes.privacy_paragraph}>Die nachfolgende Datenschutzerklärung gliedert sich in:</Typography>
        </div>
      </div>
      <ul className={`${classes.privacy_block} ${classes.privacy_list}`}>
        <li className={classes.privacy_list_item}>Allgemeines</li>
        <li className={classes.privacy_list_item}>Angaben zur Verarbeitung personenbezogenen Daten</li>
        <li className={classes.privacy_list_item}>Weitergabe an Dritte</li>
        <li className={classes.privacy_list_item}>Datenverarbeitung in Drittländern</li>
        <li className={classes.privacy_list_item}>Einsatz von Cookies</li>
        <li className={classes.privacy_list_item}>Analyse der Websiteaktivitäten unserer Nutzer</li>
        <li className={classes.privacy_list_item}>Präsenzen in sozialen Netzwerken (Social Media)</li>
        <li className={classes.privacy_list_item}>Löschung von Daten</li>
        <li className={classes.privacy_list_item}>Betroffenenrechte</li>
        <li className={classes.privacy_list_item}>Datenschutzbeauftragter</li>
      </ul>
      <ol className={`${classes.privacy_block} ${classes.privacy_ordered_list}`}>
        <li className={classes.privacy_ordered_item}>
          <Typography variant="h2" className={classes.privacy_ordered_title}>Allgemeines</Typography>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Der Bundesverband der Pharmazeutischen Industrie e.V. freut sich über Ihren Besuch auf unserer Webseite <b><a
                href="http://wir-wirken.de"
                className={classes.privacy_link}
                target="_blank"
                rel="noreferrer"
              >
                wir-wirken.de
          </a></b> und über Ihr Interesse an unserem Verein. Wir nehmen die Sicherheit und den Schutz Ihrer uns anvertrauten personenbezogenen Daten sehr ernst und möchten, dass Sie sich beim Besuch unserer Internet-Seiten und der Nutzung unserer Angebote sicher und wohl fühlen.
              </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Es ist uns wichtig, dass Sie wissen, welche personenbezogenen Daten bei der Inanspruchnahme unserer Angebote und Leistungen erhoben werden und wie wir diese danach verwenden.
              </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Der Bundesverband der Pharmazeutischen Industrie e.V. vertritt mit seiner mehr als 65jährigen Erfahrung auf dem Gebiet der Arzneimittelforschung, -entwicklung, -zulassung, -herstellung und –vermarktung das breite Spektrum der pharmazeutischen Industrie auf nationaler und internationaler Ebene.
              </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Soweit unser Haus personenbezogene Daten verarbeitet, erfolgt dies zu den in dieser Datenschutzerklärung jeweils angegebenen Zwecken.
              </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Zudem wurden zahlreiche technische und organisatorische Maßnahmen umgesetzt, um für Sie einen möglichst lückenlosen Schutz der über diese Internetseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch besteht immer ein Restrisiko (bspw. Sicherheitslücken bei Datenübertragungen), so dass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es Ihnen frei, personenbezogene Daten auf anderen Wegen an uns zu übermitteln.
              </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DS-GVO) ist:
              </Typography>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Dr. Kai Joachimsen
              </Typography>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Hauptgeschäftsführer und Mitglied des Vorstandes
              </Typography>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Bundesverband der Pharmazeutischen Industrie e.V.
              </Typography>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Friedrichstraße 148, 10117 Berlin
              </Typography>
            <a
              href="mailto:kjoachimsen@bpi.de"
              className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph} ${classes.privacy_link}`}
            >
              kjoachimsen@bpi.de
            </a>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Unseren Datenschutzbeauftragten erreichen Sie unter:
            </Typography>
            <a
              href="mailto:datenschutz@bpi.de"
              className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph} ${classes.privacy_link}`}
            >
              datenschutz@bpi.de
            </a>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Oder unter unserer Postadresse mit dem Zusatz „Der Datenschutzbeauftragte“. Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen. Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen oder Ihre Daten für werbliche Zwecke nutzen möchten, werden wir Sie untenstehend im Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.
              </Typography>
          </div>
        </li>
        <li className={classes.privacy_ordered_item}>
          <Typography variant="h2" className={classes.privacy_ordered_title}>
            Angaben zur Verarbeitung personenbezogener Daten
          </Typography>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Aufruf unserer Webseite</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Beim Aufruf unserer Internetseiten erfassen wir die folgenden Daten und Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des Servers gespeichert. Erfasst werden
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <div className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <ul className={`${classes.privacy_block} ${classes.privacy_list}`}>
                <li className={classes.privacy_list_item}>IP-Adresse</li>
                <li className={classes.privacy_list_item}>Datum und Uhrzeit der Anfrage</li>
                <li className={classes.privacy_list_item}>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                <li className={classes.privacy_list_item}>Inhalt der Anforderung (konkrete Seite)</li>
                <li className={classes.privacy_list_item}>Zugriffsstatus/HTTP-Statuscode</li>
                <li className={classes.privacy_list_item}>jeweils übertragene Datenmenge</li>
                <li className={classes.privacy_list_item}>Website, von der die Anforderung kommt</li>
                <li className={classes.privacy_list_item}>Browser</li>
                <li className={classes.privacy_list_item}>Betriebssystem und dessen Oberfläche</li>
                <li className={classes.privacy_list_item}>Sprache und Version der Browsersoftware</li>
              </ul>
            </div>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Diese Informationen werden benötigt, um
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <div className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <ul className={`${classes.privacy_block} ${classes.privacy_list}`}>
                <li className={classes.privacy_list_item}>die Inhalte unserer Internetseite korrekt auszuliefern</li>
                <li className={classes.privacy_list_item}>
                  die Inhalte unserer Internetseite sowie die Werbung für diese zu optimieren
                </li>
                <li className={classes.privacy_list_item}>
                  die dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme und der Technik unserer Internetseite zu gewährleisten sowie
                </li>
                <li className={classes.privacy_list_item}>
                  um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen bereitzustellen
                </li>
              </ul>
            </div>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              "Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von cookies ist Art. 6 Abs. 1 S.1 lit. f) DS-GVO."
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Diese unter Pseudonym erhobenen Daten und Informationen werden statistisch zu eigenen Zwecken und ferner mit dem Ziel ausgewertet, den Datenschutz und die Datensicherheit in unserem Hause zu erhöhen.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Sonstige Zwecke (Anfragen und Wir Wirken-Mitgliederbereich)</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Personenbezogene Daten werden ferner verarbeitet, wenn Sie uns diese von sich aus angeben, zum Beispiel im Rahmen einer Anfrage oder bei Anmeldung/ Registrierung im Mitgliederbereich. Rechtsgrundlage ist Art. 6 Abs. 1 lit. a DS-GVO.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Mit Ihrer Registrierung für den Mitgliederbereich des wir-wirken-Internetauftritts willigen Sie in die Verarbeitung und Speicherung der von Ihnen angegebenen Daten ein. Die Datenverarbeitung erfolgt zum Zweck der Verwaltung und Betreuung Ihres Zugangs. Die Speicherung Ihrer Daten erfolgt für die Dauer Ihrer Registrierung für den <b>Wir Wirken</b>-Mitgliederbereich.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Sofern Sie in die Verarbeitung personenbezogener Daten eingewilligt haben, können Sie Ihre Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.
            </Typography>
          </div>
        </li>
        <li className={classes.privacy_ordered_item}>
          <Typography variant="h2" className={classes.privacy_ordered_title}>
            Weitergabe an Dritte
          </Typography>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Die von Ihnen eingegebenen personenbezogenen Daten werden ausschließlich für die interne Verwendung bei dem für die Verarbeitung Verantwortlichen und für eigene Zwecke erhoben und gespeichert. Der für die Verarbeitung Verantwortliche kann die Weitergabe an einen oder mehrere Auftragsverarbeiter veranlassen, der die personenbezogenen Daten ebenfalls ausschließlich für eine interne Verwendung, die dem für die Verarbeitung Verantwortlichen zuzurechnen ist, nutzt. Die Einbindung von Auftragsverarbeitern erfolgt auf Grundlage von Art. 28 Abs. 1 DSGVO.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Ein Verkauf Ihrer Daten an Dritte und/oder Weitergabe der Daten zwecks Vermarktung wird hiermit ausgeschlossen.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Ihre personenbezogenen Daten können ohne Ihre ausdrückliche Einwilligung an die Strafverfolgungsbehörden sowie gegebenenfalls an geschädigte Dritte weitergeleitet werden, wenn es zur Aufklärung einer rechtswidrigen Nutzung unserer Dienste oder für die Rechtsverfolgung erforderlich ist. Dies geschieht jedoch nur dann, wenn konkrete Anhaltspunkte für ein gesetzwidriges beziehungsweise missbräuchliches Verhalten vorliegen. Eine Weitergabe kann auch dann stattfinden, wenn dies der Durchsetzung von Nutzungsbedingungen oder anderer Vereinbarungen dient. Wir sind zudem gesetzlich verpflichtet, auf Anfrage bestimmten öffentlichen Stellen Auskunft zu erteilen. Dies sind Strafverfolgungsbehörden, Behörden, die bußgeldbewehrte Ordnungswidrigkeiten verfolgen und die Finanzbehörden.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Die Weitergabe dieser Daten erfolgt auf Grundlage unseres berechtigten Interesses an der Bekämpfung von Missbrauch, der Verfolgung von Straftaten und der Sicherung, Geltendmachung und Durchsetzung von Ansprüchen sofern Ihre Rechte und Interessen am Schutz Ihrer personenbezogenen Daten nicht überwiegen, Art. 6 Abs. 1 lit. f DS-GVO.
            </Typography>
          </div>
        </li>
        <li className={classes.privacy_ordered_item}>
          <Typography variant="h2" className={classes.privacy_ordered_title}>
            Datenverarbeitung in Drittländern
          </Typography>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben. Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher Übermittlung verarbeiten oder lassen wir die Daten nur in Drittländern mit einem anerkannten Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission, beim Vorliegen von Zertifizierungen oder verbindlicher internen Datenschutzvorschriften verarbeiten  (Art. 44 bis 49 DSGVO, Informationsseite der EU-Kommission: <a
                href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
                className={classes.privacy_link}
                target="_blank"
                rel="noreferrer"
              >
                https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
              </a>).
            </Typography>
          </div>
        </li>
        <li className={classes.privacy_ordered_item}>
          <Typography variant="h2" className={classes.privacy_ordered_title}>
            Einsatz von Cookies
          </Typography>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Cookies sind Textdateien, die Daten von besuchten Websites oder Domains enthalten und von einem Browser auf dem Computer des Benutzers gespeichert werden. Ein Cookie dient in erster Linie dazu, die Informationen über einen Benutzer während oder nach seinem Besuch innerhalb eines Onlineangebotes zu speichern. Zu den gespeicherten Angaben können z.B. die Spracheinstellungen auf einer Webseite, der Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut wurde, gehören. Zu dem Begriff der Cookies zählen wir ferner andere Technologien, die die gleichen Funktionen wie Cookies erfüllen (z.B., wenn Angaben der Nutzer anhand pseudonymer Onlinekennzeichnungen gespeichert werden, auch als "Nutzer-IDs" bezeichnet)
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Die folgenden Cookie-Typen und Funktionen werden unterschieden:</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <div className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <ul className={`${classes.privacy_block} ${classes.privacy_list}`}>
                <li className={classes.privacy_list_item}><b>Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):</b> Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot verlassen und seinen Browser geschlossen hat.</li>
                <li className={classes.privacy_list_item}><b>Permanente Cookies:</b> Permanente Cookies bleiben auch nach dem Schließen des Browsers gespeichert. So kann beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht. Ebenso können die Interessen von Nutzern, die zur Reichweitenmessung oder zu Marketingzwecken verwendet werden, in einem solchen Cookie gespeichert werden.</li>
                <li className={classes.privacy_list_item}><b>First-Party-Cookies:</b> First-Party-Cookies werden von uns selbst gesetzt.</li>
                <li className={classes.privacy_list_item}><b>Third-Party-Cookies (auch: Drittanbieter-Cookies):</b> Drittanbieter-Cookies werden hauptsächlich von Werbetreibenden (sog. Dritten) verwendet, um Benutzerinformationen zu verarbeiten.</li>
                <li className={classes.privacy_list_item}><b>Notwendige (auch: essentielle oder unbedingt erforderliche) Cookies:</b> Cookies können zum einen für den Betrieb einer Webseite unbedingt erforderlich sein (z.B. um Logins oder andere Nutzereingaben zu speichern oder aus Gründen der Sicherheit).</li>
                <li className={classes.privacy_list_item}><b>Statistik-, Marketing- und Personalisierungs-Cookies:</b> Ferner werden Cookies im Regelfall auch im Rahmen der Reichweitenmessung eingesetzt sowie dann, wenn die Interessen eines Nutzers oder sein Verhalten (z.B. Betrachten bestimmter Inhalte, Nutzen von Funktionen etc.) auf einzelnen Webseiten in einem Nutzerprofil gespeichert werden. Solche Profile dienen dazu, den Nutzern z.B. Inhalte anzuzeigen, die ihren potentiellen Interessen entsprechen. Dieses Verfahren wird auch als "Tracking", d.h., Nachverfolgung der potentiellen Interessen der Nutzer bezeichnet. Soweit wir Cookies oder "Tracking"-Technologien einsetzen, informieren wir Sie gesondert in unserer Datenschutzerklärung oder im Rahmen der Einholung einer Einwilligung.</li>
              </ul>
            </div>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Hinweise zu Rechtsgrundlagen:</b> Auf welcher Rechtsgrundlage wir Ihre personenbezogenen Daten mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir Sie um eine Einwilligung bitten. Falls dies zutrifft und Sie in die Nutzung von Cookies einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und dessen Verbesserung) verarbeitet oder, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Speicherdauer:</b> Sofern wir Ihnen keine expliziten Angaben zur Speicherdauer von permanenten Cookies mitteilen (z. B. im Rahmen eines sog. Cookie-Opt-Ins), gehen Sie bitte davon aus, dass die Speicherdauer bis zu zwei Jahre betragen kann.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out):</b> Abhängig davon, ob die Verarbeitung auf Grundlage einer Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die Möglichkeit, eine erteilte Einwilligung zu widerrufen oder der Verarbeitung Ihrer Daten durch Cookie-Technologien zu widersprechen (zusammenfassend als "Opt-Out" bezeichnet). Sie können Ihren Widerspruch zunächst mittels der Einstellungen Ihres Browsers erklären, z.B., indem Sie die Nutzung von Cookies deaktivieren (wobei hierdurch auch die Funktionsfähigkeit unseres Onlineangebotes eingeschränkt werden kann). Ein Widerspruch gegen den Einsatz von Cookies zu Zwecken des Onlinemarketings kann auch mittels einer Vielzahl von Diensten, vor allem im Fall des Trackings, über die Webseiten <a
                href="https://optout.aboutads.info"
                className={classes.privacy_link}
                target="_blank"
                rel="noreferrer"
              >
                https://optout.aboutads.info
                </a> und <a
                href="https://www.youronlinechoices.com"
                className={classes.privacy_link}
                target="_blank"
                rel="noreferrer"
              >
                https://www.youronlinechoices.com
                </a> erklärt werden. Daneben können Sie weitere Widerspruchshinweise im Rahmen der Angaben zu den eingesetzten Dienstleistern und Cookies erhalten.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:</b> Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies, bzw. der im Rahmen des Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen und Anbieter eingeholt sowie von den Nutzern verwaltet und widerrufen werden können. Hierbei wird die Einwilligungserklärung gespeichert, um deren Abfrage nicht erneut wiederholen zum müssen und die Einwilligung entsprechend der gesetzlichen Verpflichtung nachweisen zu können. Die Speicherung kann serverseitig und/oder in einem Cookie (sogenanntes Opt-In-Cookie, bzw. mithilfe vergleichbarer Technologien) erfolgen, um die Einwilligung einem Nutzer, bzw. dessen Gerät zuordnen zu können. Vorbehaltlich individueller Angaben zu den Anbietern von Cookie-Management-Diensten, gelten die folgenden Hinweise: Die Dauer der Speicherung der Einwilligung kann bis zu zwei Jahren betragen. Hierbei wird ein pseudonymer Nutzer-Identifikator gebildet und mit dem Zeitpunkt der Einwilligung, Angaben zur Reichweite der Einwilligung (z. B. welche Kategorien von Cookies und/oder Diensteanbieter) sowie dem Browser, System und verwendeten Endgerät gespeichert.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <div className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <ul className={`${classes.privacy_block} ${classes.privacy_list}`}>
                <li className={classes.privacy_list_item}>
                  <b>Verarbeitete Datenarten:</b> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                </li>
                <li className={classes.privacy_list_item}>
                  <b>Betroffene Personen:</b> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li className={classes.privacy_list_item}>
                  <b>Rechtsgrundlagen:</b> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li className={classes.privacy_ordered_item}>
          <Typography variant="h2" className={classes.privacy_ordered_title}>
            Analyse der Websiteaktivitäten unserer Nutzer
          </Typography>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Um diese Web-Site besser an die Bedürfnisse unserer User anzupassen, analysieren wir die Besuche auf unserer Web-Site. Wir nutzen hierbei das Analysetool Matomo Analytics. Eine Weitergabe an Dritte findet nicht statt."Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Matomo Analytics ist Art. 6 Abs. 1 S.1 lit. f) DS-GVO."
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Matomo Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Web-Site ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Web-Site (einschließlich Ihrer IP-Adresse) werden in der Regel lokal auf dem Server gespeichert.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Auf dieser Web-Site wurde die IP-Anonymisierung aktiviert, so dass die IP-Adresse der Nutzer von Matomo innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt wird. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Web-Site vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Web-Site bezogenen Daten (inkl. Ihrer IP-Adresse), sowie die Verarbeitung dieser Daten durch Matomo verhindern, indem Sie das Do-Not-Track-Merkmal in Ihrem Browser aktivieren.
            </Typography>
          </div>
        </li>
        <li className={classes.privacy_ordered_item}>
          <Typography variant="h2" className={classes.privacy_ordered_title}>
            Präsenzen in sozialen Netzwerken (Social Media)
          </Typography>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten in diesem Rahmen Daten der Nutzer, um mit den dort aktiven Nutzern zu kommunizieren oder um Informationen über uns anzubieten.Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union verarbeitet werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer erschwert werden könnte.Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können z.B. anhand des Nutzungsverhaltens und sich daraus ergebender Interessen der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert werden (insbesondere, wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der Widerspruchsmöglichkeiten (Opt-Out) verweisen wir auf die Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann können Sie sich an uns wenden.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <div className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <ul className={`${classes.privacy_block} ${classes.privacy_list}`}>
                <li className={classes.privacy_list_item}>
                  <b>Verarbeitete Datenarten:</b> Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B. Eingaben in Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
                </li>
                <li className={classes.privacy_list_item}>
                  <b>Betroffene Personen:</b> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li className={classes.privacy_list_item}>
                  <b>Zwecke der Verarbeitung:</b> Kontaktanfragen und Kommunikation, Feedback (z.B. Sammeln von Feedback via Online-Formular), Marketing.
                </li>
                <li className={classes.privacy_list_item}>
                  <b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                </li>
              </ul>
            </div>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Eingesetzte Dienste und Diensteanbieter:</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>LinkedIn:</b> Soziales Netzwerk; Dienstanbieter: LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland; Website: <a
                href="https://www.linkedin.com"
                className={classes.privacy_link}
                target="_blank"
                rel="noreferrer">
                https://www.linkedin.com
                </a>; Datenschutzerklärung: <a
                href="https://www.linkedin.com/legal/privacy-policy"
                className={classes.privacy_link}
                target="_blank"
                rel="noreferrer">
                https://www.linkedin.com/legal/privacy-policy
                </a>; Widerspruchsmöglichkeit (Opt-Out): <a
                href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                className={classes.privacy_link}
                target="_blank"
                rel="noreferrer">
                https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
                </a>.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Twitter:</b> Soziales Netzwerk; Dienstanbieter: Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2 D02 AX07, Irland, Mutterunternehmen: Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA; Datenschutzerklärung: <a
                href="https://twitter.com/de/privacy"
                className={classes.privacy_link}
                target="_blank"
                rel="noreferrer">
                https://twitter.com/de/privacy
                </a>, (Einstellungen) <a
                href="https://twitter.com/personalization"
                className={classes.privacy_link}
                target="_blank"
                rel="noreferrer">
                https://twitter.com/personalization
                </a>.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>YouTube:</b> Soziales Netzwerk und Videoplattform; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Datenschutzerklärung: <a
                href="https://policies.google.com/privacy"
                className={classes.privacy_link}
                target="_blank"
                rel="noreferrer">
                https://policies.google.com/privacy
                </a>; Widerspruchsmöglichkeit (Opt-Out): <a
                href="https://adssettings.google.com/authenticated"
                className={classes.privacy_link}
                target="_blank"
                rel="noreferrer">
                https://adssettings.google.com/authenticated
                </a>.
            </Typography>
          </div>
        </li>
        <li className={classes.privacy_ordered_item}>
          <Typography variant="h2" className={classes.privacy_ordered_title}>
            Löschung von Daten
          </Typography>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die Daten gelöscht oder die Verarbeitung eingeschränkt, wenn sie für die im Rahmen dieser Datenschutzerklärung genannten Zwecke nicht mehr erforderlich sind und der Löschung keine gesetzlichen Anforderungen entgegenstehen. Sofern diese Datenschutzerklärung keine anderen, abweichenden Bestimmungen hinsichtlich der Speicherung von Daten enthält, werden die von uns erhobenen Daten von uns so lange gespeichert, wie sie für die vorstehenden Zwecke, für die sie erhoben worden sind, erforderlich sind.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Missbrauchserkennung und -verfolgung</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Informationen zur Missbrauchserkennung und -verfolgung, insbesondere Ihre IP-Adresse, halten wir maximal 7 Tage vor. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. f DS-GVO.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Unser berechtigtes Interesse an der Datenvorhaltung von 7 Tagen liegt dabei darin, das ordnungsgemäße Funktionieren unserer Web-Site und der darüber abgewickelten Geschäfte sicherzustellen sowie Cyber-Attacken u. ä. abwehren zu können.
            </Typography>
          </div>
        </li>
        <li className={classes.privacy_ordered_item}>
          <Typography variant="h2" className={classes.privacy_ordered_title}>
            Betroffenenrechte
          </Typography>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Auskunftsrecht</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Sie haben das Recht, von uns jederzeit auf Antrag eine Auskunft über die von uns verarbeiteten, Sie betreffenden personenbezogenen Daten im Umfang des Art. 15 DS-GVO zu erhalten. Hierzu können Sie einen Antrag postalisch oder per E-Mail an die unten angegebenen Adressen stellen. Den Text des Art. 15 DS-GVO finden Sie hier:
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <a
                href="http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&qid=1474615617790"
                className={`${classes.privacy_link} ${classes.privacy_link_red}`}
                target="_blank"
                rel="noreferrer"
              >
                http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&qid=1474615617790
            </a>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Recht zur Berichtigung unrichtiger Daten</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Sie haben das Recht, von uns die unverzügliche Berichtigung der Sie betreffenden personenbezogenen Daten zu verlangen, sofern diese unrichtig sein sollten,Art. 16 DS-GVO. Wenden Sie sich hierfür bitte an die unten angegebenen Kontaktadressen. Den Text des Art. 16 DS-GVO finden Sie hier:
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <a
                href="http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&qid=1474615617790"
                className={`${classes.privacy_link} ${classes.privacy_link_red}`}
                target="_blank"
                rel="noreferrer"
              >
                http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&qid=1474615617790
            </a>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Recht auf Löschung</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Sie haben ein Recht auf unverzügliche Löschung („Recht auf Vergessenwerden“) der Sie betreffenden personenbezogenen Daten beim Vorliegen der rechtlichen Gründe nach Art. 17 DS-GVO. Den Text des Art. 17 DS-GVO finden Sie hier:
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <a
                href="http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&qid=1474615617790"
                className={`${classes.privacy_link} ${classes.privacy_link_red}`}
                target="_blank"
                rel="noreferrer"
              >
                http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&qid=1474615617790
            </a>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Rechtliche Gründe liegen etwa vor, wenn die personenbezogenen Daten für die Zwecke, für die sie ursprünglich verarbeitet worden sind, nicht mehr notwendig sind oder Sie Ihre Einwilligung widerrufen haben und wenn es an einer anderweitigen Rechtsgrundlage für die Verarbeitung fehlt; die betroffene Person Widerspruch gegen die Verarbeitung einlegt (und keine vorrangigen Gründe für eine Verarbeitung vorliegen – dies gilt nicht bei Widersprüchen gegen Direktwerbung). Um Ihr vorstehendes Recht geltend zu machen, wenden Sie sich bitte an die unten angegebenen Kontaktadressen.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Recht auf Einschränkung der Verarbeitung</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Sie haben ein Recht auf Einschränkung der Verarbeitung beim Vorliegen der Voraussetzungen und nach Maßgabe von Art. 18 DS-GVO. Den Text des Art. 18 DS-GVO finden Sie hier:
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <a
                href="http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&qid=1474615617790"
                className={`${classes.privacy_link} ${classes.privacy_link_red}`}
                target="_blank"
                rel="noreferrer"
              >
                http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&qid=1474615617790
            </a>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Danach kann die Einschränkung der Verarbeitung insbesondere geboten sein, wenn die Verarbeitung unrechtmäßig ist und die betroffene Person die Löschung der personenbezogenen Daten ablehnt und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt oder die betroffene Person Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DS-GVO eingelegt hat, solange noch nicht feststeht, ob unsere berechtigten Gründe gegenüber ihren überwiegen. Um Ihr vorstehendes Recht geltend zu machen, wenden Sie sich bitte an die unten angegebenen Kontaktadressen.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Recht auf Datenübertragbarkeit</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Sie haben ein Recht auf Datenübertragbarkeit nach Art. 20 DSGVO. Den Text des Art. 20  DS-GVO finden Sie hier:
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <a
                href="http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&qid=1474615617790"
                className={`${classes.privacy_link} ${classes.privacy_link_red}`}
                target="_blank"
                rel="noreferrer"
              >
                http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&qid=1474615617790
            </a>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Sie haben hierbei das Recht, die Sie betreffenden Daten, die Sie uns bereitgestellt haben, in einem gängigen, strukturierten und maschinenlesbaren Format zu erhalten und diese Daten an einen anderen Verantwortlichen, etwa einen anderen Dienstleister, zu übergeben. Voraussetzung ist hierfür, dass die Verarbeitung auf einer Einwilligung oder auf einem Vertrag beruht und mithilfe automatisierter Verfahren erfolgt. Um Ihr vorstehendes Recht geltend zu machen, wenden Sie sich bitte an die unten angegebenen Kontaktadressen.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Widerspruchsrecht</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b><i>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die u.a. aufgrund von Art. 6 Abs. 1 lit. e oder f DS-GVO erfolgt, Widerspruch nach Art. 21 DS-GVO einzulegen. Wir werden die Verarbeitung Ihrer personenbezogenen Daten einstellen, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Um Ihr vorstehendes Recht geltend zu machen, wenden Sie sich bitte an die unten angegebenen Kontaktadressen.</i></b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Den Text des Art. 21 DS-GVO finden Sie hier:
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <a
                href="http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&qid=1474615617790"
                className={`${classes.privacy_link} ${classes.privacy_link_red}`}
                target="_blank"
                rel="noreferrer"
              >
                http://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&qid=1474615617790
            </a>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Widerrufsrecht</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen.
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Recht zur Beschwerde bei einer Aufsichtsbehörde</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Wenn Sie der Auffassung sind, dass die Verarbeitung Sie betreffender personenbezogener Daten durch uns unzulässig ist, haben Sie das Recht, sich bei der für uns zuständigen Aufsichtsbehörde zu beschweren. Die Kontaktdaten der für Sie zuständigen Landesdatenschutzbehörde können Sie abrufen unter:
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <a
                href="http://www.datenschutz-wiki.de/Aufsichtsbeh%C3%B6rden_und_Landesdatenschutzbeauftragte"
                className={`${classes.privacy_link} ${classes.privacy_link_red}`}
                target="_blank"
                rel="noreferrer"
              >
                http://www.datenschutz-wiki.de/Aufsichtsbeh%C3%B6rden_und_Landesdatenschutzbeauftragte
            </a>
            </Typography>
          </div>
        </li>
        <li className={classes.privacy_ordered_item}>
          <Typography variant="h2" className={classes.privacy_ordered_title}>
            Datenschutzbeauftragter
          </Typography>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Wenn Sie weitere Fragen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten haben, können Sie sich direkt an unseren Beauftragten für den Datenschutz wenden, der auch im Falle von Auskunftsersuchen, Anträgen oder Beschwerden zur Verfügung steht:
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Datenschutzbeauftragter:</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Roman Maczkowsky</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>m-privacy GmbH</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              <b>Berlin</b>
            </Typography>
          </div>
          <div className={classes.privacy_row}>
            <a
              href="mailto:datenschutz@bpi.de"
              className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph} ${classes.privacy_link}`}
            >
              datenschutz@bpi.de
            </a>
          </div>
          <div className={classes.privacy_row}>
            <Typography className={`${classes.privacy_paragraph} ${classes.privacy_ordered_paragraph}`}>
              Stand: April 2021
            </Typography>
          </div>
        </li>
      </ol>
    </section>
  );
}
