import { ACTION } from "../constants/types";

const INIT_STATE = {
  blogs: [],
  pagination: {
    page: 0,
    totalCount: 0,
  },
  query: {
    page: 0,
    size: 10,
    contentType: "",
    sort: "createdAt,desc",
  },
  loading: false,
  error: null,
  blog: {
    body: {
      blocks: [
        {
          type: "paragraph",
          data: {
            text: "<b>Text</b>",
          },
        },
      ],
    },
    croppedImage: "",
    description: "",
    mainImages: [],
    mainImageType: "IMAGE",
    title: "",
    type: "FOR_YOU",
    prettyUrl: "",
    keyWords: "",
  },
  mainImageUploading: false,
  mainImageUploadError: null,
  croppedImageUploading: false,
  croppedImageUploadError: null,
  actionLoading: false,
  actionError: null,
  isModalOpen: false,
  modalContent: null,
  selectedBlog: {
    id: null,
  },
  blogOrder: null,
  isSuccessModalOpen: false,
  preview: {
    id: null,
  },
};

export const adminBlogsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION.SET_ADMIN_BLOGS_QUERY:
      return { ...state, query: { ...state.query, ...action.payload } };

    case ACTION.GET_ADMIN_BLOGS_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION.GET_ADMIN_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.payload.blogs,
        pagination: { page: action.payload.page, totalCount: action.payload.totalCount },
        loading: false,
      };
    case ACTION.GET_ADMIN_BLOGS_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case ACTION.EDIT_BLOG_FIELDS:
      return { ...state, blog: { ...state.blog, ...action.payload } };

    case ACTION.CLEAN_ADMIN_BLOGS:
      return INIT_STATE;

    case ACTION.ADD_BLOG_MAIN_IMAGE_REQUEST:
      return { ...state, mainImageUploading: true, mainImageUploadError: null };
    case ACTION.ADD_BLOG_MAIN_IMAGE_SUCCESS:
      return { ...state, blog: { ...state.blog, mainImages: [...state.blog.mainImages, action.payload] }, mainImageUploading: false };
    case ACTION.ADD_BLOG_MAIN_IMAGE_FAILURE:
      return { ...state, mainImageUploadError: action.payload, mainImageUploading: false };

    case ACTION.ADD_BLOG_MAIN_VIDEO:
      return { ...state, blog: { ...state.blog, mainImages: [action.payload] } };

    case ACTION.DELETE_BLOG_MAIN_IMAGE_REQUEST:
      return { ...state, mainImageUploading: true, mainImageUploadError: null };
    case ACTION.DELETE_BLOG_MAIN_IMAGE_SUCCESS:
      return {
        ...state, blog: {
          ...state.blog,
          mainImages: [...action.payload],
        },
        mainImageUploading: false
      };
    case ACTION.DELETE_BLOG_MAIN_IMAGE_FAILURE:
      return { ...state, mainImageUploadError: action.payload, mainImageUploading: false };

    case ACTION.DELETE_BLOG_ALL_MAIN_IMAGES:
      return {
        ...state, blog: {
          ...state.blog,
          mainImages: [],
        },
        mainImageUploading: false
      };

    case ACTION.ADD_BLOG_CROPPED_IMAGE_REQUEST:
      return { ...state, croppedImageUploading: true, croppedImageUploadError: null };
    case ACTION.ADD_BLOG_CROPPED_IMAGE_SUCCESS:
      return { ...state, blog: { ...state.blog, croppedImage: action.payload }, croppedImageUploading: false };
    case ACTION.ADD_BLOG_CROPPED_IMAGE_FAILURE:
      return { ...state, croppedImageUploadError: action.payload, croppedImageUploading: false };

    case ACTION.ADD_BLOG_REQUEST:
      return { ...state, actionLoading: true, actionError: null };
    case ACTION.ADD_BLOG_SUCCESS:
      return { ...state, actionLoading: false };
    case ACTION.ADD_BLOG_FAILURE:
      return { ...state, actionError: action.payload, actionLoading: false };

    case ACTION.BLOG_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: true,
        selectedBlog: action.payload.selectedBlog,
        actionError: null,
        modalContent: action.payload.modalContent,
        blogOrder: action.payload.selectedBlog.order,
      };
    case ACTION.BLOG_MODAL_CLOSE:
      return {
        ...state,
        isModalOpen: false,
        modalContent: null,
        actionError: null,
        selectedBlog: {
          id: null,
        },
        blogOrder: null,
      };

    case ACTION.BLOG_SUCCESS_MODAL_OPEN:
      return { ...state, isSuccessModalOpen: true, selectedBlog: action.payload };
    case ACTION.BLOG_SUCCESS_MODAL_CLOSE:
      return { ...state, isSuccessModalOpen: false, selectedBlog: { id: null } };

    case ACTION.FILL_BLOG_FORM:
      return { ...state, blog: action.payload };
    case ACTION.CLEAN_BLOG_FORM:
      return {
        ...state,
        blog: {
          body: {
            blocks: [
              {
                type: "paragraph",
                data: {
                  text: "<b>Text</b>",
                },
              },
            ],
          },
          croppedImage: "",
          description: "",
          mainImages: [],
          mainImageType: "IMAGE",
          title: "",
          type: "FOR_YOU",
          prettyUrl: "",
          keyWords: "",
        },
      };

    case ACTION.EDIT_BLOG_REQUEST:
      return { ...state, actionLoading: true, actionError: null };
    case ACTION.EDIT_BLOG_SUCCESS:
      return { ...state, actionLoading: false };
    case ACTION.EDIT_BLOG_FAILURE:
      return { ...state, actionError: action.payload, actionLoading: false };

    case ACTION.DELETE_BLOG_REQUEST:
      return { ...state, actionLoading: true, actionError: null };
    case ACTION.DELETE_BLOG_SUCCESS:
      return {
        ...state,
        isModalOpen: false,
        selectedBlog: { id: null },
        blogOrder: null,
        modalContent: null,
        actionLoading: false,
      };
    case ACTION.DELETE_BLOG_FAILURE:
      return { ...state, actionError: action.payload, actionLoading: false };

    case ACTION.SET_BLOG_PREVIEW:
      return { ...state, preview: action.payload };

    case ACTION.ENABLE_ADMIN_BLOG_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION.ENABLE_ADMIN_BLOG_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case ACTION.EDIT_BLOG_ORDER:
      return { ...state, blogOrder: action.payload };
    case ACTION.EDIT_BLOG_ORDER_REQUEST:
      return { ...state, actionLoading: true, actionError: null };
    case ACTION.EDIT_BLOG_ORDER_SUCCESS:
      return {
        ...state,
        isModalOpen: false,
        selectedBlog: { id: null },
        blogOrder: null,
        modalContent: null,
        actionLoading: false,
      };
    case ACTION.EDIT_BLOG_ORDER_FAILURE:
      return { ...state, actionError: action.payload, actionLoading: false };
    case ACTION.SET_BLOG_MAIN_IMAGE_TYPE:
      return { ...state, blog: { ...state.blog, mainImageType: action.payload } };

    default:
      return state;
  }
};
