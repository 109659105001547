import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  logo_item_block: {
    height: 49,
    width: 440,
    display: "flex",
    alignItems: "center",
    margin: "0 40px 15px 40px",
    [theme.breakpoints.down("md")]: {
      margin: "0 20px 15px 20px",
    },
    [theme.breakpoints.down("xs")]: {
      width: 248,
      margin: "0 0 15px 0",
    },
  },
  logo_item_wrap: {
    display: "flex",
    justifyContent: "center",
    height: 49,
    width: 120,
    marginRight: 15,
    [theme.breakpoints.down("xs")]: {
      width: 50,
      marginRight: 10,
    },
  },
  logo_item: {
    objectFit: "contain",
    height: "100%",
    overflow: "hidden",
  },
  logo_item_color: {
    color: `${theme.palette.primary.decor} !important`,
  },
  logo_item_scale: {
    width: 105,
    marginRight: 15,
    [theme.breakpoints.down("xs")]: {
      width: 95,
      marginRight: 5,
    },
  },
}));
export default useStyles;
