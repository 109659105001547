import { useState, useEffect } from "react";
import i18n from 'i18n-js';
import Helmet from "react-helmet";

import { BlogContent } from "../../BlogContent";
import { BlogSlider } from "../BlogSlider";
import { TypeHandler } from "../TypeHandler";
import { ChevronButton } from "../buttons/ChevronButton";

import Typography from "@material-ui/core/Typography";

import useStyles from "./useStyles";

/**
 * @desc function for create blog section at blog page
 * @param {object} news - blogs data
 * @param {boolean} preview - blogs preview
 * @returns {JSX.Element}
 */
export const BlogSection = ({ news, preview }) => {
  const classes = useStyles();
  const [newsData, setNewsData] = useState({
    lastNews: [],
  });

  useEffect(() => {
    if (news.body && news.lastNews) {
      const body = JSON.parse(news.body);
      setNewsData({ ...news, body });
    } else {
      const body = JSON.parse(news.body);
      setNewsData({ ...news, body, lastNews: [] });
    }
  }, [news, news.body]);

  return (
    <section className={classes.blog_section}>
      <Helmet>
        {newsData.description && <meta name="description" content={newsData.description} />}
        {newsData.keyWords && <meta name="keywords" content={newsData.keyWords} />}
      </Helmet>
      <BlogContent
        type={newsData.type}
        title={newsData.title}
        mainImages={newsData.mainImages}
        mainImageType={newsData.mainImageType}
        body={newsData.body} preview={preview}
      />
      <div className={classes.blog_goback}>
        <ChevronButton />
      </div>
      {!preview && (
        <>
          <Typography className={classes.lastNews_header}>
            {news.type !== "CENTRAL" && `${i18n.t("blogContent.lastNewsTitle")} „`}
            <TypeHandler type={news.type} />
            {news.type !== "CENTRAL" && `“`}
          </Typography>
          <BlogSlider lastNews={newsData.lastNews} newsType={newsData.type} />
        </>
      )}
    </section>
  );
};
