import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  filter_container: {
    position: "fixed",
    top: "40vh",
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 65,
    height: 245,
    zIndex: 99,
  },
  filter_item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 55,
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
    cursor: "pointer",
  },
  filter_item_square: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  filter_item_active: {
    background: "#EAEAEA",
  },
  filter_button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 44,
    height: 44,
    border: "1px solid #EAEAEA",
    borderRadius: "50%",
    background: "red",
    color: "#fff",
  },
  filter_button_central: {
    background: "#D60B52",
    borderRadius: 0,
  },
  filter_button_forYou: {
    background: "#EABC3C",
  },
  filter_button_digital: {
    background: "#006B85",
  },
  filter_button_innovative: {
    background: "#80429B",
  },
}));

export default useStyles;
