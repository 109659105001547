import React from "react";
import Fab from "@material-ui/core/Fab";
import Zoom from "@material-ui/core/Zoom";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { animateScroll as scroll } from "react-scroll";

import { useStyles } from "./useStyles";
import { SliderButtonIcon } from "../icons/SliderButtonIcon";

/**
 * @desc function for create go to top button
 * @param {number} threshold - threshold value
 * @returns {JSX.Element}
 */
export const GoToTop = ({ threshold = 600 }) => {
  const classes = useStyles();

  const handleToTop = () => {
    scroll.scrollToTop();
  }

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold,
  });

  return (
    <Zoom in={trigger}>
      <div className={classes.scroll_up_btn} onClick={handleToTop}>
        <Fab className={classes.float_btn}>
          <SliderButtonIcon className={classes.scroll_icon} />
        </Fab>
      </div>
    </Zoom>
  );
};
