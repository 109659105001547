import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  accordion: {
    [theme.breakpoints.down("xs")]: {
      width: 248,
    },
  },
  panel_icon: {
    fontSize: 52,
    transform: "rotate(-90deg)",
  },
  panel_text: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  panel_counter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 28,
    marginLeft: 28,
    background: "#fff",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  details_load: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 6,
    paddingBottom: 9,
  },
  details_item: {
    display: "flex",
    "&:not(:last-child)": {
      marginBottom: 14,
    },
  },
  details_item_img: {
    width: 105,
    height: 55,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      width: 80,
    },
  },
  details_item_text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 100,
    marginLeft: 15,
    [theme.breakpoints.down("xs")]: {
      width: 90,
    },
  },
  details_item_checkbox: {
    height: 24,
    marginLeft: "auto",
    padding: 0,
  },
  details_load_text: {
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  details_load_btn: {
    padding: 5,
    cursor: "pointer",
    fontSize: 20,
  },
  details_load_icon: {
    fontSize: 20,
    width: 20,
    height: 20,
    color: theme.palette.primary.main,
    transition: "all 0.6s",
    "&:hover": {
      opacity: 0.5,
    },
  },
  details_load_preloader: {
    height: 30,
    width: 30,
  },
}));

export default useStyles;
