import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { Preloader } from "../../сommon/Preloader";
import { ErrorHandler } from "../../сommon/ErrorHandler";

import { closeUserModal, deleteUserRequest, editUserRequest, editUserField } from "../../../store/actions";

import useStyles from "../useStyles";

/**
 * @desc function for create user modal window
 * @returns {JSX.Element}
 */
export const UserModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isModalOpen, modalContent, user, actionLoading, actionError } = useSelector(({ users }) => users);

  const options = [
    { title: "Benutzer", value: "ROLE_USER" },
    { title: "Premium-Benutzer", value: "ROLE_PREMIUM_USER" },
    { title: "Administrator", value: "ROLE_ADMIN" },
  ];

  const handleSelectChange = (event, option) => {
    dispatch(editUserField("role", option.value));
  };

  const confirmBtnClick = () => {
    modalContent === "DELETE" && dispatch(deleteUserRequest(user.id));
    modalContent === "EDIT" && dispatch(editUserRequest(user.id, { role: user.role }));
  };

  const closeModal = () => dispatch(closeUserModal());

  return (
    isModalOpen && (
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        disableScrollLock={true}
      >
        <Fade in={isModalOpen}>
          <div className={classes.paper}>
            <div className={classes.modal_wrap}>
            <Typography className={classes.modal_text}>
                {modalContent === "DELETE" && `Möchten Sie den Benutzer ${user.email} löschen?`}
                {modalContent === "EDIT" && `Möchten Sie die Rolle von ${user.email} ändern?`}
              </Typography>

              <IconButton className={classes.modal_close_btn} onClick={closeModal} aria-label="close">
                <CloseIcon />
              </IconButton>

              {modalContent === "EDIT" && (
                <Autocomplete
                  value={options.filter(el => el.value === user.role)[0]}
                  onChange={handleSelectChange}
                  disableClearable
                  options={options}
                  autoHighlight
                  getOptionLabel={option => option.title}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Rolle"
                      className={classes.modal_autocomplete_input}
                      placeholder="Rolle"
                      variant="outlined"
                      type="button"
                    />
                  )}
                  classes={{ inputRoot: classes.modal_autocomplete }}
                />
              )}

              <div className={classes.modal_buttons}>
                <Button onClick={closeModal} variant="contained" className={classes.modal_btn}>
                  Nein
                </Button>
                {actionLoading ? (
                  <div className={classes.modal_btn}>
                    <Preloader />
                  </div>
                ) : (
                  <Button onClick={confirmBtnClick} variant="contained" className={classes.modal_btn}>
                    Ja
                  </Button>
                )}
              </div>

              {!!actionError && <ErrorHandler error={actionError} />}
            </div>
          </div>
        </Fade>
      </Modal>
    )
  );
};
