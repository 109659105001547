/**
 * @desc function for handling user role
 * @param {string} role - current user role
 * @returns {JSX.Element}
 */
export const RoleHandler = ({ role }) => {
  const roles = {
    ROLE_USER: "Benutzer",
    ROLE_ADMIN: "Administrator",
    ROLE_PREMIUM_USER: "Premium-Benutzer",
  };

  return role ? roles[role] : "";
};
