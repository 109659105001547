import { useHistory } from "react-router-dom";
import { scroller } from "react-scroll";

import useStyles from "./useStyles";

/**
 * @desc function for create float filter at home page
 * @param {string} filter - current filter
 * @returns {JSX.Element}
 */
export const FloatFilter = ({ filter}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleFilter = path => history.push(path);

  return (
    <div className={classes.filter_container}>
      <div className={`${classes.filter_item} ${classes.filter_item_square} ${filter === "" && classes.filter_item_active}`}>
        <div
          className={`${classes.filter_button} ${classes.filter_button_central}`}
          onClick={() => {
              handleFilter("/home");
              scroller.scrollTo("scroll-to-filter", {
                  duration: 800,
                  delay: 0,
                  smooth: "easeInOutQuart",
              });
          }}
        />
      </div>
      <div className={`${classes.filter_item} ${filter === "FOR_YOU" ? classes.filter_item_active : ""}`}>
        <div
          className={`${classes.filter_button} ${classes.filter_button_forYou}`}
          onClick={() => handleFilter("/home/fuer_sie")}
        >S</div>
      </div>
      <div className={`${classes.filter_item} ${filter === "DIGITAL" ? classes.filter_item_active : ""}`}>
        <div
          className={`${classes.filter_button} ${classes.filter_button_digital}`}
          onClick={() => handleFilter("/home/digital")}
        >D</div>
      </div>
      <div className={`${classes.filter_item} ${filter === "INNOVATIVE" ? classes.filter_item_active : ""}`}>
        <div
          className={`${classes.filter_button} ${classes.filter_button_innovative}`}
          onClick={() => handleFilter("/home/innovativ")}
        >I</div>
      </div>
    </div>
  );
}
