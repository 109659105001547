import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content_section: {
    margin: "100px auto 50px",
    padding: "0 140px",
    [theme.breakpoints.down("lg")]: {
      padding: "0 81px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0 50px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "70px auto 40px",
      padding: "0 36px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "50px auto 30px",
    },
  },
  content_header: {
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    height: 53,
    marginBottom: 25,
    [theme.breakpoints.down("sm")]: {
      alignItems: "normal",
      height: 100,
    },
    [theme.breakpoints.down("xs")]: {
      height: 85,
    },
  },
  content_header_text: {
    paddingRight: 330,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
      paddingRight: 0,
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  content_body: {
    marginBottom: 125,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 55,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 50,
    },
  },
  content_wrapper: {
    position: "relative",
  },
  content_body_overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: "#EAEAEA",
    opacity: 0.5,
    zIndex: 19,
  },
  content_accordion: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      top: 40,
      right: "auto",
      left: "50%",
      transform: "translateX(-50%)",
    },
    [theme.breakpoints.down("xs")]: {
      top: 35,
    },
  },
  content_container: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridGap: "2px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  content_container_preview: {
    display: "block",
    gridColumnStart: 1,
    gridColumnEnd: 4,
    margin: "2px 0 2px",
    [theme.breakpoints.down("1279")]: {
      gridColumnEnd: 3,
    },
    [theme.breakpoints.down("xs")]: {
      gridColumnEnd: 2,
    },
  },
  content_container_conditions: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gridGap: "2px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  content_overlay: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    background: theme.palette.primary.decor,
    opacity: 0.88,
  },
  content_overlay_text: {
    maxHeight: 200,
    lineHeight: "20px",
    padding: "0 5px",
    overflow: "hidden",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    color: "#fff",
    textAlign: "center",
    boxSizing: "border-box",
    wordBreak: "break-word",
    [theme.breakpoints.down("1279")]: {
      lineHeight: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      lineHeight: "12px",
    },
    "&:not(:last-child)": {
      marginBottom: 10,
      [theme.breakpoints.down("1279")]: {
        marginBottom: 5,
      },
    }
  },
  content_overlay_size: {
    maxHeight: 20,
    [theme.breakpoints.down("1279")]: {
      maxHeight: 16,
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: 12,
    },
  },
  content_overlay_title: {
    maxHeight: 100,
    fontWeight: 700,
    [theme.breakpoints.down("1279")]: {
      maxHeight: 36,
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: 36,
    },
  },
  content_overlay_title_sm: {
    maxHeight: 40,
    fontWeight: 700,
    [theme.breakpoints.down("1279")]: {
      maxHeight: 20,
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: 24,
    },
  },
  content_overlay_description: {
    [theme.breakpoints.down("1279")]: {
      maxHeight: 54,
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: 60,
    },
  },
  content_overlay_description_sm: {
    maxHeight: 80,
    [theme.breakpoints.down("1279")]: {
      maxHeight: 56,
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: 60,
    },
  },
  content_file: {
    position: "relative",
    height: 306,
    "&:hover .visibility_hidden": {
      visibility: "visible",
    },
    [theme.breakpoints.down("md")]: {
      height: 153,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 2,
    },
  },
  content_file_preview: {
    position: "relative",
    height: 647,
    "&:hover .visibility_hidden": {
      visibility: "visible",
    },
    [theme.breakpoints.down("md")]: {
      height: 330,
    },
  },
  content_file_conditions: {
    position: "relative",
    height: 177,
    "&:hover .visibility_hidden": {
      visibility: "visible",
    },
    [theme.breakpoints.down("md")]: {
      height: 122,
    },
  },
  content_img: {
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    cursor: "pointer",
  },
  content_checkbox: {
    position: "absolute",
    top: 16,
    right: 26,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      width: 15,
      height: 15,
    },
  },
  content_checkbox_icon: {
    [theme.breakpoints.down("sm")]: {
      width: 15,
      height: 15,
    },
  },
  content_conditions_checkbox: {
    position: "absolute",
    top: 7,
    right: 12,
    padding: 0,
  },
  content_footer: {
    textAlign: "center",
  },
  content_counter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 28,
    margin: "0 auto",
    background: "#F7F7F7",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  content_load_text: {
    margin: "15px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  content_load_icon: {
    cursor: "pointer",
    fontSize: 27,
    width: 27,
    height: 27,
    transition: "all 0.6s",
    "&:hover": {
      opacity: 0.5,
    },
  },
  content_load_preloader: {
    height: 51,
  },
  content_add_blogs_btn_wrap: {
    height: 49,
    margin: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      margin: 30,
    },
  },
  content_edit_btn: {
    height: 24,
    width: 24,
    position: "absolute",
    bottom: 16,
    right: 26,
    padding: 0,
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      width: 15,
      height: 15,
    },
  },
  content_edit_icon: {
    color: "#FFFFFF",
    fontSize: 28,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  content_edit_btn_additional: {
    bottom: 7,
    right: 12,
  },
}));

export default useStyles;
