import { useState, useEffect } from "react";
import axios from "axios";

import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Preloader } from "../Preloader";

import useStyles from "./useStyles";

/**
 * @desc function for create watermark
 * @param {object} logo - watermark
 * @param {string} logoUrl - logo image src
 * @param {object} selectedLogo - selected watermark
 * @param {function} setSelectedLogo - set selected watermark
 * @param {array} waterMarkImages - array with watermarks
 * @param {function} setWaterMarkImages - set watermark position
 * @returns {JSX.Element}
 */
export const LogoItem = ({ logo, logoUrl, selectedLogo, setSelectedLogo, waterMarkImages, setWaterMarkImages }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [logoSrc, setLogoSrc] = useState(logoUrl);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    let cleanup = false;
    if (!logoSrc) {
      setLoading(true);
      axios
          .get(`file/file/${logo.id}/download/`, { responseType: "blob" })
          .then(res => {
            if (!cleanup) {
              const url = URL.createObjectURL(new Blob([res.data]));
              setLoading(false);
              setLogoSrc(url);
            }
          })
          .catch(error => {
            if (!cleanup) {
              setError(error);
            }
          });
    }
    return () => (cleanup = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (logoSrc) {
      const img = new Image();
      img.src = logoSrc;
      img.onload = function () {
        setSize({ width: this.width, height: this.height });
      };
    }
  }, [logoSrc]);

  const handleLogoChange = () => {
    setSelectedLogo(logo.id);
    if (!waterMarkImages.filter(waterMark => waterMark.id === logo.id).length) {
      setWaterMarkImages([
        ...waterMarkImages,
        { src: logoSrc, width: size.width, height: size.height, x: 0, y: 0, id: logo.id, scale: 100 },
      ]);
    }
  };

  const closeLogo = () => {
    const filteredWaterMarks = waterMarkImages.filter(waterMark => waterMark.id !== logo.id);
    setWaterMarkImages(filteredWaterMarks);
    selectedLogo === logo.id && setSelectedLogo(null);
  };

  const handleScaleChange = event => {
    if (!event.target.value.includes("e")) {
      if(+event.target.value >= 10 && +event.target.value <= 200) {
        const newWaterMarkImages = waterMarkImages.map(waterMark => {
          if (waterMark.id === logo.id) {
            return {
              ...waterMark,
              width: (size.width * +event.target.value) / 100,
              height: (size.height * +event.target.value) / 100,
              scale: +event.target.value,
            };
          }
          return waterMark;
        });
        setWaterMarkImages(newWaterMarkImages);
      }

      if(+event.target.value < 10 && +event.target.value >= 0) {
        const newWaterMarkImages = waterMarkImages.map(waterMark => {
          if (waterMark.id === logo.id) {
            return {...waterMark, width: size.width/10, height: size.height/10, scale: 10 };
          }
          return waterMark;
        });
        setWaterMarkImages(newWaterMarkImages);
      }
    }
  };

  return (
      <div className={classes.logo_item_block}>
        {loading ? (
            <Preloader />
        ) : (
            <>
              <Radio checked={selectedLogo === logo.id} onChange={handleLogoChange} className={classes.logo_item_color} />
              <div className={classes.logo_item_wrap}>
                <img className={classes.logo_item} src={logoSrc} alt="logo" />
              </div>
              {!!waterMarkImages.filter(waterMark => waterMark.id === logo.id).length && (
                  <TextField
                      value={+waterMarkImages.filter(waterMark => waterMark.id === logo.id)[0].scale}
                      label="Maßstab"
                      onChange={handleScaleChange}
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        step: 1,
                        min: 10,
                        max: 200,
                        type: "number",
                        "aria-labelledby": "input-slider",
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      className={classes.logo_item_scale}
                  />
              )}
              {!!waterMarkImages.filter(waterMark => waterMark.id === logo.id).length && (
                  <IconButton onClick={closeLogo} className={classes.logo_item_color}>
                    <CloseIcon />
                  </IconButton>
              )}
            </>
        )}
      </div>
  );
};
