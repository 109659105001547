import React from "react";

import { Header } from "../../components/сommon/Header";
import { BackButton } from "../../components/сommon/BackButton";
import { RecoveryPasswordForm } from "../../components/forms/RecoveryPasswordForm";

/**
 * @desc Auth recovery password UI
 * @return {JSX.Element}
 */
export const RecoveryPasswordPage = () => (
  <>
    <Header />
    <BackButton />
    <RecoveryPasswordForm />
  </>
)
