import { ACTION } from "../constants/types";

/**
 * @desc function for set admin blogs pagination
 * @param {object} query - number of page, size per page
 * @return {{type: string, payload: *}}
 */
export const setAdminBlogsQuery = query => {
  return {
    type: ACTION.SET_ADMIN_BLOGS_QUERY,
    payload: query,
  };
};

/**
 * @desc function for successful receipt admin blogs
 * @param {object} - object with admin blogs, page, totalCount
 * @return {{type: string, payload: *}}
 */
export const getAdminBlogsSuccess = ({ blogs, page, totalCount }) => {
  return {
    type: ACTION.GET_ADMIN_BLOGS_SUCCESS,
    payload: {
      blogs,
      page,
      totalCount,
    },
  };
};

/**
 * @desc function for failed receipt admin blogs
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const getAdminBlogsFailure = error => {
  return {
    type: ACTION.GET_ADMIN_BLOGS_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for edit admin blog fields
 * @param {string} name - field name
 * @param {string} value - field value
 * @return {{type: string, payload: *}}
 */
export const editBlogField = (name, value) => {
  return {
    type: ACTION.EDIT_BLOG_FIELDS,
    payload: { [name]: value },
  };
};

/**
 * @desc function for clean admin blog fields
 * @return {{type: string}}
 */
export const cleanAdminBlogs = () => {
  return {
    type: ACTION.CLEAN_ADMIN_BLOGS,
  };
};

/**
 * @desc function for add blog main image
 * @param {object} file - object with image file
 * @return {{type: string, payload: *}}
 */
export const addBlogsMainImageRequest = file => {
  return {
    type: ACTION.ADD_BLOG_MAIN_IMAGE_REQUEST,
    payload: file,
  };
};

/**
 * @desc function for successful addition blog main image
 * @param {string} imageUrl - image url
 * @return {{type: string, payload: *}}
 */
export const addBlogsMainImageSuccess = imageUrl => {
  return {
    type: ACTION.ADD_BLOG_MAIN_IMAGE_SUCCESS,
    payload: imageUrl,
  };
};

/**
 * @desc function for failed addition blog main image
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const addBlogsMainImageFailure = error => {
  return {
    type: ACTION.ADD_BLOG_MAIN_IMAGE_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for addition blog main video
 * @param {string} url - video url
 * @return {{type: string, payload: *}}
 */
export const addBlogsMainVideo = (url) => {
  return {
    type: ACTION.ADD_BLOG_MAIN_VIDEO,
    payload: url,
  };
};

/**
 * @desc function for delete blog main image
 * @param {string} url - image url
 * @return {{type: string, payload: *}}
 */
export const deleteBlogsMainImageRequest = url => {
  return {
    type: ACTION.DELETE_BLOG_MAIN_IMAGE_REQUEST,
    payload: url,
  };
};

/**
 * @desc function for successful deletion blog main image
 * @param {array} imageUrl - array with image urls
 * @return {{type: string, payload: *}}
 */
export const deleteBlogsMainImageSuccess = imageUrl => {
  return {
    type: ACTION.DELETE_BLOG_MAIN_IMAGE_SUCCESS,
    payload: imageUrl,
  };
};

/**
 * @desc function for failed deletion blog main image
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const deleteBlogsMainImageFailure = error => {
  return {
    type: ACTION.DELETE_BLOG_MAIN_IMAGE_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for delete all blog main images
 * @return {{type: string}}
 */
export const deleteBlogsAllMainImages = () => {
  return {
    type: ACTION.DELETE_BLOG_ALL_MAIN_IMAGES,
  };
};

/**
 * @desc function for add blog cropped image
 * @param {object} file - object with cropped image file
 * @return {{type: string, payload: *}}
 */
export const addBlogsCroppedImageRequest = file => {
  return {
    type: ACTION.ADD_BLOG_CROPPED_IMAGE_REQUEST,
    payload: file,
  };
};

/**
 * @desc function for successful addition blog cropped image
 * @param {string} imageUrl - image url
 * @return {{type: string, payload: *}}
 */
export const addBlogsCroppedImageSuccess = imageUrl => {
  return {
    type: ACTION.ADD_BLOG_CROPPED_IMAGE_SUCCESS,
    payload: imageUrl,
  };
};

/**
 * @desc function for failed addition blog cropped image
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const addBlogsCroppedImageFailure = error => {
  return {
    type: ACTION.ADD_BLOG_CROPPED_IMAGE_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for add blog
 * @param {object} blog - object with blog
 * @return {{type: string, payload: *}}
 */
export const addBlogRequest = blog => {
  return {
    type: ACTION.ADD_BLOG_REQUEST,
    payload: blog,
  };
};

/**
 * @desc function for successful addition blog
 * @return {{type: string}}
 */
export const addBlogSuccess = () => {
  return {
    type: ACTION.ADD_BLOG_SUCCESS,
  };
};

/**
 * @desc function for failed addition blog
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const addBlogFailure = error => {
  return {
    type: ACTION.ADD_BLOG_FAILURE,
    payload: error,
  };
};

/**
 * @desc function open blog modal window
 * @param {object} selectedBlog - object with selected blog
 * @param {string} modalContent - string with modal content
 * @return {{type: string, payload: *}}
 */
export const openBlogModal = (selectedBlog, modalContent) => {
  return {
    type: ACTION.BLOG_MODAL_OPEN,
    payload: { selectedBlog, modalContent }
  };
};

/**
 * @desc function for close blog modal window
 * @return {{type: string}}
 */
export const closeBlogModal = () => {
  return {
    type: ACTION.BLOG_MODAL_CLOSE,
  };
};

/**
 * @desc function for fill blog form when editing
 * @param {object} blog - object with selected blog
 * @return {{type: string, payload: *}}
 */
export const fillBlogForm = blog => {
  return {
    type: ACTION.FILL_BLOG_FORM,
    payload: blog,
  };
};

/**
 * @desc function for clean blog form
 * @return {{type: string}}
 */
export const cleanBlogForm = () => {
  return {
    type: ACTION.CLEAN_BLOG_FORM,
  };
};

/**
 * @desc function for edit blog
 * @param {object} blog - object with selected blog
 * @param {number} blogId - selected blog id
 * @return {{type: string, payload: *}}
 */
export const editBlogRequest = (blog, blogId) => {
  return {
    type: ACTION.EDIT_BLOG_REQUEST,
    payload: { blog, blogId },
  };
};

/**
 * @desc function for successful editing blog
 * @return {{type: string}}
 */
export const editBlogSuccess = () => {
  return {
    type: ACTION.EDIT_BLOG_SUCCESS,
  };
};

/**
 * @desc function for failed editing blog
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const editBlogFailure = error => {
  return {
    type: ACTION.EDIT_BLOG_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for delete blog
 * @param {number} blogId - blog id
 * @return {{type: string, payload: *}}
 */
export const deleteBlogRequest = blogId => {
  return {
    type: ACTION.DELETE_BLOG_REQUEST,
    payload: blogId,
  };
};

/**
 * @desc function for successful deletion blog
 * @return {{type: string}}
 */
export const deleteBlogSuccess = () => {
  return {
    type: ACTION.DELETE_BLOG_SUCCESS,
  };
};

/**
 * @desc function for failed deletion blog
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const deleteBlogFailure = error => {
  return {
    type: ACTION.DELETE_BLOG_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for opening successful modal window
 * @param {object} selectedBlog - selected blog
 * @return {{type: string, payload: *}}
 */
export const openSuccessBlogModal = selectedBlog => {
  return {
    type: ACTION.BLOG_SUCCESS_MODAL_OPEN,
    payload: selectedBlog,
  };
};

/**
 * @desc function for closing successful modal window
 * @return {{type: string}}
 */
export const closeSuccessBlogModal = () => {
  return {
    type: ACTION.BLOG_SUCCESS_MODAL_CLOSE,
  };
};

/**
 * @desc function for opening blog preview
 * @param {object} blog - selected blog
 * @return {{type: string, payload: *}}
 */
export const setBlogPreview = blog => {
  return {
    type: ACTION.SET_BLOG_PREVIEW,
    payload: blog,
  };
};

/**
 * @desc function for enable blog
 * @param {number} blogId - blog id
 * @param {boolean} active - toggle value
 * @return {{type: string, payload: *}}
 */
export const enableBlogRequest = (blogId, active) => {
  return {
    type: ACTION.ENABLE_ADMIN_BLOG_REQUEST,
    payload: { id: blogId, active },
  };
};

/**
 * @desc function for failed enable blog
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const enableBlogFailure = error => {
  return {
    type: ACTION.ENABLE_ADMIN_BLOG_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for edit blog order
 * @param {number} blogOrder - blog order
 * @return {{type: string, payload: *}}
 */
export const editBlogOrder = blogOrder => {
  return {
    type: ACTION.EDIT_BLOG_ORDER,
    payload: blogOrder,
  };
};

/**
 * @desc function for set blog order
 * @param {number} blogId - blog id
 * @param {number} blogOrder - blog order
 * @return {{type: string, payload: *}}
 */
export const editBlogOrderRequest = (blogId, blogOrder) => {
  return {
    type: ACTION.EDIT_BLOG_ORDER_REQUEST,
    payload: { blogId, blogOrder }
  };
};

/**
 * @desc function for successful editing blog order
 * @return {{type: string}}
 */
export const editBlogOrderSuccess = () => {
  return {
    type: ACTION.EDIT_BLOG_ORDER_SUCCESS,
  };
};

/**
 * @desc function for failed editing blog order
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const editBlogOrderFailure = error => {
  return {
    type: ACTION.EDIT_BLOG_ORDER_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for set blog main image type
 * @param {string} type - image type
 * @return {{type: string, payload: *}}
 */
export const setBlogMainImageType = (type) => {
  return {
    type: ACTION.SET_BLOG_MAIN_IMAGE_TYPE,
    payload: type,
  };
};
