import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import useStyles from "../useStyles";

/**
 * @desc function for create success modal window
 * @param {boolean} isModalOpen - toggle value
 * @param {function} onCloseModal - close modal window
 * @param {string} text - modal content
 * @returns {JSX.Element}
 */
export const SuccessModal = ({ isModalOpen, onCloseModal, text }) => {
  const classes = useStyles();

  const closeModal = () => {
    onCloseModal();
  };

  return (
    isModalOpen && (
      <Modal
        open={isModalOpen}
        onClose={() => closeModal()}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableScrollLock={true}
      >
        <Fade in={isModalOpen}>
          <div className={classes.paper}>
            <div className={classes.modal_wrap}>
              <CheckCircleIcon className={classes.modal_success_icon} />

              <Typography className={classes.modal_text}>
                {text}
              </Typography>

              <IconButton className={classes.modal_close_btn} onClick={closeModal} aria-label="close">
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
    )
  );
};
