import { ACTION } from "../constants/types";

const INIT_STATE = {
  blogs: [],
  pagination: {
    page: 0,
    totalCount: 0,
  },
  query: {
    page: 0,
    size: 10,
    contentType: "",
    sort: "orderRank",
  },
  loading: false,
  error: null,
};

export const blogsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION.SET_BLOGS_QUERY:
      return { ...state, query: { ...state.query, ...action.payload } };

    case ACTION.GET_BLOGS_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION.GET_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.payload.blogs,
        pagination: { page: action.payload.page, totalCount: action.payload.totalCount },
        loading: false,
      };
    case ACTION.GET_BLOGS_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case ACTION.SET_ADD_BLOGS_QUERY:
      return { ...state, query: { ...state.query, page: action.payload } };

    case ACTION.ADD_BLOGS_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION.ADD_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: [...state.blogs, ...action.payload.blogs],
        pagination: { page: action.payload.page, totalCount: action.payload.totalCount },
        loading: false,
      };
    case ACTION.ADD_BLOGS_FAILURE:
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};
