import { ACTION } from "../constants/types";

const INIT_STATE = {
  files: [],
  pagination: {
    page: 0,
    totalCount: 0,
  },
  query: {
    page: 0,
    size: 10,
    contentType: "",
    sort: "createdAt,desc",
  },
  loading: false,
  error: null,
  file: {
    additionalFile: [],
    contentId: null,
    deleteAdditionalFilesIds: [],
    deleteLogosIds: [],
    description: "",
    forLogos: false,
    logos: [],
    mainFile: {
      size: null,
    },
    mainImages: [],
    title: "",
    type: "FOR_YOU",
  },
  croppedImageUploading: false,
  croppedImageUploadError: null,
  actionLoading: false,
  actionError: false,
  selectedFile: {
    id: null,
  },
  fileOrder: null,
  isModalOpen: false,
  modalContent: null,
  isSuccessModalOpen: false,
};

export const adminFilesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION.SET_ADMIN_FILES_QUERY:
      return { ...state, query: { ...state.query, ...action.payload } };

    case ACTION.GET_ADMIN_FILES_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION.GET_ADMIN_FILES_SUCCESS:
      return {
        ...state,
        files: action.payload.files,
        pagination: { page: action.payload.page, totalCount: action.payload.totalCount },
        loading: false,
      };
    case ACTION.GET_ADMIN_FILES_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case ACTION.EDIT_PRIVATE_FILE_FIELDS:
      return { ...state, file: { ...state.file, ...action.payload } };

    case ACTION.CLEAN_ADMIN_FILES:
      return INIT_STATE;

    case ACTION.FILL_PRIVATE_FILE_FORM:
      return { ...state, file: action.payload };
    case ACTION.CLEAN_PRIVATE_FILE_FORM:
      return {
        ...state,
        file: {
          additionalFile: [],
          contentId: null,
          deleteAdditionalFilesIds: [],
          deleteLogosIds: [],
          description: "",
          forLogos: false,
          logos: [],
          mainFile: {
            size: null,
          },
          mainImages: [],
          title: "",
          type: "FOR_YOU",
        },
      };

    case ACTION.ADD_PRIVATE_FILE_REQUEST:
      return { ...state, actionLoading: true, actionError: null };
    case ACTION.ADD_PRIVATE_FILE_SUCCESS:
      return { ...state, actionLoading: false };
    case ACTION.ADD_PRIVATE_FILE_FAILURE:
      return { ...state, actionError: action.payload, actionLoading: false };

    case ACTION.ADD_PRIVATE_FILE_CROPPED_IMAGE_REQUEST:
      return { ...state, croppedImageUploading: true, croppedImageUploadError: null };
    case ACTION.ADD_PRIVATE_FILE_CROPPED_IMAGE_SUCCESS:
      return { ...state, file: { ...state.file, mainImages: [...state.file.mainImages, action.payload] }, croppedImageUploading: false };
    case ACTION.ADD_PRIVATE_FILE_CROPPED_IMAGE_FAILURE:
      return { ...state, croppedImageUploadError: action.payload, croppedImageUploading: false };

    case ACTION.DELETE_PRIVATE_FILE_CROPPED_IMAGE_REQUEST:
      return { ...state, croppedImageUploading: true, croppedImageUploadError: null };
    case ACTION.DELETE_PRIVATE_FILE_CROPPED_IMAGE_SUCCESS:
      return { ...state, file: { ...state.file, mainImages: [...action.payload] }, croppedImageUploading: false };
    case ACTION.DELETE_PRIVATE_FILE_CROPPED_IMAGE_FAILURE:
      return { ...state, croppedImageUploadError: action.payload, croppedImageUploading: false };

    case ACTION.PRIVATE_FILE_SUCCESS_MODAL_OPEN:
      return { ...state, isSuccessModalOpen: true, selectedFile: action.payload };
    case ACTION.PRIVATE_FILE_SUCCESS_MODAL_CLOSE:
      return { ...state, isSuccessModalOpen: false, selectedFile: { id: null } };

    case ACTION.PRIVATE_FILE_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: true,
        selectedFile: action.payload.selectedFile,
        actionError: null,
        modalContent: action.payload.modalContent,
        fileOrder: action.payload.selectedFile.order,
      };
    case ACTION.PRIVATE_FILE_MODAL_CLOSE:
      return {
        ...state,
        isModalOpen: false,
        modalContent: null,
        actionError: null,
        selectedFile: {
          id: null,
        },
        fileOrder: null,
      };

    case ACTION.EDIT_PRIVATE_FILE_REQUEST:
      return { ...state, actionLoading: true, actionError: null };
    case ACTION.EDIT_PRIVATE_FILE_SUCCESS:
      return { ...state, actionLoading: false };
    case ACTION.EDIT_PRIVATE_FILE_FAILURE:
      return { ...state, actionError: action.payload, actionLoading: false };

    case ACTION.DELETE_PRIVATE_FILE_REQUEST:
      return { ...state, actionLoading: true, actionError: null };
    case ACTION.DELETE_PRIVATE_FILE_SUCCESS:
      return {
        ...state,
        isModalOpen: false,
        selectedFile: { id: null },
        fileOrder: null,
        modalContent: null,
        actionLoading: false,
      };
    case ACTION.DELETE_PRIVATE_FILE_FAILURE:
      return { ...state, actionError: action.payload, actionLoading: false };

    case ACTION.ENABLE_ADMIN_FILE_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION.ENABLE_ADMIN_FILE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case ACTION.EDIT_ADMIN_FILE_ORDER:
      return { ...state, fileOrder: action.payload };
    case ACTION.EDIT_ADMIN_FILE_ORDER_REQUEST:
      return { ...state, actionLoading: true, actionError: null };
    case ACTION.EDIT_ADMIN_FILE_ORDER_SUCCESS:
      return {
        ...state,
        isModalOpen: false,
        selectedBlog: { id: null },
        fileOrder: null,
        modalContent: null,
        actionLoading: false,
      };
    case ACTION.EDIT_ADMIN_FILE_ORDER_FAILURE:
      return { ...state, actionError: action.payload, actionLoading: false };

    default:
      return state;
  }
};
