import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { closeSuccessBlogModal, cleanBlogForm } from "../../../store/actions";

import useStyles from "../useStyles";

/**
 * @desc function for create success blog modal window
 * @returns {JSX.Element}
 */
export const BlogSuccessModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isSuccessModalOpen, blog, selectedBlog } = useSelector(({ adminBlogs }) => adminBlogs);

  const closeModal = () => {
    history.push("/blogs");
    dispatch(closeSuccessBlogModal());
    dispatch(cleanBlogForm());
  };

  return (
    isSuccessModalOpen && (
      <Modal
        open={isSuccessModalOpen}
        onClose={() => closeModal()}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableScrollLock={true}
      >
        <Fade in={isSuccessModalOpen}>
          <div className={classes.paper}>
            <div className={classes.modal_wrap}>
              <CheckCircleIcon className={classes.modal_success_icon} />

              <Typography className={classes.modal_text}>
                {`Blog ${selectedBlog.id} erfolgreich ${blog.id ? "aktualisiert" : "hinzugefügt"
                  }. Sie werden zur Blog-Seite weitergeleitet`}
              </Typography>

              <IconButton className={classes.modal_close_btn} onClick={closeModal} aria-label="close">
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
    )
  );
};
