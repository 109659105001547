import React from "react";

import { Header } from "../../components/сommon/Header";
import { Footer } from "../../components/сommon/Footer";
import { PrivateContent } from "../../components/PrivateContent";
import { GoToTop } from "../../components/сommon/GoToTop";
import { PersonalMenu } from "../../components/сommon/PersonalMenu";
import { ErrorModal } from "../../components/modals/ErrorModal";
import { PrivateContentBanner } from "../../components/PrivateContentBanner";

/**
 * @desc Private content UI
 * @return {JSX.Element}
 */
export const PrivateContentPage = () => (
  <>
    <GoToTop />
    <Header />
    <PersonalMenu />
    <PrivateContentBanner />
    <PrivateContent />
    <Footer />
    <ErrorModal />
  </>
);
