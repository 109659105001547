import { put, take } from "redux-saga/effects";
import { ACTION } from "../constants/types";
import { openErrorModal } from "../actions/";

export function* watchErrorHanding() {
  while (true) {
    const action = yield take([
      ACTION.GET_BLOGS_FAILURE,
      ACTION.ADD_BLOGS_FAILURE,
      ACTION.GET_ADMIN_BLOGS_FAILURE,
      ACTION.ADD_BLOG_MAIN_IMAGE_FAILURE,
      ACTION.ADD_BLOG_CROPPED_IMAGE_FAILURE,
      ACTION.ADD_BLOG_FAILURE,
      ACTION.EDIT_BLOG_FAILURE,
      ACTION.GET_PRIVATE_CONTENT_FAILURE,
      ACTION.ADD_PRIVATE_CONTENT_FAILURE,
      ACTION.DOWNLOAD_PRIVATE_CONTENT_FAILURE,
      ACTION.GET_ADMIN_FILES_FAILURE,
      ACTION.ADD_PRIVATE_FILE_FAILURE,
      ACTION.ADD_PRIVATE_FILE_CROPPED_IMAGE_FAILURE,
      ACTION.EDIT_PRIVATE_FILE_FAILURE,
      ACTION.GET_USERS_FAILURE,
      ACTION.ENABLE_USER_FAILURE,
      ACTION.GET_MAIN_IMAGE_FOR_MERGE_FAILURE,
      ACTION.GET_LOGOS_IDS_FAILURE,
    ]);
    const { payload } = action;
    yield put(openErrorModal(payload));
  }
}
