import { TypeHandler } from "../TypeHandler";
import getDecorColor from "../../../utils/getDecorColor";

import useStyles from "./useStyles";
import { BlogLogo } from "../icons/BlogLogo";

/**
 * @desc function for create decor color depending on blog type
 * @param {string} type - blog type
 * @returns {JSX.Element}
 */
export const BlogDecorType = ({ type }) => {
  const classes = useStyles();

  return (
    <div className={classes.decor_container}>
      <BlogLogo className={classes.decor_logo} />
      {type && type !== "CENTRAL" &&
        <span className={classes.decor_text} style={{ color: getDecorColor(type) }}>
          <TypeHandler type={type} />
        </span>
      }
      <div className={classes.decor_horizontal} style={{ background: getDecorColor(type) }} />
      <div className={classes.decor_vertical} style={{ background: getDecorColor(type) }} />
    </div>
  );
}
