import { useDispatch } from "react-redux";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import useStyles from "../useStyles";

/**
 * @desc function for select type of blog
 * @param {string} selectedOption - current blog type
 * @param {function} selectOption - set new blog type
 * @param {array} options - array with blog types
 * @returns {JSX.Element}
 */
export const SelectContentType = ({ selectedOption, selectOption, options }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleSelectChange = (event, option) => {
    dispatch(selectOption("type", option.value));
  };

  return (
    <Autocomplete
      value={options.filter(el => el.value === selectedOption)[0]}
      onChange={handleSelectChange}
      disableClearable
      options={options}
      autoHighlight
      getOptionLabel={option => option.title}
      renderInput={params => (
        <TextField
          {...params}
          label="Inhaltstyp"
          className={classes.innerFormField}
          placeholder="Inhaltstyp"
          variant="outlined"
          type="button"
        />
      )}
      classes={{ inputRoot: classes.inner_form_autocomplete }}
    />
  );
};
