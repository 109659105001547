import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { PrivateFileUpload } from "../../uploads/PrivateFileUpload";
import { PrivateAdditionalFilesUpload } from "../../uploads/PrivateAdditionalFilesUpload";
import { PrivateFileCroppedMainImageUpload } from "../../uploads/PrivateFileCroppedMainImageUpload";
import { PrivateFileLogosUpload } from "../../uploads/PrivateFileLogosUpload";
import { PrivateFileSuccessModal } from "../../modals/PrivateFileSuccessModal";
import { Preloader } from "../../сommon/Preloader";
import { SelectContentType } from "../SelectContentType";

import { editPrivateFileField, addPrivateFileRequest, editPrivateFileRequest } from "../../../store/actions/";

import useStyles from "../useStyles";

/**
 * @desc function for create private file form
 * @returns {JSX.Element}
 */
export const PrivateFileForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { file, actionLoading } = useSelector(({ adminFiles }) => adminFiles);

  const handleFieldChange = event => {
    dispatch(editPrivateFileField(event.target.name, event.target.value));
  };

  const submitForm = () => {
    const data = new FormData();
    if (!!file.additionalFile.length && file.additionalFile.filter(el => !el.id)) {
      file.additionalFile.filter(el => !el.id).forEach(val => data.append("additionalFile", val));
    }
    !!file.deleteAdditionalFilesIds.length && file.deleteAdditionalFilesIds.forEach(val => data.append("deleteAdditionalFilesIds", val));
    if (!!file.logos.length && file.logos.filter(el => !el.id)) {
      file.logos.filter(el => !el.id).forEach(val => data.append("logos", val));
    }
    !!file.deleteLogosIds.length && file.deleteLogosIds.forEach(val => data.append("deleteLogosIds", val));
    data.append("description", file.description);
    data.append("forLogos", file.forLogos);
    !file.mainFile.id && data.append("mainFile", file.mainFile);
    data.append("mainImages", file.mainImages);
    data.append("title", file.title);
    data.append("type", file.type);
    !file.mainFile.size && window.scrollTo({ top: 400, behavior: "smooth" });
    if (file.id) {
      file.mainFile.size && dispatch(editPrivateFileRequest(data, file.id));
    } else {
      file.mainFile.size && dispatch(addPrivateFileRequest(data));
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 300, behavior: "smooth" });
  };

  const options = [
    { title: "Für Sie", value: "FOR_YOU" },
    { title: "Digital", value: "DIGITAL" },
    { title: "Innovativ", value: "INNOVATIVE" },
  ];

  return (
    <section className={`${classes.innerFormSection} container_admin`}>
      <Typography align="center" variant="h2" className={classes.innerFormHeader}>
        Private Inhalte erstellen und bearbeiten
      </Typography>
      <Typography align="center" className={classes.innerFormDescription}>
        {file.id ? `Bearbeiten Sie Private Inhalt #${file.id}` : "Neue Private Inhalt-Erstellung"}
      </Typography>
      <ValidatorForm
        noValidate={false}
        onSubmit={() => submitForm()}
        onError={() => scrollToTop()}
        autoComplete="off"
        className={classes.innerForm}
      >
        <TextValidator
          label="Titel"
          variant="outlined"
          name="title"
          value={file.title}
          onChange={e => handleFieldChange(e)}
          className={classes.innerFormField}
          inputProps={{
            maxLength: 150,
          }}
          validators={["required"]}
          errorMessages={["Dieses Feld wird benötigt"]}
        />

        <TextValidator
          label="Beschreibung"
          variant="outlined"
          name="description"
          value={file.description}
          onChange={(e) => handleFieldChange(e)}
          className={`${classes.innerFormField} ${classes.innerFormTextarea}`}
          multiline
          rows={5}
          rowsMax={5}
          inputProps={{ maxLength: 300 }}
          validators={["required"]}
          errorMessages={["Dieses Feld wird benötigt"]}
        />

        <SelectContentType selectedOption={file.type} selectOption={editPrivateFileField} options={options} />

        <PrivateFileUpload />
        {/* <PrivateAdditionalFilesUpload /> */}
        <PrivateFileCroppedMainImageUpload />
        <PrivateFileLogosUpload />

        <div className={classes.innerFormSubmitBlock}>
          {actionLoading ? (
            <Preloader />
          ) : (
            <Button variant="contained" className={classes.innerFormSubmitBtn} type="submit">
              Speichern
            </Button>
          )}
        </div>
      </ValidatorForm>
      <PrivateFileSuccessModal />
    </section>
  );
};
