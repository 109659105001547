import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import mergeImages from "merge-images-v2";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { LogoItem } from "../сommon/LogoItem";
import { Preloader } from "../сommon/Preloader";
import SaveIcon from "@material-ui/icons/Save";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

import { getMainImageForMergeRequest, getLogosIdsRequest } from "../../store/actions";

import useStyles from "./useStyles";

/**
 * @desc function for create watermark page
 * @returns {JSX.Element}
 */
export const MergeImagesSection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { mainImage, mainImageLoading, logosIds, logosLoading, resultFormat } = useSelector(({ privateFiles }) => privateFiles);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [logos, setLogos] = useState([]);
  const [mergedResult, setMergedResult] = useState("");
  const [waterMarkImages, setWaterMarkImages] = useState([]);
  const resultImg = useRef(null);
  const resultWrapper = useRef(null);

  useEffect(() => {
    dispatch(getMainImageForMergeRequest());
    dispatch(getLogosIdsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const allLogos = [...logosIds];
    setLogos(allLogos)
  }, [logosIds]);

  const loading = mainImageLoading || logosLoading;

  useEffect(() => {
    let cleanup = false;
    mainImage && mergeImages([{ src: mainImage }, ...waterMarkImages], { format: resultFormat }).then(b64 => !cleanup && setMergedResult(b64));
    return () => (cleanup = true);
  }, [mainImage, waterMarkImages, resultFormat]);

  const onSelectLogo = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      const newLogo = { src: url, id: new Date(), contentType: file.type, contentLength: file.size }
      const allLogos = [...logos, newLogo];
      setLogos(allLogos)
    }
  };

  const handleLogoClick = e => {
    if (selectedLogo && resultWrapper?.current && resultImg?.current) {
      const coords = resultWrapper.current.getBoundingClientRect();
      const x = (e.clientX - coords.x) * resultImg.current.naturalWidth / coords.width;
      const y = (e.clientY - coords.y) * resultImg.current.naturalHeight / coords.height;

      setWaterMarkImages((prev) =>
        prev.map((item) => {
          if (item.id === selectedLogo) {
            return { ...item, x, y };
          }
          return item;
        })
      );
    }
  };

  return (
    <section className={`${classes.merge_image_section} container`}>
      <Typography
        variant="h1"
        align="center"
        className={classes.merge_image_section_title}
      >
        Platzierung des Logos
      </Typography>

      <Typography align="center" className={classes.logos_description}>
        Bitte laden Sie als erstes Ihr Unternehmenslogo hier hoch. Bitte
        verwenden Sie dafür die Formate png, svg oder jpg. Nun bewegen Sie das
        Logo in den weißen Logobereich und platzieren es neben dem
        Kampagnenlogo. Über die vergößern- und verkleinern-Funktion passen Sie
        ihr Logo optimal an. Fertig, Sie können ihr Motiv herunterladen.
      </Typography>

      {loading ? (
        <Preloader className={classes.preloader_wrap} />
      ) : (
        <>
          <div className={classes.logos_wrap}>
            {logos.map((logo) => {
              const logoUrl = logo.src ? logo.src : "";
              return (
                <LogoItem
                  key={logo.id}
                  logo={logo}
                  logoUrl={logoUrl}
                  selectedLogo={selectedLogo}
                  setSelectedLogo={setSelectedLogo}
                  waterMarkImages={waterMarkImages}
                  setWaterMarkImages={setWaterMarkImages}
                />
              );
            })}
          </div>
          <Button component="label" className={classes.upload_btn} startIcon={<CameraAltIcon />}>
            Ihr Logo
            <input type="file" accept="image/*" onChange={e => onSelectLogo(e)} hidden />
          </Button>
          <div
            ref={resultWrapper}
            onClick={handleLogoClick}
            className={`${classes.result_wrapper} ${selectedLogo ? classes.result_wrapper_cursor : ""}`}
          >
            {mainImage && mergedResult && (
              <img ref={resultImg} className={classes.result_img} src={mergedResult} alt="merged images" />
            )}
          </div>
        </>
      )}

      <div className={classes.waterMark_controls}>
        {mainImage && !mainImageLoading && mergedResult && (
          <Button
            variant="contained"
            component="a"
            href={mergedResult}
            download
            className={classes.waterMark_btn}
            startIcon={<SaveIcon />}
          >
            Herunterladen
          </Button>
        )}
      </div>
    </section>
  );
};

