import { ACTION } from "../constants/types";

const INIT_STATE = {
  privateFiles: [],
  pagination: {
    page: 0,
    totalCount: 0,
  },
  query: {
    page: 0,
    size: 15,
    contentType: "",
    sort: "orderRank",
  },
  privateFilesForDownload: [],
  loading: false,
  error: null,
  actionLoading: false,
  actionError: null,
  selectedContent: { id: null },
  imageForMergeId: null,
  mainImage: "",
  mainImageLoading: false,
  mainImageError: null,
  contentForMergeId: null,
  resultFormat: "image/jpeg",
  logosIds: [],
  logosLoading: false,
  logosError: null,
};

export const privateFilesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION.SET_PRIVATE_CONTENT_FOR_DOWNLOAD:
      return { ...state, privateFilesForDownload: action.payload };

    case ACTION.SET_PRIVATE_CONTENT_QUERY:
      return { ...state, query: { ...state.query, ...action.payload } };

    case ACTION.GET_PRIVATE_CONTENT_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION.GET_PRIVATE_CONTENT_SUCCESS:
      return {
        ...state,
        privateFiles: action.payload.privateFiles,
        pagination: { page: action.payload.page, totalCount: action.payload.totalCount },
        loading: false,
      };
    case ACTION.GET_PRIVATE_CONTENT_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case ACTION.SET_ADD_PRIVATE_CONTENT_QUERY:
      return { ...state, query: { ...state.query, page: action.payload } };

    case ACTION.ADD_PRIVATE_CONTENT_REQUEST:
      return { ...state, loading: true, error: null };
    case ACTION.ADD_PRIVATE_CONTENT_SUCCESS:
      return {
        ...state,
        privateFiles: [...state.privateFiles, ...action.payload.privateFiles],
        pagination: { page: action.payload.page, totalCount: action.payload.totalCount },
        loading: false,
      };
    case ACTION.ADD_PRIVATE_CONTENT_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case ACTION.DOWNLOAD_PRIVATE_CONTENT_REQUEST:
      return { ...state, actionLoading: true, actionError: null };
    case ACTION.DOWNLOAD_PRIVATE_CONTENT_SUCCESS:
      return { ...state, actionLoading: false };
    case ACTION.DOWNLOAD_PRIVATE_CONTENT_FAILURE:
      return { ...state, actionError: action.payload, actionLoading: false };

    case ACTION.SELECT_PRIVATE_CONTENT:
      return { ...state, selectedContent: action.payload };

    case ACTION.CLEAN_PRIVATE_CONTENT:
      return INIT_STATE;

    case ACTION.SET_PRIVATE_CONTENT_FOR_MERGE:
      return {
        ...state,
        imageForMergeId: action.payload.imageForMergeId,
        contentForMergeId: action.payload.contentForMergeId,
        resultFormat: action.payload.resultFormat,
      };

    case ACTION.GET_MAIN_IMAGE_FOR_MERGE_REQUEST:
      return { ...state, mainImageLoading: true, mainImageError: null };
    case ACTION.GET_MAIN_IMAGE_FOR_MERGE_SUCCESS:
      return { ...state, mainImage: action.payload, mainImageLoading: false };
    case ACTION.GET_MAIN_IMAGE_FOR_MERGE_FAILURE:
      return { ...state, mainImageError: action.payload, mainImageLoading: false };

    case ACTION.GET_LOGOS_IDS_REQUEST:
      return { ...state, logosLoading: true, logosError: null };
    case ACTION.GET_LOGOS_IDS_SUCCESS:
      return { ...state, logosIds: action.payload, logosLoading: false };
    case ACTION.GET_LOGOS_IDS_FAILURE:
      return { ...state, logosError: action.payload, logosLoading: false };

    default:
      return state;
  }
};
