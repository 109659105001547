import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logOutSuccess } from "./store/actions";

export const AxiosConfig = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(({ login }) => login);
  let isTokenRefreshing = false;
  let refreshSubscribers = [];

  const onAccessTokenFetched = accessToken => {
    refreshSubscribers = refreshSubscribers.forEach(callback => callback(accessToken));
  };

  const addSubscriber = callback => refreshSubscribers.push(callback);

  // setting axios defaults
  axios.defaults.baseURL = "https://wirwirken.de/api/";
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("bpiAuthToken");

  // Add a request interceptor
  /*  axios.interceptors.request.use(function(config) {
        // Do something before request is sent
        return config;
      }, function(error) {
        // Do something with request error
        return Promise.reject(error);
      });*/

  // Add a response interceptor
  axios.interceptors.response.use(
    response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    error => {
      const { config, response: { status } } = error;
      const originalRequest = config;
      if (token && status === 401 && config.url !== "uaa/token/refresh") {
        const refreshToken = localStorage.getItem("bpiRefreshToken");
        if (!isTokenRefreshing && refreshToken) {
          isTokenRefreshing = true;
          axios
            .post("uaa/token/refresh", { refreshToken })
            .then(res => {
              localStorage.setItem("bpiAuthToken", `Bearer ${res.data.accessToken}`);
              localStorage.setItem("bpiRefreshToken", res.data.refreshToken);
              isTokenRefreshing = false;
              axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.accessToken}`;
              onAccessTokenFetched(res.data.accessToken);
              refreshSubscribers = [];
            })
            .catch(error => {
              console.error(error);
              dispatch(logOutSuccess());
              isTokenRefreshing = false;
            });
        }

        return new Promise(resolve => {
          addSubscriber(accessToken => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(axios(originalRequest));
          });
        });
      }

      return Promise.reject(error);
    }
  );
};
