import { ACTION } from "../constants/types";

/**
 * @desc function for set private files pagination
 * @param {object} query - number of page, size per page
 * @return {{type: string, payload: *}}
 */
export const setPrivateContentQuery = query => {
  return {
    type: ACTION.SET_PRIVATE_CONTENT_QUERY,
    payload: query,
  };
};

/**
 * @desc function for successful receipt private files
 * @param {object} - object with private files, page, totalCount
 * @return {{type: string, payload: *}}
 */
export const getPrivateContentSuccess = ({ privateFiles, page, totalCount }) => {
  return {
    type: ACTION.GET_PRIVATE_CONTENT_SUCCESS,
    payload: {
      privateFiles,
      page,
      totalCount,
    },
  };
};

/**
 * @desc function for failed receipt private files
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const getPrivateContentFailure = error => {
  return {
    type: ACTION.GET_PRIVATE_CONTENT_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for set add private files query
 * @param {number} query - number of private files page
 * @return {{type: string, payload: *}}
 */
export const setAddPrivateContentQuery = query => {
  return {
    type: ACTION.SET_ADD_PRIVATE_CONTENT_QUERY,
    payload: query,
  };
};

/**
 * @desc function for successful addition private files
 * @param {object} - object with private files, page, totalCount
 * @return {{type: string, payload: *}}
 */
export const addPrivateContentSuccess = ({ privateFiles, page, totalCount }) => {
  return {
    type: ACTION.ADD_PRIVATE_CONTENT_SUCCESS,
    payload: {
      privateFiles,
      page,
      totalCount,
    },
  };
};

/**
 * @desc function for failed addition private files
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const addPrivateContentFailure = error => {
  return {
    type: ACTION.ADD_PRIVATE_CONTENT_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for set private files for download
 * @param {array} payload - array with private files
 * @return {{type: string, payload: *}}
 */
export const setPrivateContentForDownload = payload => {
  return {
    type: ACTION.SET_PRIVATE_CONTENT_FOR_DOWNLOAD,
    payload,
  };
};

/**
 * @desc function for download private files
 * @return {{type: string}}
 */
export const downloadPrivateContentRequest = () => {
  return {
    type: ACTION.DOWNLOAD_PRIVATE_CONTENT_REQUEST,
  };
};

/**
 * @desc function for successful download private files
 * @return {{type: string}}
 */
export const downloadPrivateContentSuccess = () => {
  return {
    type: ACTION.DOWNLOAD_PRIVATE_CONTENT_SUCCESS,
  };
};

/**
 * @desc function for failed download private files
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const downloadPrivateContentFailure = error => {
  return {
    type: ACTION.DOWNLOAD_PRIVATE_CONTENT_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for select active private file
 * @param {object} content - active private file
 * @return {{type: string, payload: *}}
 */
export const selectPrivateContent = content => {
  return {
    type: ACTION.SELECT_PRIVATE_CONTENT,
    payload: content,
  };
};

/**
 * @desc function for clean selected private files
 * @return {{type: string}}
 */
export const cleanPrivateContent = () => {
  return {
    type: ACTION.CLEAN_PRIVATE_CONTENT,
  };
};

/**
 * @desc function for select private file for watermark
 * @param {string} imageForMergeId - watermark image
 * @param {number} contentForMergeId - image for watermark
 * @param {string} resultFormat - image format
 * @return {{type: string, payload: *}}
 */
export const selectPrivateContentForImagesMerge = (imageForMergeId, contentForMergeId, resultFormat) => {
  return {
    type: ACTION.SET_PRIVATE_CONTENT_FOR_MERGE,
    payload: { imageForMergeId, contentForMergeId, resultFormat },
  };
};

/**
 * @desc function for receipt main image for merge
 * @return {{type: string}}
 */
export const getMainImageForMergeRequest = () => {
  return {
    type: ACTION.GET_MAIN_IMAGE_FOR_MERGE_REQUEST,
  };
};

/**
 * @desc function for successful receipt main image for merge
 * @param {string} mainImg - main image for merge
 * @return {{type: string, payload: *}}
 */
export const getMainImageForMergeSuccess = mainImg => {
  return {
    type: ACTION.GET_MAIN_IMAGE_FOR_MERGE_SUCCESS,
    payload: mainImg,
  };
};

/**
 * @desc function for failed receipt main image for merge
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const getMainImageForMergeFailure = error => {
  return {
    type: ACTION.GET_MAIN_IMAGE_FOR_MERGE_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for receipt watermarks for merge
 * @return {{type: string}}
 */
export const getLogosIdsRequest = () => {
  return {
    type: ACTION.GET_LOGOS_IDS_REQUEST,
  };
};

/**
 * @desc function for successful receipt watermarks for merge
 * @param {object} logosIds - watermarks ids
 * @return {{type: string, payload: *}}
 */
export const getLogosIdsSuccess = logosIds => {
  return {
    type: ACTION.GET_LOGOS_IDS_SUCCESS,
    payload: logosIds,
  };
};

/**
 * @desc function for failed receipt watermarks for merge
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const getLogosIdsFailure = error => {
  return {
    type: ACTION.GET_LOGOS_IDS_FAILURE,
    payload: error,
  };
};
