import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  decor_container: {
    position: "relative",
    display: "inline-flex",
    width: "100%",
    padding: "0 0 35px 20px",
    marginBottom: 35,
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 25px 20px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 0 17px 20px",
    },
  },
  decor_logo: {
    [theme.breakpoints.down("sm")]: {
      width: 130,
    },
    [theme.breakpoints.down("xs")]: {
      width: 100,
    },
  },
  decor_horizontal: {
    position: "absolute",
    left: 0,
    bottom: 15,
    width: 62,
    height: 3,
  },
  decor_vertical: {
    position: "absolute",
    left: 0,
    bottom: 15,
    width: 3,
    height: 76,
  },
  decor_text: {
    position: "absolute",
    left: 70,
    bottom: -7,
    fontSize: 28,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      bottom: 0,
      fontSize: 20,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  }
}));

export default useStyles;
