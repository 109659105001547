import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { Preloader } from "../../сommon/Preloader";
import { ErrorHandler } from "../../сommon/ErrorHandler";
import ImageCropper from "../../ImagesCropper";

import { addBlogsCroppedImageRequest } from "../../../store/actions";

import useStyles from "../useStyles";

/**
 * @desc function for create blog cropped image for upload
 * @returns {JSX.Element}
 */
export const BlogCroppedImageUpload = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { blog, croppedImageUploading, croppedImageUploadError } = useSelector(({ adminBlogs }) => adminBlogs);
  const [cropObject, setCropObject] = useState({
    src: null,
    crop: {
      unit: "%",
      width: 50,
      aspect: 1,
    },
    fileUrl: null,
  });
  const [resultCrop, setResultCrop] = useState(null);
  const [sizeAlertOpen, setSizeAlertOpen] = useState(false);
  const uploadCroppedImage = () => {
    dispatch(addBlogsCroppedImageRequest(resultCrop));
  };

    return (
    <div className={classes.upload_block}>
      <Typography align="center" className={classes.upload_title}>
        Laden eines Bildes für die Startseite
      </Typography>

      <Typography align="center" className={classes.upload_description}>
        Bitte wählen Sie das Bild für die Startseite aus und beschneiden Sie es
      </Typography>

      <ImageCropper
        setResultCrop={setResultCrop}
        setCropObject={setCropObject}
        cropObject={cropObject}
        classes={classes}
        setSizeAlertOpen={setSizeAlertOpen}
        aspect={1}
        value={blog.croppedImage}
        validation={true}
      />

      <Snackbar
        open={sizeAlertOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => setSizeAlertOpen(false)}
      >
        <MuiAlert elevation={6} variant="filled" onClose={() => setSizeAlertOpen(false)} severity="error">
          Dateigröße größer als 10 MB
        </MuiAlert>
      </Snackbar>

      {cropObject.fileUrl && (
        <>
          <Typography align="center" className={classes.upload_description}>
            Zugeschnittene Bildvorschau
          </Typography>
          <div className={classes.upload_preview_img_box}>
            <img className={classes.upload_preview_img} alt="Crop preview" src={cropObject.fileUrl} />
          </div>
        </>
      )}

      {!!cropObject.fileUrl &&
        (croppedImageUploading ? (
          <Preloader />
        ) : (
          <Button className={classes.upload_btn} onClick={() => uploadCroppedImage()} startIcon={<CameraAltIcon />}>
            Bild speichern
          </Button>
        ))}

      {!!croppedImageUploadError && <ErrorHandler error={croppedImageUploadError} />}

      {blog.croppedImage && !croppedImageUploading && (
        <>
          <Typography align="center" className={classes.upload_description}>
            Gespeichertes geschnittenes Bild
          </Typography>
          <div className={classes.upload_preview_img_box}>
            <img className={classes.upload_preview_img} alt="Blog cropped preview" src={blog.croppedImage} />
          </div>
        </>
      )}
    </div>
  );
};
