import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

import { TypeHandler } from "../сommon/TypeHandler";

import styles from "./BlogsList.module.scss";
import { setBlogsQuery } from "../../store/actions";

/**
 * @desc function for render blogs list
 * @returns {JSX.Element}
 */
export const BlogsList = ({ filter = "" }) => {
  const dispatch = useDispatch();
  const { blogs, query, loading } = useSelector(({ blogs }) => blogs);
  const [preparedBlogs, setPreparedBlogs] = useState([]);
  const location = useLocation();

  useEffect(() => {
    dispatch(setBlogsQuery({ contentType: filter, page: 0 }));
    location.pathname !== "/home" && scroller.scrollTo("scroll-to-filter", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const blogBlocksGenerator = useCallback(
    (size) => {
      let blogsBlockSubarray = [];

      for (let i = 0; i < Math.ceil(blogs.length / size); i++) {
        blogsBlockSubarray[i] = blogs.slice(i * size, i * size + size);
      }

      return blogsBlockSubarray;
    },
    [blogs]
  );

  useEffect(() => {
    setPreparedBlogs(blogBlocksGenerator(query.size));
  }, [blogBlocksGenerator, query.size]);

  const blogsGridGenerator = amount => {
    switch (amount) {
      case 1:
        return "blogsBlockOne";
      case 2:
        return "blogsBlockTwo";
      case 3:
        return "blogsBlockThree";
      case 4:
        return "blogsBlockFour";
      case 5:
        return "blogsBlockFive";
      case 6:
        return "blogsBlockSix";
      case 7:
        return "blogsBlockSeven";
      case 8:
        return "blogsBlockEight";
      case 9:
        return "blogsBlockNine";
      case 10:
        return "blogsBlockTen";
      default:
    }
  };

  const indexModification = (index) => {
    switch (index) {
      case 0:
        return "item-one";
      case 1:
        return "item-two";
      case 2:
        return "item-three";
      case 3:
        return "item-four";
      case 4:
        return "item-five";
      case 5:
        return "item-six";
      case 6:
        return "item-seven";
      case 7:
        return "item-eight";
      case 8:
        return "item-nine";
      case 9:
        return "item-ten";
      default:
    }
  };

  return (
    <div className={styles.blogsList}>
      {loading && <div className={styles.blogsListOverlay}></div>}
      {preparedBlogs.map((blog, i) => (
        <div key={i} className={[`${styles.blogsBlock} ${styles[blogsGridGenerator(blog.length)]}`]}>
          {blog.map((blog, index) => (
            <Link
              key={blog.id}
              to={`/blog/${blog.prettyUrl}`}
              className={[`${styles[`item-${blog.type}`]} ${styles[indexModification(index)]}`]}
              style={{ backgroundImage: `url(${blog.croppedImage})` }}
            >
              <div className={styles[`overlay-${blog.type}`]}>
                <div className={styles.overlayContent}>
                  <p className={styles.overlayType} style={{ align: "justify" }}>
                    <TypeHandler type={blog.type} />
                  </p>
                  <p className={styles.overlayTitle} style={{ align: "justify" }}>
                    {blog.title}
                  </p>
                  <p className={styles.overlayText} style={{ align: "justify" }}>
                    {blog.description}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};
