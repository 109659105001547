import axios from "axios";
import { put, call, all, takeLatest, take, fork, select, cancel } from "redux-saga/effects";
import { ACTION } from "../constants/types";
import {
  getAdminBlogsSuccess,
  getAdminBlogsFailure,
  addBlogsMainImageSuccess,
  addBlogsMainImageFailure,
  deleteBlogsMainImageSuccess,
  deleteBlogsMainImageFailure,
  addBlogsCroppedImageSuccess,
  addBlogsCroppedImageFailure,
  addBlogSuccess,
  addBlogFailure,
  editBlogSuccess,
  editBlogFailure,
  deleteBlogSuccess,
  deleteBlogFailure,
  openSuccessBlogModal,
  enableBlogFailure,
  editBlogOrderSuccess,
  editBlogOrderFailure,
} from "../actions/";

/**
 * @desc request for receipt admin blogs
 * @returns {object} list of admin blogs and pagination
 */
export function* fetchAdminBlogs() {
  yield put({ type: ACTION.GET_ADMIN_BLOGS_REQUEST });
  const { adminBlogs: { query: queryParams } } = yield select();
  try {
    const res = yield call(axios.get, "media/news", { params: queryParams });
    yield put(
      getAdminBlogsSuccess({ blogs: res.data.content, page: res.data.pageable.pageNumber, totalCount: res.data.totalElements })
    );
  } catch (error) {
    yield put(getAdminBlogsFailure(error));
  }
}

/**
 * @desc request for admin blog main image upload
 * @param {object} file - image file for upload
 * @returns {string} image file url
 */
function* fetchBlogMainImageUpload(file) {
  try {
    const res = yield call(axios.post, "file/file/public", file);
    yield put(addBlogsMainImageSuccess(res.data.file.url));
  } catch (error) {
    yield put(addBlogsMainImageFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {object} image file for upload
 */
export function* blogMainImageUpload() {
  while (true) {
    const { payload: file } = yield take(ACTION.ADD_BLOG_MAIN_IMAGE_REQUEST);
    const task = yield fork(fetchBlogMainImageUpload, file);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for delete admin blog main images
 * @param {string} url - admin blog main image url
 * @returns {array} new main images array
 */
function* fetchDeleteBlogMainImage(url) {
  try {
    const { adminBlogs: { blog } } = yield select();
    const res = yield call(axios.delete, `file/file/public?url=${url}`);
    yield res && put(deleteBlogsMainImageSuccess(blog.mainImages.filter(img => img !== url)));
  } catch (error) {
    yield put(deleteBlogsMainImageFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {string} main image url for delete
 */
export function* deleteBlogMainImage() {
  while (true) {
    const { payload: url } = yield take(ACTION.DELETE_BLOG_MAIN_IMAGE_REQUEST);
    const task = yield fork(fetchDeleteBlogMainImage, url);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for admin blog cropped image upload
 * @param {object} file - object with admin blog cropped image
 * @returns {string} admin blog cropped image url for upload
 */
function* fetchBlogCroppedImageUpload(file) {
  try {
    const res = yield call(axios.post, "file/file/public", file);
    yield put(addBlogsCroppedImageSuccess(res.data.file.url));
  } catch (error) {
    yield put(addBlogsCroppedImageFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {object} object with admin blog cropped image
 */
export function* blogCroppedImageUpload() {
  while (true) {
    const { payload: file } = yield take(ACTION.ADD_BLOG_CROPPED_IMAGE_REQUEST);
    const task = yield fork(fetchBlogCroppedImageUpload, file);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for add admin blog
 * @param {object} blog - object with admin blog
 * @returns {object} success message
 */
function* fetchAddBlog(blog) {
  try {
    const res = yield call(axios.post, "media/news", blog);
    yield put(addBlogSuccess());
    yield put(openSuccessBlogModal(res.data));
  } catch (error) {
    yield put(addBlogFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {object} - object with admin blog
 */
export function* addBlog() {
  while (true) {
    const { payload: blog } = yield take(ACTION.ADD_BLOG_REQUEST);
    const task = yield fork(fetchAddBlog, blog);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for edit admin blog
 * @param {object} blog - object with admin blog
 * @param {number} blogId - admin blog id
 * @returns {object} admin blog
 */
function* fetchEditBlog(blog, blogId) {
  try {
    const res = yield call(axios.put, `media/news/${blogId}`, blog);
    yield put(editBlogSuccess());
    yield put(openSuccessBlogModal(res.data));
  } catch (error) {
    yield put(editBlogFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {blog: object, blogId: number}
 */
export function* editBlog() {
  while (true) {
    const { payload: { blog, blogId } } = yield take(ACTION.EDIT_BLOG_REQUEST);
    const task = yield fork(fetchEditBlog, blog, blogId);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for delete admin blog
 * @param {number} blogId - admin blog id
 */
function* fetchDeleteBlog(blogId) {
  try {
    yield call(axios.delete, `media/news/${blogId}`);
    yield put(deleteBlogSuccess());
    yield fork(fetchAdminBlogs);
  } catch (error) {
    yield put(deleteBlogFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {number} admin blog id
 */
export function* deleteBlog() {
  while (true) {
    const { payload: blogId } = yield take(ACTION.DELETE_BLOG_REQUEST);
    const task = yield fork(fetchDeleteBlog, blogId);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for enable admin blog
 * @param {number} blogId - admin blog id
 * @param {boolean} active - toggle value
 */
export function* fetchEnableBlog(blogId, active) {
  try {
    const res = yield call(axios.put, `media/news/${blogId}/activate`, JSON.stringify(active), { headers: { "Content-Type": "application/json" }});
    yield res.data && fork(fetchAdminBlogs);
  } catch (error) {
    yield put(enableBlogFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {id: number, active: boolean}
 */
export function* enableBlog() {
  while (true) {
    const { payload: { id, active } } = yield take(ACTION.ENABLE_ADMIN_BLOG_REQUEST);
    const task = yield fork(fetchEnableBlog, id, active);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

/**
 * @desc request for enable admin blog
 * @param {number} blogId - admin blog id
 * @param {number} blogOrder - admin blog order
 */
function* fetchEditBlogOrder(blogId, blogOrder) {
  try {
    yield call(axios.put, `media/news/${blogId}/order`, blogOrder, { headers: { "Content-Type": "application/json" }});
    yield put(editBlogOrderSuccess());
    yield fork(fetchAdminBlogs);
  } catch (error) {
    yield put(editBlogOrderFailure(error));
  }
}

/**
 * @desc function for action interception
 * @returns {blogId: number, blogOrder: number}
 */
export function* editBlogOrder() {
  while (true) {
    const { payload: { blogId, blogOrder } } = yield take(ACTION.EDIT_BLOG_ORDER_REQUEST);
    const task = yield fork(fetchEditBlogOrder, blogId, blogOrder);
    const action = yield take("*");
    if (action.type === ACTION.LOGOUT_USER_SUCCESS) {
      yield cancel(task);
    }
  }
}

export function* watchAdminBlogsManagement() {
  yield all([
    takeLatest(ACTION.SET_ADMIN_BLOGS_QUERY, fetchAdminBlogs),
    fork(blogMainImageUpload),
    fork(deleteBlogMainImage),
    fork(blogCroppedImageUpload),
    fork(addBlog),
    fork(editBlog),
    fork(deleteBlog),
    fork(enableBlog),
    fork(editBlogOrder),
  ]);
}
