import { useHistory } from "react-router-dom";

import { Button } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import useStyles from "./useStyles";

/**
 * @desc function for create chevron button
 * @returns {JSX.Element}
 */
export const ChevronButton = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Button variant="contained" onClick={() => history.push("/home")} className={classes.goBack_btn}>
      <ChevronLeftIcon className={classes.goBack_icon} />
    </Button>
  );
};
