/**
 * @desc function for convert type to string
 * @param {string} type - content type
 * @returns {JSX.Element}
 */
export const TypeHandler = ({type}) => {
  const types = {
    "CENTRAL": "Entdecken Sie weitere Beiträge",
    "FOR_YOU": "Für Sie",
    "DIGITAL": "Digital",
    "INNOVATIVE": "Innovativ",
};

  return types[type]
};
