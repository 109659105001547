import React from "react";

import { Header } from "../../components/сommon/Header";
import { BackButton } from "../../components/сommon/BackButton";
import { LoginForm } from "../../components/forms/LoginForm";

/**
 * @desc Auth sign in UI
 * @return {JSX.Element}
 */
export const SignInPage = () => (
  <>
    <Header />
    <BackButton />
    <LoginForm />
  </>
);
