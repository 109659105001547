export const HeaderLogoIcon = ({ className }) => (
  <svg className={className} width="217" height="121" viewBox="0 0 217 121" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M65.3234 0.683105L59.8284 23.7891H59.7344L54.0054 0.683105H47.1004L41.2764 23.5071H41.1834L35.8764 0.683105H28.5024L37.3794 34.2141H44.8474L50.4344 11.3901H50.5344L56.2144 34.2141H63.5404L72.5564 0.683105H65.3234Z" fill="#2B2B2A" />
    <path d="M83.3095 0.683105H75.9365V34.2141H83.3095V0.683105Z" fill="#2B2B2A" />
    <path d="M105.245 15.8509C106.607 15.9536 107.959 15.5521 109.045 14.7229C109.506 14.2385 109.857 13.6608 110.076 13.029C110.295 12.3971 110.375 11.7256 110.313 11.0599C110.372 10.4123 110.289 9.75959 110.07 9.1473C109.851 8.535 109.501 7.9779 109.045 7.51488C107.952 6.70266 106.602 6.31208 105.245 6.41488H97.1635V15.8549L105.245 15.8509ZM107.873 0.682876C109.261 0.661879 110.64 0.908718 111.935 1.40988C113.09 1.86416 114.144 2.54405 115.035 3.40988C115.886 4.24303 116.557 5.24239 117.007 6.34588C117.466 7.48728 117.697 8.70757 117.688 9.93788C117.733 11.7228 117.304 13.488 116.445 15.0529C115.507 16.5819 114.073 17.7424 112.383 18.3399V18.4339C113.211 18.6427 113.981 19.0358 114.637 19.5839C115.231 20.0996 115.724 20.7207 116.093 21.4159C116.478 22.1468 116.755 22.93 116.915 23.7409C117.086 24.5779 117.204 25.4249 117.268 26.2769C117.298 26.8102 117.33 27.4362 117.362 28.1549C117.394 28.8735 117.448 29.6095 117.526 30.3629C117.598 31.0831 117.723 31.7971 117.901 32.4989C118.047 33.1151 118.31 33.6974 118.677 34.2139H111.302C110.862 32.9921 110.609 31.7111 110.551 30.4139C110.456 28.9419 110.315 27.5325 110.128 26.1859C110.02 24.7644 109.459 23.415 108.528 22.3359C107.409 21.4187 105.975 20.9796 104.535 21.1139H97.1635V34.2139H89.7905V0.682876H107.873Z" fill="#2B2B2A" />
    <path d="M56.2154 92.7317L50.5304 69.9067H50.4374L44.8484 92.7317H37.3804L28.5044 59.1987H35.8774L41.1844 82.0237H41.2774L47.1024 59.1987H54.0024L59.7354 82.3047H59.8284L65.3284 59.1987H72.5604L63.5394 92.7317H56.2154Z" fill="#2B2B2A" />
    <path d="M83.3095 59.1987H75.9365V92.7317H83.3095V59.1987Z" fill="#2B2B2A" />
    <path d="M105.245 74.368C106.607 74.4706 107.959 74.0692 109.045 73.2399C109.506 72.7556 109.857 72.1779 110.076 71.546C110.295 70.9141 110.375 70.2427 110.313 69.577C110.372 68.9294 110.289 68.2767 110.07 67.6644C109.851 67.0521 109.501 66.495 109.045 66.032C107.952 65.2197 106.602 64.8292 105.245 64.9319H97.1635V74.3719L105.245 74.368ZM107.874 59.199C109.262 59.1783 110.641 59.4254 111.936 59.9269C113.091 60.3812 114.145 61.0611 115.036 61.9269C115.887 62.7601 116.558 63.7595 117.008 64.8629C117.467 66.0043 117.698 67.2246 117.689 68.4549C117.735 70.2414 117.305 72.008 116.445 73.574C115.507 75.103 114.073 76.2634 112.383 76.8609V76.9549C113.211 77.1638 113.981 77.5569 114.637 78.1049C115.231 78.6206 115.724 79.2417 116.093 79.937C116.478 80.6679 116.755 81.4511 116.915 82.2619C117.086 83.0989 117.204 83.946 117.268 84.798C117.298 85.3313 117.33 85.9573 117.362 86.6759C117.394 87.3946 117.448 88.1306 117.526 88.8839C117.598 89.6042 117.723 90.3181 117.901 91.02C118.047 91.6362 118.31 92.2185 118.677 92.7349H111.302C110.862 91.5132 110.609 90.2321 110.551 88.935C110.456 87.463 110.315 86.0536 110.128 84.7069C110.02 83.2855 109.459 81.9361 108.528 80.8569C107.409 79.9398 105.975 79.5006 104.535 79.6349H97.1635V92.7349H89.7905V59.199H107.874Z" fill="#2B2B2A" />
    <path d="M131.072 59.1987V73.0987L144.172 59.1987H153.377L140.277 72.4427L154.649 92.7317H145.398L135.298 77.6557L131.072 81.9297V92.7297H123.699V59.1987H131.072Z" fill="#2B2B2A" />
    <path d="M182.685 59.1987V65.3987H164.98V72.5837H181.228V78.3127H164.98V86.5317H183.06V92.7317H157.606V59.1987H182.685Z" fill="#2B2B2A" />
    <path d="M209.453 59.1989V81.6909H209.36L195.36 59.1909H188.033V92.7319H194.938V70.2819H195.031L208.979 92.7309H216.354V59.1989H209.453Z" fill="#2B2B2A" />
    <path d="M2.10645 45.1201V119.204H61.0724" stroke="#2B2B2A" strokeWidth="2.62" strokeMiterlimit="10" />
  </svg>
);