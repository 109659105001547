import Typography from "@material-ui/core/Typography";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import { BlogMainSlider } from "../сommon/BlogMainSlider";

import useStyles from "./useStyles";
import { ShareIcon } from "../сommon/icons/ShareIcon";
import { FacebookIcon } from "../сommon/icons/FacebookIcon";
import { BlogDecorType } from "../сommon/BlogDecorType";
import { Intro } from "../../components/Intro";

/**
 * @desc function for create blog content from blog form
 * @param {string} title - blog title
 * @param {object} mainImages - blog main images
 * @param {boolean} preview - blog preview
 * @param {object} body - blog body
 * @param {string} type - blog type
 * @param {string} mainImageType - blog main image type
 * @returns {JSX.Element}
 */
export const BlogContent = ({ title, mainImages, preview, body, type, mainImageType }) => {
  const classes = useStyles();

  return (
    <section className={classes.blog_section}>
      {mainImageType === "IMAGE"
        ? <div className={classes.blog_main_slider}>
          <BlogMainSlider mainImages={mainImages} />
        </div>
        : <Intro url={mainImages} type={mainImageType} />
      }

      <BlogDecorType type={type} />

      <Typography variant="h1" className={classes.blog_header}>
        {title}
      </Typography>

      {!preview && (
        <div className={classes.blog_menu_dropdown_wrap}>
          <ShareIcon />
          <div className={`${classes.blog_menu_dropdown} dropdown_share_menu`}>
            <a
              href={`http://www.facebook.com/sharer.php?u=${window.location.href}`}
              target="_blank"
              rel="noreferrer"
              className={classes.blog_dropdown_link}
            >
              <FacebookIcon className={classes.blog_dropdown_icon} />
            </a>
            <a
              href={`http://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`}
              target="_blank"
              rel="noreferrer"
              className={classes.blog_dropdown_link}
            >
              <LinkedInIcon className={classes.blog_dropdown_icon} />
            </a>
            <a
              href={`https://twitter.com/share?url=${window.location.href}`}
              target="_blank"
              rel="noreferrer"
              className={classes.blog_dropdown_link}
            >
              <TwitterIcon className={classes.blog_dropdown_icon} />
            </a>
          </div>
        </div>
      )}

      <div className={classes.blog_body}>
        {body?.blocks?.map((el, id) => {
          if (el.type === "header") {
            switch (el.data.level) {
              case 1: return <h1 className={classes.blog_h1} key={id} dangerouslySetInnerHTML={{ __html: el.data.text }} />;
              case 2: return <h2 className={classes.blog_h2} key={id} dangerouslySetInnerHTML={{ __html: el.data.text }} />;
              case 3: return <h3 className={classes.blog_h3} key={id} dangerouslySetInnerHTML={{ __html: el.data.text }} />;
              case 4: return <h4 className={classes.blog_h4} key={id} dangerouslySetInnerHTML={{ __html: el.data.text }} />;
              case 5: return <h5 className={classes.blog_h5} key={id} dangerouslySetInnerHTML={{ __html: el.data.text }} />;
              case 6: return <h6 className={classes.blog_h6} key={id} dangerouslySetInnerHTML={{ __html: el.data.text }} />;
              default: ;
            }
          }
          if (el.type === "paragraph") {
            if (el.data.text.includes("href=")) {
              const textWithLink = el.data.text
                .split("<a href=")
                .filter(el => el)
                .map(el => {
                  if (el.includes("wirwirken.de")) {
                    return `<a class=${classes.blog_inner_link} href=${el}`
                  } else if (el.includes("http")) {
                    return `<a class=${classes.blog_outer_link} target="_blank" href=${el}`
                  }

                  return el;
                })
                .join("");

              return <p className={classes.blog_paragraph} key={id} dangerouslySetInnerHTML={{ __html: textWithLink }} />;
            }

            return <p className={classes.blog_paragraph} key={id} dangerouslySetInnerHTML={{ __html: el.data.text }} />;
          }
          if (el.type === "image") {
            const imageLink = el.data.caption.replace(/(&nbsp;|<br>)/g, '');
            return (
              <div key={id} className={classes.blog_img_wrap} style={{ maxWidth: `${!el.data.stretched && "800px"}` }}>
                {el.data.caption.includes('https://')
                  ? (
                    <a
                      className={el.data.stretched ? classes.blog_img_stretched : classes.blog_img} href={imageLink}
                      target='_blank'
                      rel="noopener noreferrer"
                    >
                      <img
                        className={el.data.stretched ? classes.blog_img_stretched : classes.blog_img}
                        src={el.data.file.url}
                        alt={`link to ${imageLink}`}
                      />
                    </a>
                  )
                  : <img className={el.data.stretched ? classes.blog_img_stretched : classes.blog_img} src={el.data.file.url} alt={el.data.caption} />
                }
              </div>
            );
          }
          if (el.type === "embed") {
            return (
              <div key={id} className={classes.blog_embed_wrap}>
                <iframe
                  title={el.data.caption}
                  allowFullScreen
                  className={classes.blog_embed}
                  frameBorder={0}
                  height={el.data.height}
                  src={el.data.embed}
                />
                <p className={classes.blog_embed_caption}>{el.data.caption}</p>
              </div>
            );
          }
          return <></>;
        })}
      </div>
    </section>
  );
};
