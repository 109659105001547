import axios from "axios";
import { put, call, all, takeLatest, select } from "redux-saga/effects";
import { ACTION } from "../constants/types";
import { getBlogsSuccess, getBlogsFailure, addBlogsSuccess, addBlogsFailure } from "../actions/";

/**
 * @desc request for receipt blogs
 * @returns {object} list of blogs and pagination
 */
export function* fetchBlogs() {
  yield put({ type: ACTION.GET_BLOGS_REQUEST });
  const { blogs: { query: queryParams } } = yield select();
  try {
    const res = yield call(axios.get, "media/news/active", { params: queryParams });
    yield put(
      getBlogsSuccess({ blogs: res.data.content, page: res.data.pageable.pageNumber, totalCount: res.data.totalElements })
    );
  } catch (error) {
    yield put(getBlogsFailure(error));
  }
}

/**
 * @desc request for add blogs
 * @returns {object} list of blogs and pagination
 */
export function* fetchAddBlogs() {
  yield put({ type: ACTION.ADD_BLOGS_REQUEST });
  const { blogs: { query: queryParams } } = yield select();
  try {
    const res = yield call(axios.get, "media/news/active", { params: queryParams });
    yield put(addBlogsSuccess({ blogs: res.data.content, page: res.data.pageable.pageNumber, totalCount: res.data.totalElements }));
  } catch (error) {
    yield put(addBlogsFailure(error));
  }
}

export function* watchBlogs() {
  yield all([takeLatest(ACTION.SET_BLOGS_QUERY, fetchBlogs), takeLatest(ACTION.SET_ADD_BLOGS_QUERY, fetchAddBlogs)]);
}
