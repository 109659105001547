export const ACTION = {
  //Auth
  SIGNIN_USER_REQUEST: "SIGNIN_USER_REQUEST",
  SIGNIN_USER_SUCCESS: "SIGNIN_USER_SUCCESS",
  SIGNIN_USER_FAILURE: "SIGNIN_USER_FAILURE",
  LOGOUT_USER_REQUEST: "LOGOUT_USER_REQUEST",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_FAILURE: "LOGOUT_USER_FAILURE",
  SET_COOKIE: "SET_COOKIE",

  //Private files
  SET_PRIVATE_CONTENT_FOR_DOWNLOAD: "SET_PRIVATE_CONTENT_FOR_DOWNLOAD",
  SET_PRIVATE_CONTENT_QUERY: "SET_PRIVATE_CONTENT_QUERY",
  GET_PRIVATE_CONTENT_REQUEST: "GET_PRIVATE_CONTENT_REQUEST",
  GET_PRIVATE_CONTENT_SUCCESS: "GET_PRIVATE_CONTENT_SUCCESS",
  GET_PRIVATE_CONTENT_FAILURE: "GET_PRIVATE_CONTENT_FAILURE",
  SET_ADD_PRIVATE_CONTENT_QUERY: "SET_ADD_PRIVATE_CONTENT_QUERY",
  ADD_PRIVATE_CONTENT_REQUEST: "ADD_PRIVATE_CONTENT_REQUEST",
  ADD_PRIVATE_CONTENT_SUCCESS: "ADD_PRIVATE_CONTENT_SUCCESS",
  ADD_PRIVATE_CONTENT_FAILURE: "ADD_PRIVATE_CONTENT_FAILURE",
  DOWNLOAD_PRIVATE_CONTENT_REQUEST: "DOWNLOAD_PRIVATE_CONTENT_REQUEST",
  DOWNLOAD_PRIVATE_CONTENT_SUCCESS: "DOWNLOAD_PRIVATE_CONTENT_SUCCESS",
  DOWNLOAD_PRIVATE_CONTENT_FAILURE: "DOWNLOAD_PRIVATE_CONTENT_FAILURE",
  SELECT_PRIVATE_CONTENT: "SELECT_PRIVATE_CONTENT",
  CLEAN_PRIVATE_CONTENT: "CLEAN_PRIVATE_CONTENT",
  SET_PRIVATE_CONTENT_FOR_MERGE: "SET_PRIVATE_CONTENT_FOR_MERGE",
  GET_MAIN_IMAGE_FOR_MERGE_REQUEST: "GET_MAIN_IMAGE_FOR_MERGE_REQUEST",
  GET_MAIN_IMAGE_FOR_MERGE_SUCCESS: "GET_MAIN_IMAGE_FOR_MERGE_SUCCESS",
  GET_MAIN_IMAGE_FOR_MERGE_FAILURE: "GET_MAIN_IMAGE_FOR_MERGE_FAILURE",
  GET_LOGOS_IDS_REQUEST: "GET_LOGOS_IDS_REQUEST",
  GET_LOGOS_IDS_SUCCESS: "GET_LOGOS_IDS_SUCCESS",
  GET_LOGOS_IDS_FAILURE: "GET_LOGOS_IDS_FAILURE",

  //Blogs
  SET_BLOGS_QUERY: "SET_BLOGS_QUERY",
  GET_BLOGS_REQUEST: "GET_BLOGS_REQUEST",
  GET_BLOGS_SUCCESS: "GET_BLOGS_SUCCESS",
  GET_BLOGS_FAILURE: "GET_BLOGS_FAILURE",
  SET_ADD_BLOGS_QUERY: "SET_ADD_BLOGS_QUERY",
  ADD_BLOGS_REQUEST: "ADD_BLOGS_REQUEST",
  ADD_BLOGS_SUCCESS: "ADD_BLOGS_SUCCESS",
  ADD_BLOGS_FAILURE: "ADD_BLOGS_FAILURE",

  //Users
  SET_USERS_QUERY: "SET_USERS_QUERY",
  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  ENABLE_USER_REQUEST: "ENABLE_USER_REQUEST",
  ENABLE_USER_FAILURE: "ENABLE_USER_FAILURE",
  USER_MODAL_OPEN: "USER_MODAL_OPEN",
  USER_MODAL_CLOSE: "USER_MODAL_CLOSE",
  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
  EDIT_USER_REQUEST: "EDIT_USER_REQUEST",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAILURE: "EDIT_USER_FAILURE",
  CLEAN_USERS: "CLEAN_USERS",
  EDIT_USER_FIELDS: "EDIT_USER_FIELDS",

  //AdminBlogs
  SET_ADMIN_BLOGS_QUERY: "SET_ADMIN_BLOGS_QUERY",
  GET_ADMIN_BLOGS_REQUEST: "GET_ADMIN_BLOGS_REQUEST",
  GET_ADMIN_BLOGS_SUCCESS: "GET_ADMIN_BLOGS_SUCCESS",
  GET_ADMIN_BLOGS_FAILURE: "GET_ADMIN_BLOGS_FAILURE",
  EDIT_BLOG_FIELDS: "EDIT_BLOG_FIELDS",
  ADD_BLOG_MAIN_IMAGE_REQUEST: "ADD_BLOG_MAIN_IMAGE_REQUEST",
  ADD_BLOG_MAIN_IMAGE_SUCCESS: "ADD_BLOG_MAIN_IMAGE_SUCCESS",
  ADD_BLOG_MAIN_IMAGE_FAILURE: "ADD_BLOG_MAIN_IMAGE_FAILURE",
  ADD_BLOG_MAIN_VIDEO: "ADD_BLOG_MAIN_VIDEO",
  DELETE_BLOG_MAIN_IMAGE_REQUEST: "DELETE_BLOG_MAIN_IMAGE_REQUEST",
  DELETE_BLOG_MAIN_IMAGE_SUCCESS: "DELETE_BLOG_MAIN_IMAGE_SUCCESS",
  DELETE_BLOG_MAIN_IMAGE_FAILURE: "DELETE_BLOG_MAIN_IMAGE_FAILURE",
  DELETE_BLOG_ALL_MAIN_IMAGES: "DELETE_BLOG_ALL_MAIN_IMAGES",
  ADD_BLOG_CROPPED_IMAGE_REQUEST: "ADD_BLOG_CROPPED_IMAGE_REQUEST",
  ADD_BLOG_CROPPED_IMAGE_SUCCESS: "ADD_BLOG_CROPPED_IMAGE_SUCCESS",
  ADD_BLOG_CROPPED_IMAGE_FAILURE: "ADD_BLOG_CROPPED_IMAGE_FAILURE",
  BLOG_MODAL_OPEN: "BLOG_MODAL_OPEN",
  BLOG_MODAL_CLOSE: "BLOG_MODAL_CLOSE",
  BLOG_SUCCESS_MODAL_OPEN: "BLOG_SUCCESS_MODAL_OPEN",
  BLOG_SUCCESS_MODAL_CLOSE: "BLOG_SUCCESS_MODAL_CLOSE",
  ADD_BLOG_REQUEST: "ADD_BLOG_REQUEST",
  ADD_BLOG_SUCCESS: "ADD_BLOG_SUCCESS",
  ADD_BLOG_FAILURE: "ADD_BLOG_FAILURE",
  EDIT_BLOG_REQUEST: "EDIT_BLOG_REQUEST",
  EDIT_BLOG_SUCCESS: "EDIT_BLOG_SUCCESS",
  EDIT_BLOG_FAILURE: "EDIT_BLOG_FAILURE",
  DELETE_BLOG_REQUEST: "DELETE_BLOG_REQUEST",
  DELETE_BLOG_SUCCESS: "DELETE_BLOG_SUCCESS",
  DELETE_BLOG_FAILURE: "DELETE_BLOG_FAILURE",
  CLEAN_ADMIN_BLOGS: "CLEAN_ADMIN_BLOGS",
  FILL_BLOG_FORM: "FILL_BLOG_FORM",
  CLEAN_BLOG_FORM: "CLEAN_BLOG_FORM",
  SET_BLOG_PREVIEW: "SET_BLOG_PREVIEW",
  ENABLE_ADMIN_BLOG_REQUEST: "ENABLE_ADMIN_BLOG_REQUEST",
  ENABLE_ADMIN_BLOG_FAILURE: "ENABLE_ADMIN_BLOG_FAILURE",
  EDIT_BLOG_ORDER: "EDIT_BLOG_ORDER",
  EDIT_BLOG_ORDER_REQUEST: "EDIT_BLOG_ORDER_REQUEST",
  EDIT_BLOG_ORDER_SUCCESS: "EDIT_BLOG_ORDER_SUCCESS",
  EDIT_BLOG_ORDER_FAILURE: "EDIT_BLOG_ORDER_FAILURE",
  SET_BLOG_MAIN_IMAGE_TYPE: "SET_BLOG_MAIN_IMAGE_TYPE",

  //AdminFiles
  SET_ADMIN_FILES_QUERY: "SET_ADMIN_FILES_QUERY",
  GET_ADMIN_FILES_REQUEST: "GET_ADMIN_FILES_REQUEST",
  GET_ADMIN_FILES_SUCCESS: "GET_ADMIN_FILES_SUCCESS",
  GET_ADMIN_FILES_FAILURE: "GET_ADMIN_FILES_FAILURE",
  EDIT_PRIVATE_FILE_FIELDS: "EDIT_PRIVATE_FILE_FIELDS",
  CLEAN_ADMIN_FILES: "CLEAN_ADMIN_FILES",
  FILL_PRIVATE_FILE_FORM: "FILL_PRIVATE_FILE_FORM",
  CLEAN_PRIVATE_FILE_FORM: "CLEAN_PRIVATE_FILE_FORM",
  PRIVATE_FILE_SUCCESS_MODAL_OPEN: "PRIVATE_FILE_SUCCESS_MODAL_OPEN",
  PRIVATE_FILE_SUCCESS_MODAL_CLOSE: "PRIVATE_FILE_SUCCESS_MODAL_CLOSE",
  PRIVATE_FILE_MODAL_OPEN: "PRIVATE_FILE_MODAL_OPEN",
  PRIVATE_FILE_MODAL_CLOSE: "PRIVATE_FILE_MODAL_CLOSE",
  ADD_PRIVATE_FILE_REQUEST: "ADD_PRIVATE_FILE_REQUEST",
  ADD_PRIVATE_FILE_SUCCESS: "ADD_PRIVATE_FILE_SUCCESS",
  ADD_PRIVATE_FILE_FAILURE: "ADD_PRIVATE_FILE_FAILURE",
  ADD_PRIVATE_FILE_CROPPED_IMAGE_REQUEST: "ADD_PRIVATE_FILE_CROPPED_IMAGE_REQUEST",
  ADD_PRIVATE_FILE_CROPPED_IMAGE_SUCCESS: "ADD_PRIVATE_FILE_CROPPED_IMAGE_SUCCESS",
  ADD_PRIVATE_FILE_CROPPED_IMAGE_FAILURE: "ADD_PRIVATE_FILE_CROPPED_IMAGE_FAILURE",
  DELETE_PRIVATE_FILE_CROPPED_IMAGE_REQUEST: "DELETE_PRIVATE_FILE_CROPPED_IMAGE_REQUEST",
  DELETE_PRIVATE_FILE_CROPPED_IMAGE_SUCCESS: "DELETE_PRIVATE_FILE_CROPPED_IMAGE_SUCCESS",
  DELETE_PRIVATE_FILE_CROPPED_IMAGE_FAILURE: "DELETE_PRIVATE_FILE_CROPPED_IMAGE_FAILURE",
  EDIT_PRIVATE_FILE_REQUEST: "EDIT_PRIVATE_FILE_REQUEST",
  EDIT_PRIVATE_FILE_SUCCESS: "EDIT_PRIVATE_FILE_SUCCESS",
  EDIT_PRIVATE_FILE_FAILURE: "EDIT_PRIVATE_FILE_FAILURE",
  DELETE_PRIVATE_FILE_REQUEST: "DELETE_PRIVATE_FILE_REQUEST",
  DELETE_PRIVATE_FILE_SUCCESS: "DELETE_PRIVATE_FILE_SUCCESS",
  DELETE_PRIVATE_FILE_FAILURE: "DELETE_PRIVATE_FILE_FAILURE",
  ENABLE_ADMIN_FILE_REQUEST: "ENABLE_ADMIN_FILE_REQUEST",
  ENABLE_ADMIN_FILE_FAILURE: "ENABLE_ADMIN_FILE_FAILURE",
  EDIT_ADMIN_FILE_ORDER: "EDIT_ADMIN_FILE_ORDER",
  EDIT_ADMIN_FILE_ORDER_REQUEST: "EDIT_ADMIN_FILE_ORDER_REQUEST",
  EDIT_ADMIN_FILE_ORDER_SUCCESS: "EDIT_ADMIN_FILE_ORDER_SUCCESS",
  EDIT_ADMIN_FILE_ORDER_FAILURE: "EDIT_ADMIN_FILE_ORDER_FAILURE",

  // Errors
  ERROR_MODAL_OPEN: "ERROR_MODAL_OPEN",
  ERROR_MODAL_CLOSE: "ERROR_MODAL_CLOSE",
}
