import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import TablePagination from "@material-ui/core/TablePagination";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import { RoleHandler } from "../../сommon/RoleHadler";
import { UserModal } from "../../modals/UserModal";


import { setUsersQuery, enableUsersRequest, openUserModal } from "../../../store/actions";

import useStyles from "../useStyles";
import localization from "../../../assets/locale/materialTableLocale";

/**
 * @desc function for create users table
 * @returns {JSX.Element}
 */
export const UsersTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { users, pagination, query, loading } = useSelector(({ users }) => users);

  useEffect(() => {
    dispatch(setUsersQuery(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "ID",
      field: "id",
      width: 50,
      render: rowData => (
        <Typography variant="body1" noWrap className={classes.tablefield_id}>
          {rowData.id}
        </Typography>
      ),
      sorting: false,
    },
    {
      title: "Email",
      field: "email",
      width: 300,
      render: rowData => (
        <Typography variant="body1" noWrap className={classes.tablefield_email}>
          {rowData.email}
        </Typography>
      ),
      sorting: false,
    },
    {
      title: "Aktivierung",
      field: "enabled",
      align: "center",
      width: 300,
      render: rowData => (
        <Button
          variant="contained"
          className={classes.table_btn}
          onClick={() => dispatch(enableUsersRequest(rowData.id, { enable: !rowData.enabled }))}
        >
          {rowData.enabled ? "Deaktivieren" : "Aktivieren"}
        </Button>
      ),
      sorting: false,
    },
    {
      title: "Rolle",
      field: "role",
      align: "center",
      width: 200,
      render: rowData => (
        <Typography variant="body1" noWrap className={classes.tablefield_role}>
          <RoleHandler role={rowData.role} />
        </Typography>
      ),
      sorting: false,
    },
  ];

  return (
    <section className={`${classes.table_section} container`}>
      <Typography align="center" variant="h2" className={classes.table_title}>
        Benutzerverwaltung
      </Typography>
      <MaterialTable
        columns={columns}
        isLoading={loading}
        data={users}
        paging={false}
        title="Benutzerliste"
        actions={[
          {
            icon: "edit",
            tooltip: "Benutzer bearbeiten",
            onClick: (event, rowData) => dispatch(openUserModal("EDIT", rowData.id, rowData.email, rowData.role)),
          },
          {
            icon: "delete",
            tooltip: "Benutzer löschen",
            onClick: (event, rowData) => dispatch(openUserModal("DELETE", rowData.id, rowData.email, rowData.role)),
          },
        ]}
        onSearchChange={str => dispatch(setUsersQuery({ email: str, page: 0 }))}
        options={{
          actionsColumnIndex: -1,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          minBodyHeight: 410,
          draggable: false,
          searchText: query.email,
        }}
        localization={localization}
        components={{
          Pagination: () => (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              page={query.page}
              count={pagination.totalCount}
              rowsPerPage={query.size}
              SelectProps={{ inputProps: { "aria-label": "rows per page" } }}
              onChangePage={(e, page) => dispatch(setUsersQuery({ page }))}
              onChangeRowsPerPage={e => dispatch(setUsersQuery({ size: +e.target.value, page: 0 }))}
            />
          ),
          Action: props => {
            return props.action.icon === "delete" && props.data.enabled ? (
              <IconButton aria-label={props.action.icon} disabled={props.data.enabled}>
                <Icon>{props.action.icon}</Icon>
              </IconButton>
            ) : (
              <Tooltip title={props.action.tooltip}>
                <IconButton aria-label={props.action.icon} onClick={event => props.action.onClick(event, props.data)}>
                  <Icon>{props.action.icon}</Icon>
                </IconButton>
              </Tooltip>
            );
          },
        }}
      />

      <UserModal />
    </section>
  );
};
