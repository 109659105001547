import { GoToTop } from "../../components/сommon/GoToTop";
import { ScrollToTop } from "../../components/сommon/ScrollToTop";
import { Header } from "../../components/сommon/Header";
import { ChevronButton } from "../../components/сommon/buttons/ChevronButton";
import { PrivacyContent } from "../../components/PrivacyContent";
import { Footer } from "../../components/сommon/Footer";

/**
 * @desc Privacy UI
 * @return {JSX.Element}
 */
export const PrivacyPage = () => (
  <>
    <GoToTop />
    <ScrollToTop />
    <Header />
    <ChevronButton />
    <PrivacyContent />
    <Footer />
  </>
);
