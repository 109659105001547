import { ACTION } from "../constants/types";

/**
 * @desc function for set admin users pagination
 * @param {object} query - number of page, size per page
 * @return {{type: string, payload: *}}
 */
export const setUsersQuery = query => {
  return {
    type: ACTION.SET_USERS_QUERY,
    payload: query,
  };
};

/**
 * @desc function for successful receipt admin users
 * @param {object} - object with admin users, page, totalCount
 * @return {{type: string, payload: *}}
 */
export const getUsersSuccess = ({ users, page, totalCount }) => {
  return {
    type: ACTION.GET_USERS_SUCCESS,
    payload: {
      users,
      page,
      totalCount,
    },
  };
};

/**
 * @desc function for failed receipt admin users
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const getUsersFailure = error => {
  return {
    type: ACTION.GET_USERS_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for enable admin users
 * @param {number} userId - admin user id
 * @param {boolean} enable - toggle value
 * @return {{type: string, payload: *}}
 */
export const enableUsersRequest = (userId, enable) => {
  return {
    type: ACTION.ENABLE_USER_REQUEST,
    payload: { id: userId, enable: enable },
  };
};

/**
 * @desc function for failed enable admin users
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const enableUsersFailure = error => {
  return {
    type: ACTION.ENABLE_USER_FAILURE,
    payload: error,
  };
};

/**
 * @desc function open admin users modal window
 * @param {string} modalContent - string with modal content
 * @param {number} userId - user id
 * @param {string} email - user email
 * @param {string} role - user role
 * @return {{type: string, payload: *}}
 */
export const openUserModal = (modalContent, userId, email, role) => {
  return {
    type: ACTION.USER_MODAL_OPEN,
    payload: { modalContent, id: userId, email, role },
  };
};

/**
 * @desc function for close admin users modal window
 * @return {{type: string}}
 */
export const closeUserModal = () => {
  return {
    type: ACTION.USER_MODAL_CLOSE,
  };
};

/**
 * @desc function for delete admin user
 * @param {number} id - admin user id
 * @return {{type: string, payload: *}}
 */
export const deleteUserRequest = id => {
  return {
    type: ACTION.DELETE_USER_REQUEST,
    payload: id,
  };
};

/**
 * @desc function for successful deletion admin user
 * @return {{type: string}}
 */
export const deleteUserSuccess = () => {
  return {
    type: ACTION.DELETE_USER_SUCCESS,
  };
};

/**
 * @desc function for failed deletion admin user
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const deleteUserFailure = error => {
  return {
    type: ACTION.DELETE_USER_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for edit admin user
 * @param {number} userId - selected admin user id
 * @param {object} role - user role
 * @return {{type: string, payload: *}}
 */
export const editUserRequest = (userId, role) => {
  return {
    type: ACTION.EDIT_USER_REQUEST,
    payload: { id: userId, role },
  };
};

/**
 * @desc function for successful editing admin user
 * @return {{type: string}}
 */
export const editUserSuccess = () => {
  return {
    type: ACTION.EDIT_USER_SUCCESS,
  };
};

/**
 * @desc function for failed editing admin user
 * @param {object} error - object with error
 * @return {{type: string, payload: *}}
 */
export const editUserFailure = error => {
  return {
    type: ACTION.EDIT_USER_FAILURE,
    payload: error,
  };
};

/**
 * @desc function for clean admin users
 * @return {{type: string}}
 */
export const cleanUsers = () => {
  return {
    type: ACTION.CLEAN_USERS,
  };
};

/**
 * @desc function for edit admin user fields
 * @param {string} name - field name
 * @param {string} value - field value
 * @return {{type: string, payload: *}}
 */
export const editUserField = (name, value) => {
  return {
    type: ACTION.EDIT_USER_FIELDS,
    payload: { [name]: value },
  };
};